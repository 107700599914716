import React, { useContext } from "react";
import {
  Box,
  Grid,
  InputLabel,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
} from "@mui/material";
import { Button } from "react-bootstrap";
import { AssistenciasContext } from "../../contexts/assistencias-context";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import ReplyIcon from "@mui/icons-material/Reply";
import { formatarDataInput } from "../../utils/formattedInputDate";
import { formattedCurrency } from "../../utils/formattedCurrency";

function AssistenciaSolucao() {
  const { constantes, assistenciaEdicao } = useContext(AssistenciasContext);
  const produtosEnvio = assistenciaEdicao.ProdutosEnvio;
  const produtosRetorno = assistenciaEdicao.ProdutosRetorno;

  return (
    <>
      <Box container display={"flex"} flexDirection={"column"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: 1,
            padding: 2,
          }}
          component={Paper}
        >
          <InputLabel sx={{ fontSize: 16, fontWeight: "bold" }}>
            {constantes.ItensaEnviar} <SendIcon sx={{ marginTop: "-2px" }} />
          </InputLabel>

          <Grid
            container
            width={"100%"}
            
            spacing={0.5}
            sx={{
              background: "#E8EFF2",
              marginTop: 1,
              padding: 2,
              borderRadius: 1,
            }}
          >
            <Grid item xs={15} sm={3} lg={2} xl={2}>
              <InputLabel
                sx={{
                  fontSize: { xs: 12, md: 15 },
                  fontWeight: "bold",
                  color: "#666666",
                }}
              >
                {constantes.PedidoEnvio}: {assistenciaEdicao?.PedidoEnvio?.Id}
              </InputLabel>
            </Grid>
            <Grid item xs={15} sm={3} lg={2} xl={2}>
              <InputLabel
                sx={{
                  fontSize: { xs: 12, md: 15 },
                  fontWeight: "bold",
                  color: "#666666",
                }}
              >
                {constantes.ValorPedido}: {formattedCurrency(assistenciaEdicao?.PedidoEnvio?.ValorPedido)}
              </InputLabel>
            </Grid>
       
            <Grid item xs={15} sm={4} lg={8} xl={8}>
              <InputLabel
                sx={{
                  fontSize: { xs: 12, md: 15 },
                  fontWeight: "bold",
                  color: "#666666",
                }}
              >
                {constantes.Situacao}:{" "}
                {assistenciaEdicao?.PedidoEnvio?.Situacao}
              </InputLabel>
            </Grid>
            <Grid item xs={15} sm={3} lg={2} xl={2}>
              <InputLabel
                sx={{
                  fontSize: { xs: 12, md: 15 },
                  fontWeight: "bold",
                  color: "#666666",
                }}
              >
                {constantes.DataEnvio}:{" "}
                {formatarDataInput(
                  assistenciaEdicao?.PedidoEnvio?.DataCadastro
                )}
              </InputLabel>
            </Grid>
            <Grid item xs={15} sm={8} lg={2} xl={8}>
              <InputLabel
                sx={{
                  fontSize: { xs: 12, md: 15 },
                  fontWeight: "bold",
                  color: "#666666",
                }}
              >
                {constantes.ObservacaodeEnvio}:{" "}
                {assistenciaEdicao.ObservacaodeEnvio}
              </InputLabel>
            </Grid>
          </Grid>

          {produtosEnvio.length !== 0 ? (
            <Grid item sm={12} md={12} lg={14} marginTop={1}>
              <Box
                style={{
                  marginTop: 5,
                  border: "1px solid #cedbd9",
                  borderRadius: 5,
                  overflowX: "auto",
                  maxHeight: "200px",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold", color: "#666666" }}>
                        {constantes.Produto}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#666666" }}>
                        {constantes.Quantidade}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#666666" }}>
                        {constantes.NomeProduto}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {produtosEnvio?.map((item) => (
                      <TableRow key={item.Id}>
                        <TableCell>{item.ProdutoId}</TableCell>
                        <TableCell align={"left"}>{item.Quantidade}</TableCell>
                        <TableCell>{item.ProdutoNome}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Grid>
          ) : (
            <Box
              sx={{
                display: "flex",
                background: "#E8EFF2",
                flexDirection: { xs: "column", md: "row" },
                width: "100%",
                marginTop: 1,
                padding: 2,
                borderRadius: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: { xs: "100%", sm: "45%" },
                  gap: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: 12, sm: 15 },
                    fontWeight: "bold",
                    color: "#706f6f",
                  }}
                >
                  {constantes.MsgNenhumProdutoRetornado}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: 1,
            padding: 2,
          }}
          component={Paper}
        >
          <InputLabel
            sx={{ fontSize: 16, fontWeight: "bold", color: "#666666" }}
          >
            {constantes.ItensaRetornar}
            <ReplyIcon sx={{ marginTop: "-2px" }} />
          </InputLabel>

          <Grid
            container
            spacing={0.5}
            width={"100%"}
            sx={{
              background: "#E8EFF2",
              marginTop: 1,
              padding: 2,
              borderRadius: 1,
            }}
          >
            <Grid item xs={15} lg={12} xl={12}>
              <InputLabel
                sx={{
                  fontSize: { xs: 12, md: 15 },
                  fontWeight: "bold",
                  color: "#666666",
                }}
              >
                {constantes.ObservacaodeRetorno}:{" "}
                {assistenciaEdicao.ObservacaoRetorno}
              </InputLabel>
            </Grid>
            <Grid item xs={15} lg={12} xl={12}>
              <InputLabel
                sx={{
                  fontSize: { xs: 12, md: 15 },
                  fontWeight: "bold",
                  color: "#666666",
                }}
              >
                {constantes.DataRetorno}:{" "}
                {formatarDataInput(assistenciaEdicao?.DataRetorno)}
              </InputLabel>
            </Grid>
          </Grid>

          {produtosRetorno?.length !== 0 ? (
            <Grid item sm={12} md={12} lg={14} marginTop={1}>
              <Box
                style={{
                  marginTop: 5,
                  border: "1px solid #cedbd9",
                  borderRadius: 5,
                  overflowX: "auto",
                  maxHeight: "200px",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold", color: "#666666" }}>
                        {constantes.Produto}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#666666" }}>
                        {constantes.Quantidade}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#666666" }}>
                        {constantes.NomeProduto}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {produtosRetorno?.map((item) => (
                      <TableRow key={item.Id}>
                        <TableCell>{item.ProdutoId}</TableCell>
                        <TableCell>{item.Quantidade}</TableCell>
                        <TableCell>{item.ProdutoNome}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Grid>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                background: "#E8EFF2",
                width: "100%",
                marginTop: 1,
                padding: 2,
                borderRadius: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: { xs: "100%", sm: "45%", lg: "100%" },
                  gap: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: 12, sm: 15 },
                    fontWeight: "bold",
                    color: "#706f6f",
                  }}
                >
                  {constantes.MsgNenhumProdutoRetornado}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default AssistenciaSolucao;
