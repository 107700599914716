import { useContext, useEffect } from "react";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import AtributoComp from "../atributos_comp/atributo.comp.atributo";

const OrcamentoVendaAtributosCtx = () => {

    const {
        somenteLeitura,
    } = useContext(OrcamentoVendaContext);

    return (
       <AtributoComp disabled={somenteLeitura} /> 
    )

}

export default OrcamentoVendaAtributosCtx;