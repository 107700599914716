import { useContext } from "react";
import {
  Box,
  Grid,
  InputLabel,
  Paper,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import { useFormikContext } from "formik";
import { ClienteContext } from "../../contexts/clientes-context";
import { BsFillClipboard2CheckFill } from "react-icons/bs";

export default function ClientTabNotasGerais() {

  const { constantes, somenteLeitura } = useContext(ClienteContext);

  const { values, handleChange } = useFormikContext()

  return (
    <Box component={Paper}>
      <Box
        display="flex"
        alignItems="center"
        p={2}
        height={50}
        sx={{ backgroundColor: "rgba(59, 134, 124, 0.08)" }}
      >
        <Typography variant="h1" fontSize={22} fontWeight="sembold">
          <BsFillClipboard2CheckFill
            style={{ marginBottom: "5px", marginRight: "5px" }}
          />
          {constantes.NotasGerais}
        </Typography>
      </Box>

      <Grid container spacing={2} p={2}>
        <Grid
          spacing={2}
          container
          item
          xs={12}
          sm={12}
          md={12}
          display="flex"
          alignItems="end"
        >
          <Grid item xs={12} sm={12} md={12}>
            
            <InputLabel>{constantes.NotasGerais}</InputLabel>
            <TextareaAutosize
              name="NotasGerais"
              disabled={somenteLeitura}
              value={values.NotasGerais}
              onChange={handleChange}
              style={{
                width: "30%",
                marginTop: 15,
                padding: "5px 10px",
                fontFamily: "sans-serif",
                textTransform: "lowercase",
                ...(window.innerWidth <= 800 && {
                  width: "60%",
                  marginTop: 15,
                  padding: "5px 10px",
                  fontFamily: "sans-serif",
                  textTransform: "lowercase",

                }),
                ...(window.innerWidth <= 600 && {
                  width: "100%",
                  marginTop: 15,
                  padding: "5px 10px",
                  fontFamily: "sans-serif",
                  textTransform: "lowercase",

                }),
              }}
              minRows={6}
              placeholder={constantes.Escrevasuasnotasgerais}
              fullWidth
              size="small"
            />
           </Grid>
        </Grid>
      </Grid> 

    </Box>
  );
}
