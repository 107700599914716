import { ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { Badge, Form } from "react-bootstrap";

const AtributoItemLogicoControl = (props) => {

    const { listvalues, atributoItemEntrada, onItemChange, tipo, index, name } = props;

    const [valueSelected, setValueSelected] = useState(atributoItemEntrada?.Valor);
    const [values, setValues] = useState(listvalues);
    const [isValid, setIsValid] = useState(false);

    const [valueText, setValueText] = useState(atributoItemEntrada?.Valor);

    
    useEffect(() => {
        const atributosaida = {
            Valor: valueText,
            Id: atributoItemEntrada?.Id,
            ValorAtributoId: null,
            Atributo: atributoItemEntrada?.Atributo,
            ValorAtributo: null
        }
        valueText && valueSelected && onItemChange && valueSelected && onItemChange(
            atributosaida,
            index,
            tipo
        );
    }, [valueSelected, valueText])
    

    return (
        <>
            <Form.Check
                {...props}
                checked={valueSelected === "Sim"}
                value={valueSelected}
                onChange={(e) => {
                    setValueSelected(e.target.checked ? "Sim" : "Não");
                    setValueText(e.target.checked ? "Sim" : "Não");
                }}
                isValid={isValid}

            >

            </Form.Check>
            {name && <ErrorMessage name={name} >
                {(msg) => <Badge bg="danger">{msg}</Badge>
                }
            </ErrorMessage >}
            

        </>
    )
}


export default AtributoItemLogicoControl;