import { useContext, useEffect } from "react";
import { useFormikContext } from "formik";
import { ItemOrcamentoVendaContext } from "../../contexts/itemorcamentovenda-context";
import { Col, Container, Row } from "react-bootstrap";
import OrcamentoVendaCondicoesEspeciaisCtx from "../orcamento_venda/orcamentovenda-condicoes.especiais";
import CalculatedControl from "../controls_comp/control-calculated.jsx";
import ItemOrcamentoVendaCondicaoEspecialItemCtx from "./itemorcamentovenda-condicaoespecialitem.jsx";


const ItemOrcamentoVendaDescontosCtx = () => {

    const {
        somenteLeitura,
        constantes,
        descontosTabela,
        configs,
        updateDescontoItem1,
        updateDescontoItem2,
        updateDescontoItem3,
        updateDescontoItem4,
        updateDescontoItem5,
        updateDescontoEspecialItem,
        updateAcrescimoItem,
        updateAcrescimoEspecialItem,
        updateDescontosEAcrescimosItem
    } = useContext(ItemOrcamentoVendaContext);


    const {
        values,
        setFieldValue
    } = useFormikContext();

    function AtualizarDesconto(campo, valor) {
        switch (campo) {
            case "ItemOrcamentoVenda.PercentualDesconto1Padrao":
                updateDescontoItem1(valor, setFieldValue, values);
                break;
            case "ItemOrcamentoVenda.PercentualDesconto2Padrao":
                updateDescontoItem2(valor, setFieldValue, values);
                break;
            case "ItemOrcamentoVenda.PercentualDesconto3Padrao":
                updateDescontoItem3(valor, setFieldValue, values);
                break;
            case "ItemOrcamentoVenda.PercentualDesconto4Padrao":
                updateDescontoItem4(valor, setFieldValue, values);
                break;
            case "ItemOrcamentoVenda.PercentualDesconto5Padrao":
                updateDescontoItem5(valor, setFieldValue, values);
                break;
            case "ItemOrcamentoVenda.PercentualDescontoEspecialPadrao":
                updateDescontoEspecialItem(valor, setFieldValue, values);
                break;
            case "ItemOrcamentoVenda.PercentualAcrescimoPadrao":
                updateAcrescimoItem(valor, setFieldValue, values);
                break;
            case "ItemOrcamentoVenda.PercentualAcrescimoEspecialPadrao":
                updateAcrescimoEspecialItem(valor, setFieldValue, values);
                break;
            default:
                break;
        }
    }

    function AtualizarDescontosDeTela(descontos) {
        updateDescontosEAcrescimosItem(descontos, setFieldValue, values);
    }


    useEffect(() => {
        setFieldValue("Descontos.PercentualDesconto1Praticado", values.ItemOrcamentoVenda?.PercentualDesconto1Praticado);
        setFieldValue("Descontos.PercentualDesconto2Praticado", values.ItemOrcamentoVenda?.PercentualDesconto2Praticado);
        setFieldValue("Descontos.PercentualDesconto3Praticado", values.ItemOrcamentoVenda?.PercentualDesconto3Praticado);
        setFieldValue("Descontos.PercentualDesconto4Praticado", values.ItemOrcamentoVenda?.PercentualDesconto4Praticado);
        setFieldValue("Descontos.PercentualDesconto5Praticado", values.ItemOrcamentoVenda?.PercentualDesconto5Praticado);
        setFieldValue("Descontos.PercentualDescontoEspecialPraticado", values.ItemOrcamentoVenda?.PercentualDescontoEspecialPraticado);
        setFieldValue("Descontos.PercentualAcrescimoPadrao", values.ItemOrcamentoVenda?.PercentualAcrescimoPadrao);
        setFieldValue("Descontos.PercentualAcrescimoEspecialPadrao", values.ItemOrcamentoVenda?.PercentualAcrescimoEspecialPadrao);
    }, [
        values.ItemOrcamentoVenda?.PercentualDesconto1Praticado,
        values.ItemOrcamentoVenda?.PercentualDesconto2Praticado,
        values.ItemOrcamentoVenda?.PercentualDesconto3Praticado,
        values.ItemOrcamentoVenda?.PercentualDesconto4Praticado,
        values.ItemOrcamentoVenda?.PercentualDesconto5Praticado,
        values.ItemOrcamentoVenda?.PercentualDescontoEspecialPraticado,
        values.ItemOrcamentoVenda?.PercentualAcrescimoPadrao,
        values.ItemOrcamentoVenda?.PercentualAcrescimoEspecialPadrao])

    const handleBlur = (e, campo, valor) => {
        setFieldValue(campo, valor);
    }

    return (

        <Container>
            <Row style={{ textAlign: 'left' }}>
                {configs?.QuantidadeMaximaDescontos > 0 &&
                    configs?.PermitirAlterarDescontosVenda && (
                        <Col  >

                            <CalculatedControl
                                disabled={somenteLeitura}
                                name="ItemOrcamentoVenda.PercentualDesconto1Praticado"
                                valuecalculated={
                                    descontosTabela?.PercentualDesconto1Tabela
                                }
                                placeholder={constantes.PercentualDesconto1}
                                onBlur={(e) => {
                                    Number(values.Descontos.PercentualDesconto1Praticado ?? 0) != Number(values.ItemOrcamentoVenda.PercentualDesconto1Praticado ?? 0)
                                        &&
                                        handleBlur(e, "ItemOrcamentoVenda.ItemOrcamentoVenda", Number(values.Descontos.ItemOrcamentoVenda ?? 0));

                                }}
                            />

                        </Col>
                    )}
                {configs?.QuantidadeMaximaDescontos > 1 &&
                    configs?.PermitirAlterarDescontosVenda && (
                        <Col>

                            <NewCalculatedControl
                                disabled={somenteLeitura}
                                name="ItemOrcamentoVenda.PercentualDesconto2Praticado"
                                valuecalculated={
                                    descontosTabela?.PercentualDesconto2Tabela
                                }
                                placeholder={constantes.PercentualDesconto2}
                                onBlur={(e) => {
                                    Number(values.Descontos.PercentualDesconto2Praticado ?? 0) != Number(values.OrcamentoVenda.PercentualDesconto2Praticado ?? 0)
                                        &&
                                        handleBlur(e, "OrcamentoVenda.PercentualDesconto2Praticado", Number(values.Descontos.PercentualDesconto2Praticado ?? 0));
                                }}
                            />

                        </Col>
                    )}

                {configs?.QuantidadeMaximaDescontos > 2 &&
                    configs?.PermitirAlterarDescontosVenda && (
                        <Col>
                            <NewCalculatedControl
                                disabled={somenteLeitura}
                                name="ItemOrcamentoVenda.PercentualDesconto3Praticado"
                                valuecalculated={
                                    descontosTabela?.PercentualDesconto3Tabela
                                }
                                placeholder={constantes.PercentualDesconto3}
                                onBlur={(e) => {
                                    Number(values.Descontos.PercentualDesconto3Praticado ?? 0) != Number(values.OrcamentoVenda.PercentualDesconto3Praticado ?? 0)
                                        &&
                                        handleBlur(e, "OrcamentoVenda.PercentualDesconto3Praticado", Number(values.Descontos.PercentualDesconto3Praticado ?? 0));
                                }}
                            />
                        </Col>
                    )}

                {configs?.QuantidadeMaximaDescontos > 3 &&
                    configs?.PermitirAlterarDescontosVenda && (
                        <Col>

                            <NewCalculatedControl
                                disabled={somenteLeitura}
                                name="ItemOrcamentoVenda.PercentualDesconto4Praticado"
                                valuecalculated={
                                    descontosTabela?.PercentualDesconto4Tabela
                                }
                                placeholder={constantes.PercentualDesconto4}
                                onBlur={(e) => {
                                    Number(values.Descontos.PercentualDesconto4Praticado ?? 0) != Number(values.OrcamentoVenda.PercentualDesconto4Praticado ?? 0)
                                        &&
                                        handleBlur(e, "OrcamentoVenda.PercentualDesconto4Praticado", Number(values.Descontos.PercentualDesconto4Praticado ?? 0));
                                }}
                            />

                        </Col>
                    )}
                {configs?.QuantidadeMaximaDescontos > 4 &&
                    configs?.PermitirAlterarDescontosVenda && (
                        <Col>

                            <CalculatedControl
                                disabled={somenteLeitura}
                                name="ItemOrcamentoVenda.PercentualDesconto5Praticado"
                                valuecalculated={
                                    descontosTabela?.PercentualDesconto5Tabela
                                }
                                placeholder={constantes.PercentualDesconto5}
                                onBlur={(e) => {
                                    Number(values.Descontos.PercentualDesconto5Praticado ?? 0) != Number(values.OrcamentoVenda.PercentualDesconto5Praticado ?? 0)
                                        &&
                                        handleBlur(e, "OrcamentoVenda.PercentualDesconto5Praticado", Number(values.Descontos.PercentualDesconto5Praticado ?? 0));
                                }}
                            />

                        </Col>
                    )}

                {configs?.HabilitarDescontoEspecial &&
                    configs.PermitirAlterarDescontosVenda && (
                        <Col sm>
                            <ItemOrcamentoVendaCondicaoEspecialItemCtx
                                disabled={somenteLeitura}
                                name="ItemOrcamentoVenda.PercentualDescontoEspecialPraticado"
                                placeholder={constantes.PercentualDescontoEspecial}
                                onBlur={(e) => {
                                    setCalcular(true);
                                }}
                            />

                            {/* <NewCondicaoEspecialControl
                                // disabled={somenteLeitura}
                                disabled={true}
                                name="Descontos.PercentualDescontoEspecialPraticado"
                                // valuecalculated={
                                //     descontosTabela?.PercentualDescontoEspecialTabela
                                // }
                                placeholder={constantes.PercentualDescontoEspecial}
                                onBlur={(e) => {
                                    Number(values.Descontos.PercentualDescontoEspecialPraticado ?? 0) != Number(values.OrcamentoVenda.PercentualDescontoEspecialPraticado ?? 0)
                                        &&
                                        handleBlur(e, "OrcamentoVenda.PercentualDescontoEspecialPraticado", Number(values.Descontos.PercentualDescontoEspecialPraticado ?? 0));
                                }}

                            /> */}


                        </Col>
                    )}

                {/* 
                    {configs?.HabilitarDescontoEspecial &&
                        configs?.PermitirAlterarDescontosVenda && (
                            <Col>
                                <NewCalculatedControl
                                    disabled={somenteLeitura}
                                    name="ItemOrcamentoVenda.PercentualDescontoEspecialPraticado"
                                    placeholder={constantes.PercentualDescontoEspecial}
                                    onBlur={(e) => {
                                        setCalcular(true);
                                    }}
                                />
                            </Col>
                        )} */}

                {configs?.PermitirAlterarAcrescimoVenda && (
                    <Col>
                        <NewCalculatedControl
                            disabled={somenteLeitura}
                            name="ItemOrcamentoVenda.PercentualAcrescimoPraticado"
                            valuecalculated={
                                descontosTabela?.PercentualAcrescimoTabela
                            }
                            placeholder={constantes.PercentualAcrescimo}
                            onBlur={(e) => {
                                Number(values.Descontos.PercentualAcrescimoPraticado ?? 0) != Number(values.OrcamentoVenda.PercentualAcrescimoPraticado ?? 0)
                                    &&
                                    handleBlur(e, "OrcamentoVenda.PercentualAcrescimoPraticado", Number(values.Descontos.PercentualAcrescimoPraticado ?? 0));

                            }}
                        />
                    </Col>
                )}
                {configs?.PermitirAlterarAcrescimoVenda && (
                    <Col>
                        <NewCalculatedControl
                            disabled={somenteLeitura}
                            name="ItemOrcamentoVenda.PercentualAcrescimoEspecialPraticado"
                            valuecalculated={
                                descontosTabela?.PercentualAcrescimoEspecialTabela

                            }
                            placeholder={
                                constantes.PercentualAcrescimoEspecial
                            }
                            onBlur={(e) => {
                                Number(values.Descontos.PercentualAcrescimoEspecialPraticado ?? 0) != Number(values.OrcamentoVenda.PercentualAcrescimoEspecialPraticado ?? 0)
                                    &&
                                    handleBlur(e, "OrcamentoVenda.PercentualAcrescimoEspecialPraticado", Number(values.Descontos.PercentualAcrescimoEspecialPraticado ?? 0));

                            }}
                        />
                    </Col>
                )}

                {values.OrcamentoVenda?.CondicoesEspeciais?.length > 0 && configs?.HabilitarDescontoEspecial &&
                    <Col>
                        <OrcamentoVendaCondicoesEspeciaisCtx />
                    </Col>
                }

            </Row >


            {/* <Col sm style={{ padding: 20, marginTop: "1.65rem", alignItems: "center" }}>


                        {loadingDesconto ? <Spinner animation="border" style={{ marginTop: 2, alignItems: "center" }} variant="primary" /> : <Button
                            disabled={somenteLeitura}
                            type="button"
                            onClick={(e) => {
                                setLoadingDesconto(true)
                                AtualizarCalculo(
                                    setFieldValue,
                                    values,
                                    resetForm,
                                    values.ItemOrcamentoVenda.CodProduto
                                );
                            }
                            } >Aplicar Descontos </Button>}
                    </Col> */}
            {/* </Row> */}
        </Container >
    )
}




// {
//     !permiteAplicarNaTela && modal &&
//     <Container
//         className="p-3 mt-5 d-flex justify-content-center align-items-center gap-2 bottom-0 bg-white"
//         fluid
//     >
//         <Col md={{ span: 10, offset: 8 }}>
//             <Button
//                 onClick={(e) => {

//                     // values.Descontos.PercentualDesconto1Padrao = Number(values.Descontos.PercentualDesconto1Padrao ?? 0);
//                     // values.Descontos.PercentualDesconto2Padrao = Number(values.Descontos.PercentualDesconto2Padrao ?? 0);
//                     // values.Descontos.PercentualDesconto3Padrao = Number(values.Descontos.PercentualDesconto3Padrao ?? 0);
//                     // values.Descontos.PercentualDesconto4Padrao = Number(values.Descontos.PercentualDesconto4Padrao ?? 0);
//                     // values.Descontos.PercentualDesconto5Padrao = Number(values.Descontos.PercentualDesconto5Padrao ?? 0);
//                     // values.Descontos.PercentualAcrescimoPadrao = Number(values.Descontos.PercentualAcrescimoPadrao ?? 0);
//                     // values.Descontos.PercentualAcrescimoEspecialPadrao = Number(values.Descontos.PercentualAcrescimoEspecialPadrao ?? 0);
//                     // values.Descontos.PercentualDescontoEspecialPadrao = Number(values.Descontos.PercentualDescontoEspecialPadrao ?? 0);

//                     setFieldValue("OrcamentoVenda.PercentualDesconto1Padrao", Number(values.Descontos.PercentualDesconto1Padrao ?? 0));
//                     setFieldValue("OrcamentoVenda.PercentualDesconto2Padrao", Number(values.Descontos.PercentualDesconto2Padrao ?? 0));
//                     setFieldValue("OrcamentoVenda.PercentualDesconto3Padrao", Number(values.Descontos.PercentualDesconto3Padrao ?? 0));
//                     setFieldValue("OrcamentoVenda.PercentualDesconto4Padrao", Number(values.Descontos.PercentualDesconto4Padrao ?? 0));
//                     setFieldValue("OrcamentoVenda.PercentualDesconto5Padrao", Number(values.Descontos.PercentualDesconto5Padrao ?? 0));
//                     setFieldValue("OrcamentoVenda.PercentualDescontoEspecialPadrao", Number(values.Descontos.PercentualDescontoEspecialPadrao ?? 0));
//                     setFieldValue("OrcamentoVenda.PercentualAcrescimoPadrao", Number(values.Descontos.PercentualAcrescimoPadrao ?? 0));
//                     setFieldValue("OrcamentoVenda.PercentualAcrescimoEspecialPadrao", Number(values.Descontos.PercentualAcrescimoEspecialPadrao ?? 0));

//                     onAplicarDescontos(values.Descontos);
//                     //setFieldValue("OrcamentoVenda.PercentualDesconto1Padrao", descontos.PercentualDesconto1Padrao);
//                 }}
//             >
//                 Aplicar descontos e acrescimos
//             </Button>
//         </Col>
//     </Container>
// }

export default ItemOrcamentoVendaDescontosCtx;
