import { GetResponsaveis, SetResponsaveis } from "../config/localstorageConfig/localstorage";
import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";

class ReponsavelService extends ServiceBase {

    getAll(filter) {
        //return http.get("/clientes?$top=10");
        return http.get("/ResponsaveisAssistencia" + this.getFilter(filter));
    }

    get(id) {
        return http.get(`/ResponsaveisAssistencia/${id}`);
    }

    async setCache() {
        const responsaveis = GetResponsaveis();
        if (!responsaveis) {
            http.get("/ResponsaveisAssistencia")
                .then(response => {
                    SetResponsaveis(response.data.value)
                })
                .catch(e => {
                    console.error(e);
                })
        };
    }
}

export default new ReponsavelService();