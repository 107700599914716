import React, { useState,useContext } from "react";
import {
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "react-bootstrap";

import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import { yellow } from "@mui/material/colors";
import { GoAlertFill } from "react-icons/go"
export default function OrcamentoModalImportaItensErros() {
  const { 
    modalErrorsImportarItens, 
    errosModalErrorsImportarItens,
    setErrosModalErrorsImportarItens, 
    errorImportarItens,
    setErrorImportarItens, 
    setErros, 
    constantes, 
    listagemValidacao,
    setAvisoImportarItens 
  } = useContext(OrcamentoVendaContext);

  const FecharErrors = () => {
    setErrosModalErrorsImportarItens(false)
    setAvisoImportarItens(false)

  }

  return (
    <Modal
      size="lg"
      show={errosModalErrorsImportarItens}
      centered
      onHide={() => {
        setErrosModalErrorsImportarItens(false);
      }}
      backdrop="static"
      keyboard={false}
   
    >
      <ModalHeader
        toggle={() => {

          setErrosModalErrorsImportarItens(false);
        }}
        closeButton
      >
        <h2 style={{ fontSize: 22, color: "#363636" }}>{constantes.Atencao} {constantes.Possiveispendencias} <GoAlertFill color="#e09731" size={22} style={{marginTop: -5}}/></h2>
      </ModalHeader>
      <ModalBody >
        <Container className="d-flex flex-column align-items-end">
          <div
            className="d-flex flex-column md-3"
            style={{
              background: "#fcf8e3",
              width: "100%",
              margin: "15px 5px",
              padding: "5px 15px",
              border: "1px solid #f2e5c9",
              borderRadius: 5
            }}
          >
     
            
            {listagemValidacao.length !== 0 ? listagemValidacao.map((item) => (
               <div key={item.id}>
                  <li>{item.Validacao}</li>
               </div>
            
            )) : ""}
          </div>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {FecharErrors()}}
          style={{ fontSize: 15, marginLeft: 0, backgroundColor: "#f0ad4e",  border: "1px solid #e09731", }}
        >
          {constantes.ok}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

