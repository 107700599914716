import { useState } from "react";
import vendedorService from "../../services/service.vendedor";
import NewSearchBarControl from "../searchs_comp/search-Bar.Control";

const VendedorSearch = (props) => {

    const [vendedores, setVendedores] = useState([]);
    const [pages, setPages] = useState();

    const columns = [
        {
            field: "Id",
            headerName: "Id",
            width: 90,
            key: true,
        },
        {
            field: "Nome",
            headerName: "Nome",
            width: 90,
        }
    ]

    const columnsFilter = [
        {
            field: 'Id',
            headerName: 'Codigo',
            tipo: 'number'
        },
        {
            field: 'Nome',
            headerName: 'Nome',
            tipo: 'text'
        }
    ]

    function onSearchTitle(filter) {
        // this.onLoad(true);
        vendedorService.getVinculados(filter)
            .then(response => {
                setVendedores(response.data.value);
                setPages(response.data.pages);
            })
            .catch(e => {
                // this.onLoad(false);
                // this.onError(e);
            });
    }

    function onGetItem(id) {
        id && vendedorService.get(id)
            .then(response => {            
                if (response.response?.data.error) 
                {
                    // onError(response.response?.data.error);   
                } else {
                    response.data && onItemSelected(response.data);
                }
            })
            .catch(e => {
                // onError(e);
            })
            .finally(() => {
                // onLoad(false);
            })
            ;
    }

    function onItemSelected(item) {
        props.onItemSelected && props.onItemSelected(item);
    }

    return (
        <>
            <NewSearchBarControl
                {...props}
                onSearchTitle={onSearchTitle}
                onGetItem={onGetItem}
                onItemSelected={onItemSelected}
                data={vendedores}
                pages={pages}
                columns={columns}
                columnsFilter={columnsFilter}

            />
        </>
    )
}

export default VendedorSearch;



