import { useContext } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography
} from "@mui/material";

import { ClienteContext } from "../../contexts/clientes-context.js";
import { MdEmail } from "react-icons/md";
import ClienteEmailDepartamento from "./cliente-email-departamento.jsx";
import EditControl from "../controls_comp/control-edit.jsx";

export default function ClienteTabEmail() {
  const {
    constantes,
    somenteLeitura
  } = useContext(ClienteContext);

  return (
    <Box component={Paper}>
      <Box
        display="flex"
        alignItems="center"
        p={2}
        height={50}
        sx={{ backgroundColor: "rgba(59, 134, 124, 0.08)" }}
      >
        <Typography variant="h1" fontSize={22} fontWeight="sembold">
          <MdEmail style={{ marginBottom: "5px", marginRight: "2px" }} />{" "}
          {constantes.Emails}
        </Typography>
      </Box>

      <Grid container spacing={2} p={2}>
        <Grid
          spacing={2}
          container
          item
          xs={12}
          sm={12}
          md={12}
          display="flex"
          alignItems="end"
        >
          <Grid item xs={12} sm={7} md={6}>
            <EditControl
              disabled={somenteLeitura}
              name="Entidade.NomeContato"
              placeholder={constantes?.Nomeparacontato}
              type="text" />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={7} md={6}>
          <EditControl
            disabled={somenteLeitura}
            name="Entidade.Email"
            placeholder={constantes?.EmailContato}
            type="text" />
        </Grid>
      </Grid>
      <Grid container spacing={2} p={2}>
        <Grid item xs={15} sm={2} md={2}>
          <ClienteEmailDepartamento />
        </Grid>
      </Grid>
    </Box>
  );
}
