import { GetTiposFrete, SetTiposFrete } from "../config/localstorageConfig/localstorage";
import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";

class TabelaFreteService extends ServiceBase {

  getAll(filter) {
    //return http.get("/clientes?$top=10");
    return http.get("/tabelasfrete" + this.getFilter(filter));
  }

  getTiposFrete() {
    //return http.get("/clientes?$top=10");
    return http.get("/tabelasfrete/tiposfrete");
  }

  get(id) {
    return http.get(`/tabelasfrete/${id}`);
  }

  async setCache() {
    const tiposfrete = GetTiposFrete();
    if (!tiposfrete) {
        http.get("/tabelasfrete/tiposfrete")
            .then(response => { 
              SetTiposFrete(response.data.value) })
            .catch(e => {
                console.error(e);
            })
    };

}


}

export default new TabelaFreteService();