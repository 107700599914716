import { ErrorMessage, Field, useField, useFormikContext } from "formik";
import { Component } from "react";
import { Badge, Button, Col, FloatingLabel, InputGroup, Placeholder, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { format_percent_2 } from "../../common/format";
import T from "../../common/traducao";

const CalculatedControl = (props) => {

  const { name, placeholder, namedefault, valuecalculated, hideTitle, disabled, lado, onBlur, value, heightLabel } = props;
  const [fieldId, metaId, handleId] = useField(name ?? null);

  const hasValueCalculated = valuecalculated > 0 ? true : false;
  const constantes = T();
  const { handleChange, errors, isValid } = useFormikContext();

  return (
    <>

      {/* <InputGroup style={{ flex: 1 }}>
        {!hideTitle &&
          <Row>
            <Form.Label >{placeholder}</Form.Label>
          </Row>}
        <Row md={3} sm={12} className="mb-3"> */}
      {/* {disabled &&
            < Form.Control
              style={{ textAlign: 'right' }}
              value={format_percent_2.format(metaId.value ?? 0)}
              placeholder="0,000"
              disabled />
          }
          {!disabled && */}


      <Form.Label style={{ height: heightLabel ?? 40 }} htmlFor={placeholder}>{placeholder}</Form.Label>
      {/* <Form.Floating className="mb-3"> */}
      <Form.Control
        //isInvalid={!isValid}
        {...props}
        style={{ maxWidth: 250 }}
        id={placeholder}
        name={name ?? null}
        type="number"
        value={value ? value : metaId.value}
        onChange={(e) => {
          handleChange(e)
          //onChange && onChange(e);
        }}
        onWheel={(e) => e.target.blur()}
        aria-describedby={placeholder}
        placeholder="0,00"
      />
      {/* <label htmlFor={placeholder}>{placeholder}</label> */}

      {hasValueCalculated &&
        <Form.Text id={placeholder} muted>
          {constantes.Tabela} ({format_percent_2.format(valuecalculated ?? 0)})
        </Form.Text>
      }

      {/* </Form.Floating> */}
      <ErrorMessage name={name}>
        {(msg) =>
          <Badge bg="danger">{msg}</Badge>
        }
      </ErrorMessage>

      {// 
      }

      {/* < Form.Control
        style={{ textAlign: 'right' }}
        value={format_percent_2.format(valuecalculated ?? 0)}
        placeholder="0,000"
        disabled /> */}

      {/* </Row>


        <ErrorMessage name={name}>
          {(msg) => <div classname="field-error">{msg}</div>}
        </ErrorMessage>
      </InputGroup> */}
    </>
  )
}


export default CalculatedControl;
