import { Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import AssistenciaComentario from "./assistencia-comentario";
import { useContext } from "react";
import { AssistenciasContext } from "../../contexts/assistencias-context";
import { Badge, Button } from "react-bootstrap";
import { BsChatRightTextFill } from "react-icons/bs";
import { useFormikContext } from "formik";
import AssistenciaComentarioAnexo from "./assistencia-comentario-anexo";
import { toast } from "react-toastify";

function AssistenciaComentarioModal() {

    const {
        fecharModalComentarios,
        constantes,
        novoComentario,
        modalComentarios,
        obrigarAnexoComentario,
        comentarioSelecionado,

    } = useContext(AssistenciasContext);

    const {
        values,
        setFieldValue
    } = useFormikContext();


    function DeveObrigarAnexoNoComentario() {
        return obrigarAnexoComentario && (comentarioSelecionado?.AnexosComentarios ? comentarioSelecionado?.AnexosComentarios.length : 0) == 0
    }

    return (
        <>
            <Dialog
                open={modalComentarios}
                onClose={fecharModalComentarios}
                maxWidth="md"
                fullWidth
            >
                <DialogContent>
                    <Typography sx={{ fontSize: 18 }}>
                        {" "}
                        <BsChatRightTextFill
                            style={{ marginBottom: "5px", marginRight: "8px" }}
                        />
                        {constantes.AdicionarComentarios}
                    </Typography>
                </DialogContent>
                <DialogContent>
                    <AssistenciaComentario />
                    { DeveObrigarAnexoNoComentario() && <Badge bg="danger">Anexo Obrigatório</Badge>}
                </DialogContent>
                <DialogContent>

                </DialogContent>
                <DialogActions>

                    <Button
                        style={{ background: "#0D9B86", border: "none" }}
                        onClick={() => {
                            if (DeveObrigarAnexoNoComentario()) {
                                toast.error("é obrigatório adicionar um anexo ao comentário")
                            } else {
                                novoComentario(comentarioSelecionado, setFieldValue, values);
                                fecharModalComentarios();
                            }
                        }}
                    >
                        {constantes.Adicionar}
                    </Button>
                    <Button
                        style={{ background: "crimson", border: "none" }}
                        onClick={() => {
                            fecharModalComentarios();
                        }}
                    >
                        {constantes.Cancelar}
                    </Button>
                </DialogActions>
            </Dialog>

            <AssistenciaComentarioAnexo/>
        </>
    )
}

export default AssistenciaComentarioModal;



