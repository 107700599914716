import { useContext, useRef } from "react";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import { Button, Col, Row } from "react-bootstrap";
import EditControl from "../controls_comp/control-edit";
import SearchListMunicipios from "../searchs_comp/search-List.Municipios";
import { useFormikContext } from "formik";
import ModalMapControl from "../ui_comp/Modals/ModalMap";
import PlaceIcon from "@mui/icons-material/Place";
import { Box, Typography } from "@mui/material";
const OrcamentoVendaEntregaCtx = () => {
  const {
    somenteLeitura,
    constantes,
    handleLoad,
    handleError,
    modalMap,
    setModalMap,
    configuracoes,
    updateMunicipioEnderecoEntrega,
    updateLatitudeLongitudeEntrega,
  } = useContext(OrcamentoVendaContext);

  const { setFieldValue, values } = useFormikContext();

  const lat = values.OrcamentoVenda?.EnderecoEntrega?.Latitude;
  const long = values.OrcamentoVenda?.EnderecoEntrega?.Longitude;
  const county = values.OrcamentoVenda?.EnderecoEntrega?.Municipio?.Nome
  const address = values?.OrcamentoVenda?.EnderecoEntrega?.Endereco;
  const cep = values?.OrcamentoVenda?.EnderecoEntrega?.Cep;
  const number = values?.OrcamentoVenda?.EnderecoEntrega?.Numero;
  const neighborhood = values?.OrcamentoVenda?.EnderecoEntrega?.Bairro;

  const addressSearchLocation = `${address && `${address}`} ${number && `, ${number}`}${county && `, ${county}`}${cep && `, ${cep}`}${neighborhood && `, ${neighborhood}`}`;
  return (
    <>
      <Row>
        <Col xs={12} md={12} className="mb-3">
          <EditControl
            disabled={somenteLeitura}
            name="OrcamentoVenda.EnderecoEntrega.NomeRecebedor"
            placeholder={constantes?.NomeRecebedor}
            type="text"
          />
        </Col>
        <Col xs={12} md={3} className="mb-3">
          <EditControl
            disabled={somenteLeitura}
            name="OrcamentoVenda.EnderecoEntrega.CpfCnpj"
            placeholder={constantes?.CpfCnpj}
            type="text"
          />
        </Col>
        <Col xs={12} md={3} className="mb-3">
          <EditControl
            disabled={somenteLeitura}
            name="OrcamentoVenda.EnderecoEntrega.Telefone"
            placeholder={constantes?.Telefone}
            type="text"
          />
        </Col>
        <Col xs={12} md={3} className="mb-3">
          <EditControl
            disabled={somenteLeitura}
            name="OrcamentoVenda.EnderecoEntrega.Email"
            placeholder={constantes?.Email}
            type="text"
          />
        </Col>
        <Col xs={12} md={3} className="mb-3">
          <EditControl
            disabled={somenteLeitura}
            name="OrcamentoVenda.EnderecoEntrega.InscricaoEstadual"
            placeholder={constantes?.Ie}
            type="text"
          />
        </Col>
        <Col xs={12} md={12} className="mb-3">
          <SearchListMunicipios
            //disabled={values.OrcamentoVenda.Cliente?.Id > 0}
            disabled={somenteLeitura}
            nameId="OrcamentoVenda.EnderecoEntrega.Municipio.Id"
            nameDesc="OrcamentoVenda.EnderecoEntrega.Municipio.Nome"
            placeholder={constantes?.MunicipioEntrega}
            onLoad={handleLoad}
            onError={handleError}
            onItemSelected={(item) => {
              updateMunicipioEnderecoEntrega(item, setFieldValue, values);
              // setFieldValue(
              //     "OrcamentoVenda.EnderecoEntrega.Municipio.IdNovo",
              //     item.Id
              // );
            }}
          />
        </Col>
        <Col xs={12} md={3} className="mb-3">
          <EditControl
            disabled={somenteLeitura}
            name="OrcamentoVenda.EnderecoEntrega.Cep"
            placeholder={constantes?.Cep}
            type="text"
          />
        </Col>

        <Col xs={12} md={9} className="mb-3">
          <EditControl
            disabled={somenteLeitura}
            name="OrcamentoVenda.EnderecoEntrega.Endereco"
            placeholder={constantes?.Endereco}
            type="text"
          />
        </Col>
        <Col xs={12} md={3} className="mb-3">
          <EditControl
            disabled={somenteLeitura}
            name="OrcamentoVenda.EnderecoEntrega.Numero"
            placeholder={constantes?.Numero}
            type="text"
          />
        </Col>
        <Col xs={12} md={3} className="mb-3">
          <EditControl
            disabled={somenteLeitura}
            name="OrcamentoVenda.EnderecoEntrega.Complemento"
            placeholder={constantes?.Complemento}
            type="text"
          />
        </Col>
        <Col xs={12} md={5} lg={6} className="mb-3">
          <EditControl
            disabled={somenteLeitura}
            name="OrcamentoVenda.EnderecoEntrega.Bairro"
            placeholder={constantes?.Bairro}
            type="text"
          />
        </Col>
        <Col xs={12} md={3} className="mb-3">
          <EditControl
            disabled={somenteLeitura}
            name="OrcamentoVenda.EnderecoEntrega.Latitude"
            placeholder={constantes?.Latitude}
            type="number"
          />
        </Col>
        <Col xs={12} md={3} className="mb-3">
          <EditControl
            disabled={somenteLeitura}
            name="OrcamentoVenda.EnderecoEntrega.Longitude"
            placeholder={constantes?.Longitude}
            type="number"
          />
        </Col>
        {configuracoes?.ChaveApiGoogleMaps &&

          <Col xs={12} md={3} className="mb-3">
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                height: "100%",
              }}
            >
              <Button
                bsPrefix="ButtonStyle BtnBuscar"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: "0.4rem",
                  gap: 1,
                }}
                disabled={
                  somenteLeitura ||
                  !values.OrcamentoVenda?.EnderecoEntrega?.Cep ||
                  !values.OrcamentoVenda?.EnderecoEntrega?.Endereco ||
                  !values.OrcamentoVenda?.EnderecoEntrega?.Municipio?.Id
                }
                onClick={() => setModalMap(true)}
              >
                <PlaceIcon fontSize="small" />{" "}
                <Typography sx={{ fontSize: 13, textAlign: "center" }}>
                  {" "}
                  {constantes?.SelecionarLocalizacao}{" "}
                </Typography>
              </Button>
            </Box>
          </Col>}

        <Col xs={12} md={12} className="mb-3">
          <EditControl
            disabled={somenteLeitura}
            name="OrcamentoVenda.EnderecoEntrega.Observacao"
            placeholder={constantes?.Observacao}
            type="text"
          />
        </Col>

        <Col xs={12} md={12} className="mb-3"></Col>
      </Row>

      {configuracoes?.ChaveApiGoogleMaps && <ModalMapControl
        constantes={constantes}
        defaultValue={addressSearchLocation}
        markerAtual={
          lat && long
            ? {
              lat: lat,
              lng: long,
            }
            : null
        }
        onSelectedMarker={(marker) => {
          updateLatitudeLongitudeEntrega(
            marker.lat,
            marker.lng,
            setFieldValue,
            values
          );
          setModalMap(false);
        }}
        modal={modalMap}
        toggle={(e) => setModalMap(false)}
      />}
    </>
  );
};

export default OrcamentoVendaEntregaCtx;
