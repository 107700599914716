import { ErrorMessage, Field } from "formik";
import { Badge, Stack } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const CheckBoxControl = (props) => {
  const { name, placeholder, onBlur } = props;
  return (
    <>
      <Form.Group >
        <Form.Label>
          <Stack direction="horizontal" gap={2}>
            <Field {...props} type="checkbox" className="px-5" />
            {placeholder}
          </Stack>
        </Form.Label>

        <ErrorMessage name={name}>
          {(msg) => <Badge bg="danger">{msg}</Badge>}
        </ErrorMessage>
      </Form.Group>
    </>
  );
}

export default CheckBoxControl;
