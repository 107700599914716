import { Alert, Container } from "react-bootstrap";
import VendedorSearch from "../vendedor_comp/vendedor-search";
import { getAcessoSimulado, refreshlogin, setAcessoSimulado } from "../../config/localstorageConfig/localstorage";
import { useEffect, useState,  } from "react";
import LoginService from "../../services/service.login";
import { toast } from "react-toastify";
import T from "../../common/traducao";
import { withRouter } from "../../common/with-router";

const UsuarioSimulacaoAcesso = (props) => {
    const constantes = T();
    const [vendedorSimulado, setVendedorSimulado] = useState(getAcessoSimulado());

    useEffect(() => {
        setVendedorSimulado(getAcessoSimulado());
    }, [getAcessoSimulado])

    return (
        <>
            <Container>
                <Alert>
                    {constantes.Selecionevendedorquedesejasimularoacesso}
                </Alert>
                <VendedorSearch
                    nameDesc={"Nome"}
                    valueId={vendedorSimulado?.Id}
                    valueDesc={vendedorSimulado?.Nome}
                    placeholder={constantes.Vendedor}
                    onItemSelected={
                        (item) => {
                            const acesso = {
                                Id: item.Id,
                                Nome: item.Nome
                            }
                            LoginService.simularAcesso(item.Id)
                                .then((response) => {
                                    refreshlogin(response.data.AccessToken);
                                    setAcessoSimulado(acesso);
                                    window.location.reload(false);
                                })
                                .catch((error) => {
                                    toast.error(error.response.data.error.message);
                                })

                            
                        }
                    }
                />
            </Container>
        </>
    )
}

export default UsuarioSimulacaoAcesso;