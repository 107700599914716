import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";

class PedidoService extends ServiceBase {

  getAll(filter) {
    //return http.get("/clientes?$top=10");
    return http.get("/pedidosvenda" + this.getFilter(filter));
  }

  get(id) {
    return http.get(`/pedidosvenda/${id}`);
  }

  getItensPedido(id, filter) {
    return http.get(`/ItensPedidoVenda/Pedido/${id}${this.getFilter(filter)}`);
  }

  getItemPedido(id) {
    return http.get(`/ItensPedidoVenda/${id}`);
  }

  getComposicaoItem(id) {
    return http.get(`/ItensPedidoVenda/${id}/composicao`);
  }

  notas(id, filter){
    return http.get(`/pedidosvenda/${id}/notas${this.getFilter(filter)}`)
  }

  ListVendedor(filter) {
    return http.get(`/pedidosvenda/ListVendedor${this.getFilter(filter)}`)
  }


}

export default new PedidoService();