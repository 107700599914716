import { ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { Badge, Form } from "react-bootstrap";

const AtributoItemSelectControl = (props) => {

    const { listvalues, atributoItemEntrada, onItemChange, tipo, index, name } = props;

    const [valueSelected, setValueSelected] = useState(atributoItemEntrada?.ValorAtributo?.Id);
    const [values, setValues] = useState(listvalues);
    const [isValid, setIsValid] = useState(false);

    const [valueText, setValueText] = useState();

    
    useEffect(() => {
        const atributosaida = {
            Valor: valueText,
            Id: atributoItemEntrada?.Id,
            ValorAtributoId: valueSelected,
            Atributo: atributoItemEntrada?.Atributo,
            ValorAtributo: {
                Id: Number(valueSelected)
            }
        }
        valueText && valueSelected && onItemChange && valueSelected && onItemChange(
            atributosaida,
            index,
            tipo
        );
    }, [valueSelected, valueText])
    

    return (
        <>
            <Form.Select
                {...props}
                value={valueSelected}
                onChange={(e) => {
                    setValueSelected(e.target.value);
                    setValueText(e.target.selectedOptions[0].textContent);
                }}
                isValid={isValid}
                

            >

                {<option value={-1} key={-1}>{""}</option>}
                {values && values.map((item, index) => {
                    return <option value={item.Id} key={index}>{item.ValorTraduzido}</option>
                })}


            </Form.Select>
            {name && <ErrorMessage name={name} >
                {(msg) => <Badge bg="danger">{msg}</Badge>
                }
            </ErrorMessage >}
        </>
    )
}


export default AtributoItemSelectControl;