import React, { useEffect,useState } from "react";
import { Box } from "@mui/system";
import { Divider, Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import T from "../../common/traducao";
import {
  getUserLogin,
  getAcessoSimulado,
} from "../../config/localstorageConfig/localstorage";
import CardComponent from "../../components/ui_comp/Cards/cardComponent";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [userLogin, setUserLogin] = useState(getUserLogin());
  const vendedorSimulado = getAcessoSimulado();

  const[usarAssistencia, setUsarAssistencia] = useState(process.env.REACT_APP_USAR_ASSISTENCIA === "true");
  const[usarCliente, setUsarCliente] = useState(process.env.REACT_APP_USAR_CLIENTE === "true");

  const constantes = T();
  const navigate = useNavigate()
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
   

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        padding: 3,
        heigh: "100%",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height="100%"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <HomeIcon sx={{ color: "#009180" }} />
          <Typography variant="h6" component="h5" sx={{ color: "#009180" }}>
            {constantes.Home}
          </Typography>
        </Box>

      </Box>
      <Divider color="primary" sx={{ marginBottom: 2 }} />
      <Box>
        <Typography variant="h5" component="span" sx={{ color: "#009180" }}>
          {constantes.BemVindo} {" "}
          {vendedorSimulado ? vendedorSimulado.Nome : userLogin}
        </Typography>

        <Grid container spacing={2} sx={{ ...(isLg && { marginLeft: -2 }), gap: 2 }} >

          <Grid item display={"flex"} justifyContent={"center"} xs={12} sm={5.8} md={3.8} lg={3} xl={2.2}>
            <CardComponent
              titulo={constantes.OrcamentosDeVenda}
              categoria={constantes.Modulo}
              descricao={constantes.MsgCardHome}
              descricaoImage={constantes.OrcamentosDeVenda}
              route="/orcamentosvenda"
              icone="receipt_long"
            />
          </Grid>

          {usarCliente ?
            <Grid item display={"flex"} justifyContent={"center"} xs={12} sm={5.8} md={3.8} lg={3} xl={2.2}>
              <CardComponent
                titulo={constantes.Clientes}
                categoria={constantes.Modulo}
                descricao={constantes.MsgCardClientes}
                descricaoImage={constantes.Clientes}
                route="/clientes"
                icone="people_alt"
              />
            </Grid>
          : <></>}

          {usarAssistencia &&

            <Grid item display={"flex"} justifyContent={"center"} xs={12} sm={5.8} md={3.8} lg={3} xl={2.2}>
              <CardComponent
                titulo={constantes.Assistencias}
                categoria={constantes.Modulo}
                descricao={constantes.MsgCardAssistencia}
                descricaoImage={constantes.Assistencias}
                route="/assistencias"
                icone="settings"
              />
            </Grid>
          }

        </Grid>

      </Box>
    </Box>
  );
}
export default Home;