
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Paper,
  Tab,
  Tabs,
  useTheme,
  useMediaQuery
} from "@mui/material";

import { AssistenciaPainel } from "./assistencias-painel";
import AssistenciaTabAnexos from "./assistencia-tab-anexos";
import AssistenciaTabPrincipal from "./assistencia-tab-principal";
import AssistenciaTabProblema from "./assistencia-tab-problema";
import AssistenciaTabSolucao from "./assistencia-tab-solucao";
import { AssistenciasContext } from "../../contexts/assistencias-context";
import { useFormikContext } from "formik";
import AssistenciaTabComentarios from "./assistencia-tab-comentarios";

import AssistenciaTabVisitaTecnica from "./assistencia-tab-visita-tecnica";

export default function AssistenciaTabs() {

  const theme = useTheme();
  const screenWidth = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    constantes,
    tabValue,
    setTab,
    fecharMenu,
    assistenciaEdicao,
    assistenciaDuplicada
  } = useContext(AssistenciasContext)

  const [abasVisiveis, setAbasVisiveis] = useState();
  
  const formik = useFormikContext();
  const { values, setValues, setFieldValue } = formik;

  function selecionarTab(event, newValue) {
    setTab(newValue);
    fecharMenu();
  }

  useEffect(() => {
    assistenciaEdicao && setFieldValue("Assistencia", assistenciaEdicao);
  }, [assistenciaEdicao]);

  useEffect(() => {
    assistenciaDuplicada && setFieldValue("Assistencia", assistenciaDuplicada);
  }, [assistenciaDuplicada]);


  function getAbasVisiveis(assistencia) {
    return [
      {
        id : 0,
        name : "Principal",
        label: constantes.Principal,
        visible : true,
        color: "#007767",
        element : <AssistenciaTabPrincipal />
      },
      {
        id : 1,
        name : "Problema",
        label : constantes.Problema,
        visible : assistencia?.Cliente ? true : false,
        color: "#007767",
        element : <AssistenciaTabProblema />
      },
      {
        id : 2,
        name : "Anexos",
        label : constantes.Anexos,
        visible : assistencia?.Cliente ? true : false,
        color: "#007767",
        element : <AssistenciaTabAnexos />
      },
      {
        id : 3,
        name : "Comentarios",
        label : constantes.Comentarios,
        visible : assistencia?.Cliente && assistencia?.Id && assistencia.Situacao !== "Rascunho" ? true : false,
        color: "#007767",
        element : <AssistenciaTabComentarios />
      },
      {
        id : 4,
        name : "AssistenciaTecnica",
        label : constantes.AssistenciaTecnica,
        visible : assistencia?.Cliente ? true : false,
        color: "#007767",
        element : <AssistenciaTabVisitaTecnica />
      },
      {
        id : 5,
        name : "Solucao",
        label: constantes.Solucao,
        visible : assistencia?.Cliente && assistencia?.Solucao ? true : false,
        color: "#007767",
        element : <AssistenciaTabSolucao />
      }
    ]
  }
  
  useEffect(() => {
    setAbasVisiveis(getAbasVisiveis(values.Assistencia));
  }, [values.Assistencia])

  return (
    <>
      <Box>

        <Box padding={2}>
          {!screenWidth && (
            <Box width="100%" height={70} component={Paper} mb={3}>
              <Tabs
                value={tabValue}
                onChange={selecionarTab}
                aria-label="Tabs"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                textColor={"#007767"}
                TabIndicatorProps={{
                  style: { backgroundColor: "#007767" },
                }}
              >
                {abasVisiveis && abasVisiveis.filter((item, index) => {
                  return item.visible
                }).map((item, index) => {
                  return <Tab label={item.label} sx={{ color: item.color }} />
                })}
              </Tabs>
            </Box>
          )}

            {abasVisiveis && abasVisiveis.filter((item, index) => {
              return item.visible
            }).map((item, index) => {
            return (
              <AssistenciaPainel value={tabValue} index={index}>
                {item.element}
              </AssistenciaPainel>
              )
            })}


        </Box>
      </Box>
    </>
  );
};