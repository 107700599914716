import { ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { Badge, Form } from "react-bootstrap";

const AtributoItemTextoControl = (props) => {

    const { atributoItemEntrada, onItemChange, tipo, index, name } = props;

    const [valueSelected, setValueSelected] = useState(atributoItemEntrada?.Valor);
    const [valueText, setValueText] = useState(atributoItemEntrada?.Valor);


    useEffect(() => {
        const atributosaida = {
            Valor: valueText,
            Id: atributoItemEntrada?.Id,
            ValorAtributoId: null,
            Atributo: atributoItemEntrada?.Atributo,
            ValorAtributo: null
        }
        valueText && valueSelected && onItemChange && valueSelected && onItemChange(
            atributosaida,
            index,
            tipo
        );
    }, [valueSelected, valueText])


    return (
        <>
            <Form.Control
                {...props}
                type="text"
                onWheel={(e) => e.target.blur()}
                value={valueSelected}
                onChange={(e) => {
                    setValueSelected(e.target.value);
                    setValueText(e.target.value);
                }}
            >

            </Form.Control>
            {name && <ErrorMessage name={name} >
                {(msg) => <Badge bg="danger">{msg}</Badge>
                }
            </ErrorMessage >}

        </>
    )
}


export default AtributoItemTextoControl;