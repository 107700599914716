import { Component } from "react";

class ServiceBase extends Component {

  state = {
    error: null
  }

  getFilter(filter) {
    let situacao = filter && filter.situacao ? "situacao eq '" + filter.situacao + "'" : null;
    let filters = filter && filter.filters
    let campo = filter && filter.campo ? filter.campo : null;
    let operacao = filter && filter.operacao ? filter.operacao : null;
    let valor = filter && filter.valor
    ?
    (typeof (filter.valor) === 'string')
      ? filter.valor.replaceAll('%', '%25')
      : filter.valor
    : null;

    let retorno = "";

    if (situacao) {
      retorno = filters ? `?$filter=${situacao} and ${filters}`: `?$filter=${situacao}`
    }
    
    if (filters) {
      retorno = situacao ? `?$filter=${situacao} and ${filters}`: `?$filter=${filters}`;
    }
  
    if (campo && operacao && valor) {
      let filtro = ""
      if (operacao === 'Contains') {
        filtro = `?$filter=Contains(${campo},'${valor}')`;
      } else if (operacao === 'StartsWith') {
        filtro = `?$filter=StartsWith(${campo},'${valor}')`;
      } else {
        filtro = `?$filter=${campo} ${operacao} '${valor}'`;
      }
  
      if (situacao) {
        filtro += ` and ${situacao} `;
      }
      if(filters) {
        filtro += ` and ${filters} `;
      }
      retorno = filtro ;
    }
  
    if (filter.page > 1) {
      retorno = retorno === "" ? "?" : retorno + "&";
      retorno = retorno + "$page=" + filter.page;
    }
    if (retorno === "" ) {
      retorno = "?$top=10";
    }
   
    const campoorder = filter.campoorder ?? null;
    const tipoorder = filter.tipoorder ?? null;

    retorno = campoorder ? `${retorno}&$orderby=${campoorder} ${tipoorder}` : retorno;

    return retorno;
  }

  SetError(error) {
    this.setState({
      error: error
    })
  }

  ClearError() {
    this.setState({
      error: null
    })
  }

}

export default ServiceBase;