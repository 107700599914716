import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Dropdown,
  Image,
  Row,
  Stack,
  Table,
} from "react-bootstrap";
import NewPaginationControl from "../components/controls_comp/control-Pagination";
import T from "./traducao";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortAsc, faSortDesc } from "@fortawesome/free-solid-svg-icons";
import "../styles/botoesBuscar.css"

function ListComp(props) {
  const constantes = T();
  const [currentObject, setCurrentObject] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [isActive, setIsActive] = useState(-1);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [pageactive, setPageActive] = useState(1);
  const [pagesstate, setPagesState] = useState(1);
  const [currentsortname, setCurrentSortName] = useState("");
  const [currentsorttype, setCurrentSortType] = useState("sort");
  const format_2 = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    minimumFractionDigits: 2,
  });

  const format_3 = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    minimumFractionDigits: 3,
  });

  const format_4 = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    minimumFractionDigits: 4,
  });

  const format_5 = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    minimumFractionDigits: 2,
  });
  const { selecionar, pages, records, onItemSelected, columns, buttons, title, onExpanded } = props;

  const toogleActive = (i) => {
    if (isActive === i) {
      setIsActive(null);
      setIsBtnDisabled(true);
    } else {
      setIsActive(i);
      setIsBtnDisabled(false);
    }
  };


  const onPageSelect = (number) => {
    props.onPageSelect(number);
  };

  const onSortSelect = (item, sorttype) => {
    props.onSortSelect && props.onSortSelect(item, sorttype);
  };

  const getSwitchType = (item, obj) => {
    switch (item.type) {
      case "checkbox":
        return <input readOnly type={"checkbox"} checked={obj}></input>;
      case "custom":
        return item.onGetCustom ? item.onGetCustom(obj) : obj;
      case "date":
        const data = new Date(obj);
        const dataformatada = data.toLocaleDateString("pt-BR", {
          timeZone: "UTC",
        });
        return dataformatada;
      case "image":
        return <Image width={100} src={obj ? obj : null} fluid />;
      default:
        return obj;
    }
  };

  const getMap = (itemdata) => {
    return columns.map((item) => {
      return {
        indexCampo: 0,
        indexDado: Object.keys(itemdata).findIndex(
          (element) => element === item.field
        ),
        key: item.key,
        type: item.type,
        digits: item.digits,
        align: item.align,
        onGetStyle: item.onGetStyle,
        onGetCustom: item.onGetCustom,
      };
    });
  };

  const getItens = (itemdata, indexlinha) => {
    let indexlist = getMap(itemdata);

    return indexlist.map((item, i) => {
      let obj = Object.values(itemdata)[item.indexDado];
      if (item.digits && item.digits > 0) {
        switch (item.digits) {
          case 2:
            obj = format_2.format(obj);
            break;
          case 3:
            obj = format_3.format(obj);
            break;
          case 4:
            obj = format_4.format(obj);
            break;
          default:
            obj = format_2.format(obj);
            break;
        }
      }

      const style = item.onGetStyle
        ? item.onGetStyle(obj)
        : getStyle(itemdata, indexlinha);

      return item.key ? (
        <th style={style} scope="row" align={item.align ?? "left"} key={i}>
          {getSwitchType(item, obj)}
        </th>
      ) : (
        <td style={style} align={item.align ?? "left"} key={i}>
          {getSwitchType(item, obj)}
        </td>
      );
    });
  };

  const getStyle = (itemdata, i) => {
    return isActive === i
      ? props.noselect
        ? null
        : { background: "#017ebc", color: "white" }
      : props.onGetStyle
        ? props.onGetStyle(itemdata)
        : null;
  };

  const getButtons = (itemdata, i) => {
    let retorno = null;

    if (props.btntype && props.btntype === "dropdown") {
      const retorno_botoes =
        buttons &&
        buttons.map((btn, index) => {
          const isVisible = btn.isVisible ? btn.isVisible(itemdata) : true;
          const _retorno = isVisible && (
            <DropdownItem
              key={index}
              value={i}
              variant={btn.variant}
              onClick={(e) => {
                btn.onClick(e);
              }}
            >
              {btn.text}
            </DropdownItem>
          );
          return _retorno;
        });

      retorno = retorno_botoes && retorno_botoes.length > 0 && (
        <Dropdown>
          <DropdownToggle variant="light" id="dropdown-basic">
            {constantes.Acoes}
          </DropdownToggle>
          <DropdownMenu>{retorno_botoes}</DropdownMenu>
        </Dropdown>
      );
    } else {
      let retorno_botoes =
        buttons &&
        buttons.map((btn) => {
          const isVisible = btn.isVisible ? btn.isVisible(itemdata) : true;
          const icon = btn.Icon ?? null;
          const btncalc = btn.getBtn ? (
            btn.getBtn(itemdata, i)
          ) : (
            <Button variant={btn.variant} onClick={btn.onClick} value={i}>
              {btn.text}
            </Button>
          );
          const _retorno = isVisible ? (
            <>
              {isVisible && " "}
              {isVisible && btncalc}
            </>
          ) : null;
          if (_retorno) {
            if (!retorno) {
              retorno = [];
            }
            retorno = retorno.concat(_retorno);
          }
          return _retorno;
        });
    }

    return retorno ? (
      <Stack direction="horizontal" gap={1}>
        {" "}
        {retorno}{" "}
      </Stack>
    ) : null;
  };

  const getHeader = (item) => {
    let sorttype = "sort";
    if (currentsortname === item.field) {
      switch (currentsorttype) {
        case "sort":
          sorttype = "asc";
          break;
        case "asc":
          sorttype = "desc";
          break;
        case "desc":
          sorttype = "sort";
          break;
        default:
          sorttype = "sort";
          break;
      }

      onSortSelect(item, sorttype);
    }

    const icon =
      sorttype === "sort"
        ? faSort
        : sorttype === "asc"
          ? faSortAsc
          : faSortDesc;
    const retorno = (
      <Stack direction="horizontal" gap={2}>
        <small>{item.headerName}</small>
        <Button
          variant="outline-secondary"
          style={{
            borderColor: "white",
          }}
          onClick={() => {
            setCurrentSortName(item.field);
            setCurrentSortType(sorttype);
          }}
        >
          <FontAwesomeIcon
            icon={icon}
            style={{
              width: 10,
            }}
          ></FontAwesomeIcon>
        </Button>
      </Stack>
    );

    return props.onSortSelect ? retorno : item.headerName;
  };

  const getExpanded = (itemdata) => {
    const _onExpanded = onExpanded && onExpanded(itemdata);
    return  _onExpanded && (
      <tr key={itemdata.$id} >
        <td style={{ width:"100%",padding: 0,}} colSpan={columns.length}>
          {_onExpanded}
        </td>
      </tr>
    )
  }

  const getBodyTable = () => {
    const { data } = props;
    const dados = data?.map((itemdata, i) => (
      <>
        <tr
          key={i}
          style={getStyle(itemdata, i)}
          onClick={() => {
            toogleActive(i);
          }}
        >
          {getItens(itemdata, i)}

          {getButtons(itemdata, i)}
        </tr>


        {getExpanded(itemdata)}

      </>

    ));

    const striped = props.striped ?? true;

    return (
      <Table responsive hover striped={striped} bordered size="sm">
        <thead>
          <tr>
            {data &&
              data.length > 0 &&
              columns.map((item, i) => (
                <th align={item.align ?? "left"} key={i}>
                  {getHeader(item)}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>{dados}</tbody>
      </Table>
    );
  };

  const getSearchControl = () => {
    return props.SearchControl;
  };

  const onBtnClick = () => {
    const { data } = props;
    if (isActive !== -1 && onItemSelected != null) {
      onItemSelected && onItemSelected(data[isActive]);
    }
  };



  const isEnabled = isBtnDisabled;

  return (
    <Container>
      <h4>{title}</h4>
      {getSearchControl()}
      {getBodyTable()}
      <NewPaginationControl
        pages={pages}
        records={records}
        onPageSelect={(number) => {
          onPageSelect(number);
        }}
      />
      {selecionar ? (
        <div>
          <Row>
            <Button
              bsPrefix="ButtonStyle selecionar"
              type="submit"
              onClick={() => onBtnClick()}
              disabled={isEnabled}
            >
              {constantes.Selecionar}
            </Button>
          </Row>
        </div>
      ) : null}
    </Container>
  );
}

export default ListComp;