import React, { useRef, useEffect, useState, forwardRef } from "react";
import { useApiIsLoaded } from "@vis.gl/react-google-maps";
import { Box, Paper } from "@mui/material";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

export const PlaceAutocompleteClassic = forwardRef((props, ref) => {
  const { onMarkerSelected, onPlaceSelect, places,markers, defaultValue } = props;
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
  const inputRef = useRef(null);

  const isLoaded = useApiIsLoaded();

  const options = {
    fields: ["geometry", "name", "formatted_address"],
  };

  useEffect(() => {
    if (!places) return;

    const autoComplete = new places.Autocomplete(inputRef.current, options);
    setPlaceAutocomplete(autoComplete);

    const style = document.createElement('style');
    style.innerHTML = `
      .pac-container {
        background-color: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 4px;
        z-index:2000;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.055);
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };

  }, [places]);

  useEffect(() => {
    if (defaultValue && defaultValue !== "" && places) {
      inputRef.current.value = defaultValue;
      if (places.AutocompleteService && !markers.lat && !markers.lng) {
        const autocompleteService = new places.AutocompleteService();
        autocompleteService.getPlacePredictions(
          { input: defaultValue },
          (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions.length > 0) {
              const selectedPrediction = predictions[0];
              if (places.PlacesService) {
                const placesService = new places.PlacesService(document.createElement("div"));
                placesService.getDetails({ placeId: selectedPrediction.place_id, fields: options.fields }, (place, status) => {
                  if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    onPlaceSelect(place);
                  }
                });
              }
            }else{
              toast.error("Endereço não encontrado! Verifique o endereço novamente")
            }
          }
        );
      }
    }
  }, [defaultValue, places]);

  useEffect(() => {
    if (isLoaded && placeAutocomplete) {
      placeAutocomplete.addListener("place_changed", () => {
        onPlaceSelect(placeAutocomplete.getPlace());
      });
    }
  }, [isLoaded, onPlaceSelect, placeAutocomplete]);

  return (
    <Paper elevation={4} sx={{ width: 500, margin: "0.5rem 0", padding: "16px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 4,
        }}
      >
        <Form.Control ref={inputRef}></Form.Control>

        {onMarkerSelected && (
          <Button onClick={onMarkerSelected}>Confirmar</Button>
        )}
      </Box>
    </Paper>
  );
});
