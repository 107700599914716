import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import NewGerarPedido from "../../controls_comp/control-gerarpedido";

const NewModalGerarPedido = (props) => {

    const [modal, setModdal] = useState(false);
    const { toggle, className } = props;

    useEffect(() => {
        setModdal(props.modal);
    }, [props.modal])

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={modal}
            onHide={toggle}
            className={className}>
            <ModalHeader toggle={toggle} closeButton></ModalHeader>
            <ModalBody>
                <NewGerarPedido {...props} />
            </ModalBody>
            <ModalFooter>
                <Button variant="primary" onClick={toggle}>OK</Button>
            </ModalFooter>
        </Modal>
    )
}

export default NewModalGerarPedido;
