import { AssistenciasContext } from '../../contexts/assistencias-context';
import React, { useContext, useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from "@mui/material";
import { GetResponsaveis } from "../../config/localstorageConfig/localstorage";
import { Button } from "react-bootstrap";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import "react-datepicker/dist/react-datepicker.css";

function AssistenciaComentarioAnexo() {

    const {
        modalAnexoComentario,
        fecharmodalAnexoComentario,
        constantes,
        modalContentPreviewAnexosComentarios
    } = useContext(AssistenciasContext);

    

    const pdfjs = require('pdfjs-dist');

    return (
        <Dialog
            open={modalAnexoComentario}
            onClose={fecharmodalAnexoComentario}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle> 
                <Typography><AttachFileIcon sx={{ marginBottom: "5px", marginRight: "4px"}} />{constantes.VisualizarAnexo}</Typography> 
                <Divider sx={{background: "black",}}/>
            </DialogTitle>

            <DialogContent>
                <DialogContentText>{modalContentPreviewAnexosComentarios}</DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => fecharmodalAnexoComentario()} style={{background:"crimson",border:"none"}}>
                    {constantes.Fechar}
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default AssistenciaComentarioAnexo;