import { Alert } from '@mui/material';

export const AlertWarn = ({icon, severity,children, ...rest}) => {
     return <Alert icon={icon} severity="warning" {...rest}  >{children}</Alert>
}

export const AlertError = ({icon, severity,children, ...rest}) => {
    return <Alert icon={icon} severity="error" {...rest}  >{children}</Alert>
}

export const AlertInfo = ({icon, severity,children, ...rest}) => {
    return <Alert icon={icon} severity="info" {...rest}  >{children}</Alert>
}

export const AlertSuccess = ({icon, severity,children, ...rest}) => {
    return <Alert icon={icon} severity="success" {...rest}  >{children}</Alert>
}