import React, { useEffect, useState } from "react";
import OrcamentoVendaService from "../../../../services/service.orcamentovenda";
import {
  Alert,
  Button,
  Container,
  ListGroup,
  ListGroupItem,
  ProgressBar,
  Spinner,
} from "react-bootstrap";
import T from "../../../../common/traducao";
import { Box, Divider, Grid, LinearProgress, Progress, Typography } from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PrintIcon from "@mui/icons-material/Print";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import serviceAssistencias from "../../../../services/service.assistencias";
export default function NewImprimirAssistencia({ idAssistencia }) {
  const constantes = T();
  const [listaImpressoes, setListaImpressoes] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const [erroImpressao, setErroImpressao] = useState();
  const [openCollapsePrincipaisImpressoes, setOpenCollapsePrincipaisImpressoes] = React.useState(true);

  const navigate = useNavigate()

  const handleCollapseComDescontos = () => {
    setOpenCollapsePrincipaisImpressoes(!openCollapsePrincipaisImpressoes)
  }

  async function GetRelatorios() {
    await serviceAssistencias.getListaRelatoriosAssistencias(idAssistencia)
      .then((response) => {
        console.log(response.data)
        setListaImpressoes(response.data.Impressoes);
       
      })
      .catch((error) => {
        const _error =
          error.code === "ERR_NETWORK"
            ? "Server OffLine"
            : error.response && error.response.data && error.response.data.error
            ? error.response.data.error.message
            : error.message;
        setErroImpressao(_error);
        toast.error(error.message)
        navigate("/assistencias")
      })
      .finally(() => {});
  }

  async function ImprimirRelatorio(link) {

    setLoading(true);
    await serviceAssistencias.imprimirRelatoriosAssistencias(link)
      .then((response) => {
        try {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          const pdfWindow = window.open();
          pdfWindow.location.href = fileURL;
          setLoading(false);
      } catch (error) {
          navigate("/assistencias")
          toast.error("Ocorreu um erro ao tentar abrir o arquivo. Por favor, tente novamente mais tarde.");
      }
      })
      .catch((error) => {
        const _error =
          error.code === "ERR_NETWORK"
            ? "Server OffLine"
            : error.response && error.response.data && error.response.data.error
            ? error.response.data.error.message
            : error.message;
        setErroImpressao(error);
        toast.error(error.message)
        navigate("/assistencias")
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    GetRelatorios();
  }, []);

  function ListagemRelatorios() {
    const alertClicked = (link) => {
      ImprimirRelatorio(link);
    };



    return (
      <>
        <Grid container display={"flex"} flexDirection={"column"} gap={1}>
          <Grid item sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <PrintIcon  sx={{color: "#5e5e5e"}} />
            <Typography
              sx={{ color: "#5e5e5e", fontSize: "22px" }}
            >{`${constantes.ImpressaoAssistencia} ${idAssistencia}`}</Typography>
          </Grid>
          <Divider sx={{color: "black", background:"black"}} />
          <Grid item xl={15} sx={{ display: "flex", flexDirection: "column" }}>
            <List
              sx={{
                width: "100%",
                maxWidth: "100%",
                bgcolor: "background.paper",
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton onClick={handleCollapseComDescontos}>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={constantes.ImpressoesPrincipais} />
                {openCollapsePrincipaisImpressoes ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openCollapsePrincipaisImpressoes} timeout="auto" unmountOnExit>
                {listaImpressoes &&
                  listaImpressoes.map((item, index) => {
                    return (
                      <>
                        <ListItemButton
                          disabled={loading}
                          key={index}
                          sx={{ pl: 4 }}
                          onClick={() => alertClicked(item.LinkDownload)}
                        >
                          <ListItemIcon>
                            <TextSnippetIcon />
                          </ListItemIcon>
                          <ListItemText primary={item.Descricao} />
                        </ListItemButton>
                      </>
                    );
                  })}
              </Collapse>

            </List>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      {ListagemRelatorios()}

     
      {loading && (
        <Box sx={{display: "flex", flexDirection:"column",gap: 1, fontSize: "15px",}}>
          <Typography sx={{fontWeight: "bold", color: "#707070"}}>{constantes.MsgAguardeImprimirAssistencia}</Typography>
          <LinearProgress color="inherit"/>
        </Box>
      )}

      {erroImpressao && <Alert variant="danger">{erroImpressao}</Alert>}
    </>
  );
}