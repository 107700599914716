import React, { useContext, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  InputLabel,
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { AssistenciasContext } from "../../contexts/assistencias-context";
import { Button } from "react-bootstrap";
import { useFormikContext } from "formik";
import PreviewIcon from "@mui/icons-material/Preview";
import { IoMdDocument } from "react-icons/io";
import { RiImageFill } from "react-icons/ri";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Box } from "@mui/system";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DownloadIcon from "@mui/icons-material/Download";


const ModalPreviewAnexos = ({ indexPreview }) => {
  const {
    constantes,
    fecharModalAnexosComentarios,
    modalPreviewAnexosComentarios,
    formatFilesSize,
    listaAnexosComentarios,
    loadingAnexos,
  } = useContext(AssistenciasContext);

  const [modalOpen, setModalOpen] = useState();
  const [modalContent, setModalContent] = useState([]);
  const [itemSelecionado, setItemSelecionado] = useState();
  const { values } = useFormikContext();
  const pdfjs = require("pdfjs-dist");
  const [itemDowloadSelecionado, setItemDowloadSelecionado] = useState();
  const [loadingDowloadSelecionado, setLoadingDowloadSelecionado] =
    useState(false);
  const [indexParaDowload, setIndexParaDowload] = useState();

  const handleDownloadAnexo = async (item) => {
    setLoadingDowloadSelecionado(true);

    const downloadUrl = `${process.env.REACT_APP_BASE_URL}${item.AnexoLink}`;
    const fileName = item.NomeArquivo;

    await fetch(downloadUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(constantes.ErrorDowload);
        }
        return response.blob();
      })
      .then((blob) => {
        saveAs(blob, fileName);
      })
      .catch((error) => {
        setLoadingDowloadSelecionado(true);
        console.error("Erro:", error);
      })
      .finally(() => {
        setLoadingDowloadSelecionado(false);
      });
  };

  const handlePreview = (item) => {
    setModalOpen(true);
    setItemSelecionado(item);
    if (item.TipoArquivo === ".pdf") {
      setModalContent(
        <Worker
          workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
        >
          <Viewer
            fileUrl={`${process.env.REACT_APP_BASE_URL}${item.AnexoLink}`}
          />
        </Worker>
      );
    } else if (item.TipoArquivo === ".png" || ".jpg") {
      setModalContent(
        <img
          src={`${process.env.REACT_APP_BASE_URL}${item.AnexoLink}`}
          alt={item.NomeArquivo}
          style={{ width: "100%", borderRadius: 5 }}
        />
      );
    } else {
      setModalContent(
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#ccc",
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            {" "}
            {constantes.MsgAnexoNaoCarregado}
          </Typography>
        </Box>
      );
    }
  };

  return (
    <>
      <Dialog
        open={modalPreviewAnexosComentarios}
        onClose={fecharModalAnexosComentarios}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <Typography sx={{ fontSize: 20 }}>
            <PreviewIcon style={{ marginBottom: "5px", marginRight: "8px" }} />
            {constantes.PreviewAnexos}
          </Typography>
        </DialogContent>

        <DialogContent>
          <Grid
            container
            display={"flex"}
            justifyContent="center"
            spacing={2}
            p={0}
          >
            <Grid
              item
              flexDirection={"column"}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              display="flex"
            >
              {listaAnexosComentarios.length > 0 ? (
                <List sx={{ maxHeight: 250, overflowX: "auto" }}>
                  {!loadingAnexos ? (
                    <>
                      {listaAnexosComentarios.map((anexo, index) => (
                        <ListItem
                          key={index}
                          sx={{ width: "100%", overflowX: "auto" }}
                        >
                          <a
                            onMouseMove={() => {
                              setItemDowloadSelecionado(index);
                            }}
                            onClick={() => {
                              handleDownloadAnexo(anexo);
                              setIndexParaDowload(index);
                            }}
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            {loadingDowloadSelecionado && indexParaDowload === index ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  paddingRight: "10px",
                                }}
                              >
                                <CircularProgress size={18} color="inherit" />
                              </Box>
                            ) : (
                              <Box>
                                {itemDowloadSelecionado !== index ? (
                                  <InsertDriveFileIcon
                                    fontSize={"medium"}
                                    sx={{ marginRight: "10px", cursor: "pointer" }}
                                  />
                                ) : (
                                  <DownloadIcon
                                    fontSize={"medium"}
                                    sx={{ marginRight: "10px", cursor: "pointer" }}
                                  />
                                )}
                              </Box>
                            )}
                          </a>
                          <ListItemText
                            primary={anexo.NomeArquivo}
                            secondary={formatFilesSize(anexo.TamanhoArquivo)}
                          />
                          <ListItemSecondaryAction
                            sx={{ display: "flex", gap: 1 }}
                          >
                            <Button
                              style={{ background: "#0D9B86", border: "none" }}
                              onClick={() => {
                                handlePreview(anexo);
                              }}
                            >
                              {constantes.Visualizar}
                            </Button>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </>
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress color="inherit" />
                      </Box>
                    </>
                  )}
                </List>
              ) : (
                <Box sx={{ textAlign: "center", marginTop: 2 }}>
                  <Typography
                    sx={{ fontSize: 18, fontWeight: "bold", color: "#969696" }}
                  >
                    {constantes.Nenhumanexoaqui}!{" "}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ background: "crimson", border: "none" }}
            onClick={fecharModalAnexosComentarios}
          >
            {constantes.Voltar}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <IoMdDocument
              style={{
                marginRight: "5px",
                fontSize: "30px",
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                {constantes.VisualizarAnexo}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                <Typography sx={{ fontSize: 12, color: "#707070" }}>
                  {itemSelecionado?.NomeArquivo} |
                </Typography>
                <Typography sx={{ fontSize: 10, color: "#707070" }}>
                  {formatFilesSize(itemSelecionado?.TamanhoArquivo)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>{modalContent}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setModalOpen(false)} style={{ background: "crimson", border: "none" }}>
            {constantes.Fechar.toLowerCase()}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalPreviewAnexos;
