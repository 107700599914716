import { ErrorMessage, useField, useFormikContext } from "formik";
import { forwardRef } from "react";
import { Form, Badge, Button, Stack } from "react-bootstrap";
import InputMask from "react-input-mask";
import axios from "axios";
import { TravelExplore } from "@mui/icons-material";
import { toast } from "react-toastify";
import MunicipioService from '../../services/service.municipio';

const EditCepControl = forwardRef((props, ref) => {
    const { name, placeholder, hideTitle, value, onChange, disabled, onCepResponse } = props;

    const { setValues, values, handleChange, validateOnMount } = useFormikContext();
    const [fieldId, metaId, handleId] = useField(name);

    const buscarCep = async (cep, tabIndex) => {
        console.log(cep)
        axios.get(`https://viacep.com.br/ws/${cep}/json/`)
            .then((response) => {
                const enderecoResposta = response.data;
                console.log(enderecoResposta);
                MunicipioService.getByCodigoFederal(Number(enderecoResposta.ibge))
                    .then((response) => {
                        const municipioResposta = response.data;
                        onCepResponse && onCepResponse(enderecoResposta, municipioResposta)
                    })
                    .catch((error) => {
                        toast.error("Município não encontrado.")
                    })
            })
            .catch((error) => {
                console.error("Erro ao buscar CEP", error);
                toast.error(error);
            });
    }

    return (
        <Form.Group>
            {!hideTitle && <Form.Label className="mb-0">{placeholder}</Form.Label>}

            <Stack direction="horizontal" gap={1}>
                <InputMask
                    {...props}
                    style={{ width: 120 }}
                    disabled={disabled}
                    ref={ref}
                    name={name}
                    value={value ? value : metaId.value}
                    onChange={(e) => {
                        handleChange(e)
                        onChange && onChange(e);
                    }}
                    onWheel={(e) => e.target.blur()}
                    mask={"99999-999"}
                >
                    {(inputProps) =>
                        <Form.Control {...inputProps} disabled={disabled} placeholder="" />
                    }
                </InputMask>

                {onCepResponse && <Button
                    disabled={disabled || !metaId.value}
                    style={{
                        background: "#0D9B86",
                        margin: "0 10px",
                        border: "none",
                        "&:hover": {
                            background: "#0D8A7A",
                        },
                        "&:active": {
                            background: "#0D7261",
                        },
                    }}
                    onClick={() => buscarCep(metaId.value, 1)}
                >
                    <TravelExplore sx={{ color: "white", margin: 0 }} />
                </Button>}
            </Stack>

            {validateOnMount && metaId.error && <Badge bg="danger">{metaId.error}</Badge>}

            {
                !validateOnMount && <ErrorMessage name={props.name}>
                    {(msg) => <Badge bg="danger">{msg}</Badge>}
                </ErrorMessage>
            }

        </Form.Group >
    );
});

export default EditCepControl;

