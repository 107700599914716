import { useEffect, useState } from "react";
import { GetTiposFrete } from "../../config/localstorageConfig/localstorage";
import SelectControl from "./control-select";
import T from '../../common/traducao'
const TipoFreteControl = (props) => {
  const [tipos, setTipos] = useState();
  const constantes = T();
  const {onBlur} = props;

  useEffect(() => {
    const tiposfrete = GetTiposFrete();
    const newtiposfrete =
      tiposfrete &&
      tiposfrete.map((item, i) => {
        return {
          Id: item.Id,
          Descricao: item.Nome,
          Valor: parseInt(item.Id),
          ModoUso: item.ModoUso,
          IdModoUso: item.IdModoUso,
        };
      });
    setTipos(newtiposfrete);
  }, []);

  return (
    tipos && (
      <SelectControl
        {...props}
        onBlur={(e) => onBlur ? onBlur(e) : e.target.blur()}
        name={"OrcamentoVenda.TipoFrete"}
        placeholder={constantes.TipodoFrete}
        itens={tipos}
      />
    )
  );
};

export default TipoFreteControl;
