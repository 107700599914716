import { useContext } from "react";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import { Col, Row } from "react-bootstrap";
import CampoMemo from "../campos_comp/campo-memo";

const OrcamentoVendaObservacoesCtx = () => {

    const {
        somenteLeitura,
        constantes,
    } = useContext(OrcamentoVendaContext);

    return (
        <>
            <Row>
                <Col xs={12} md={12} className="mb-3">
                    <CampoMemo
                        disabled={somenteLeitura}
                        name="OrcamentoVenda.ObservacaoVenda"
                        placeholder={constantes?.ObservacaoVenda}
                    />
                </Col>
                <Col xs={12} md={12} className="mb-3">
                    <CampoMemo
                        disabled={somenteLeitura}
                        name="OrcamentoVenda.ObservacaoInterna"
                        placeholder={constantes?.ObservacaoInterna}
                    />
                </Col>
                <Col xs={12} md={12} className="mb-3">
                    <CampoMemo
                        disabled={somenteLeitura}
                        name="OrcamentoVenda.ObservacaoFiscal"
                        placeholder={constantes?.ObservacaoFiscal}
                    />
                </Col>
            </Row>
        </>
    )
}

export default OrcamentoVendaObservacoesCtx;