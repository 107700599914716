import { forwardRef } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import GoogleMaps from "../../map";
import { Box, Typography } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";

const ModalMapControl = forwardRef((props, ref) => {
  const {
    modal,
    className,
    toggle,
    onSelectedMarker,
    markerAtual,
    defaultValue,
    constantes,
  } = props;

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={modal}
      onHide={toggle}
      className={className}
      style={{
        content: {
          position: "relative",
          inset: "true",
          width: "400px",
          margin: "auto",
        },
      }}
    >
      <ModalHeader toggle={toggle} closeButton>
        <Box
          sx={{
            display: "flex",
            flexDirection:"row",
            alignItems:"center",
            height:"100%",
            gap: 0.5,
          }}
        >
          <PlaceIcon fontSize="medium" />
          <Typography sx={{ fontSize: 18, marginTop:"0.1rem",textAlign: "center" }}>
            {" "}
            {constantes?.SelecionarLocalizacao}{" "}
          </Typography>
        </Box>
      </ModalHeader>
      <ModalBody>
        <GoogleMaps
          defaultValue={defaultValue}
          markerAtual={markerAtual}
          onSelectedMarker={onSelectedMarker}
        />
      </ModalBody>
    </Modal>
  );
});

export default ModalMapControl;
