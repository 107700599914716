import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import QuantidadeCtx from "./itemorcamentovenda-quantidade";
import { useFormikContext } from "formik";
import { useContext } from "react";
import { ItemOrcamentoVendaContext } from "../../contexts/itemorcamentovenda-context";
import { format_currency_2, format_currency_4 } from "../../common/format";

const QuantidadeEPrecosCtx = () => {

    const {
        constantes,
        precoUnitario,
        loadingGlobal,
        unidadeMedida,
        totalItem,
        precoTabela,
        precoTabelaComDesconto
    } = useContext(ItemOrcamentoVendaContext);

    const {
        values,
        setFieldValue,
        resetForm
    } = useFormikContext();

    return (
        <Container >
            <Row>
                <Col>
                    <QuantidadeCtx />
                </Col>

                <Col>
                    <Form.Label className="mb-0">{constantes.UnidadeMedida}  {loadingGlobal ? <Spinner animation="border" size="sm" variant="secondary" /> : ""} </Form.Label>
                    <Form.Control value={unidadeMedida} disabled />
                </Col>
                <Col>
                    <Form.Label className="mb-0">{constantes.PrecoTabela} {loadingGlobal ? <Spinner animation="border" size="sm" variant="secondary" /> : ""}  </Form.Label>
                    <Form.Control value={format_currency_2.format(precoTabela ?? 0)} disabled />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label className="mb-0">{constantes.PrecoTabelaComDesconto} {loadingGlobal ? <Spinner animation="border" size="sm" variant="secondary" /> : ""} </Form.Label>
                    <Form.Control value={format_currency_2.format(precoTabelaComDesconto ?? 0)} disabled />
                </Col>
                <Col>
                    <Form.Label className="mb-0">{constantes.PrecoUnitario} {loadingGlobal ? <Spinner animation="border" size="sm" variant="secondary" /> : ""} </Form.Label>
                    <Form.Control value={format_currency_4.format(precoUnitario ?? 0)} disabled />
                </Col>
                <Col>
                    <Form.Label className="mb-0">{constantes.TotalItem} </Form.Label>
                    <Form.Control style={{ fontSize: 25, height: "4.2vh", textAlign: 'right', fontWeight: 'bold' }} value={format_currency_2.format(totalItem ?? 0)} disabled />
                </Col>
            </Row>
        </Container>
    )
}

export default QuantidadeEPrecosCtx;