import React from 'react'
import T from '../../../common/traducao';
import { FaArrowRight } from "react-icons/fa";
import { Button, Icon, Divider, Typography,useTheme, useMediaQuery, Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Icone } from '../Icons/Icon';
import { Link, useNavigate } from 'react-router-dom';
export default function CardComponent({ 
  titulo,
  categoria,
  tituloImage,
  descricao,
  descricaoImage,
  image,
  route,
  icone
}) {
    const constantes = T();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.up('xs'));
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));
    const navigate = useNavigate()

    const handleNavigateModule = (path) => {
      navigate(navigate)
    }

  return (
    <Card sx={{
        maxWidth: "100%",
        marginTop: 2,
        transition: "transform 0.6s ease",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: `0px 5px 15px rgba(0, 0, 0, 0.2)`, 
        },
      }}>
        <Link to={route} style={{textDecoration:"none"}}> 
        <CardMedia
            sx={{
              background: "linear-gradient(10deg, rgba(2,0,36,1) 0%, rgba(23,161,144,1) 60%, rgba(0,212,255,1) 100%)",   
              height: 260,
            }}
            title="Gerar orçamento venda"
          >
            <Box
              sx={{
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                color: "#fff",
                fontSize: "1.5rem",
                width: "100%",
                height: "100%"
              }}
            >
              <Typography
                sx={{
                  fontFamily: "sans-serif",
                  fontSize: 16,
                  color: "rgba(240, 240, 240, 0.9)",
                  textShadow: "1.5px 2.5px 6px rgba(0, 0, 0, 1)",
                  ...(isXs && { fontSize: 14}) 
                }}
              >
                {tituloImage} 
              </Typography>
              <Typography
                sx={{
                  fontFamily: "revert-layer",
                  fontWeight: "bold",
                  fontSize: 25,
                  color: "#ededed",
               
                  textShadow: "4px 3px 4px rgba(0, 0, 0, 1)",
                  ...(isXs && { fontSize: 20 }),
                  ...(isSm && { fontSize: 22 })
                }}
              >
                {descricaoImage} <Icone icone={icone} sx={{ marginLeft: "2px",marginBottom: "-5px",color: "white", borderRadius: 8 }} />
              </Typography>
            </Box>
          </CardMedia>
        </Link>
        
          <CardContent sx={{ height: 120, padding: "8px 15px" }}>
          <Typography
              variant="p"
              sx={{ margin: 0 ,color: "#6b6b6b",fontSize: 12,fontWeight:"bold"  }}
              component="p"
            >
              {categoria}
            </Typography>
            <Typography
              gutterBottom
              variant="h5"
              sx={{
                color: "#009180",
                ...(isXs && { fontSize: 18})
              }}
              component="h5"
              
            >
              {titulo}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ ...(isXs && { fontSize: 13})}}>
              {descricao}
            </Typography>
          </CardContent>
          <CardActions
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
              marginRight: 1,
            }}
          >
            <Button
              href={route}
              sx={{
                fontSize: 15,
                color: "#009180",
                gap: 1,
                textDecoration: "none",
                "&:hover": { color: "#009180" },
              }}
              size="small"
            >
              {constantes.Acessar}
              <FaArrowRight color="#009180" />
            </Button>
          </CardActions>
        </Card>
  )
}