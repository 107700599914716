import { Field, Formik, useFormikContext } from "formik";
import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import T from "./traducao";
import { blockInvalidChar } from "../utils/blockkeys";
import "../styles/botoesBuscar.css"
import DatePickerField from "../components/controls_comp/control-datepicker-field";

const GetCollumns = (props) => {
  const { setFieldValue, handleChange, resetForm } = useFormikContext();
  const { campos, onSetFocusChanged } = props;

  const list = campos.map((item, i) => (
    <option key={item.field} value={item.field} tipo={item.tipo}>
      {item.headerName}
    </option>

  ));

  return (
    <Field name={"searchField"}>
      {({ field, form }) => (
        <Form.Select
          {...field}
          onChange={(e) => {
            handleChange(e);
            setFieldValue(
              "tipo",
              campos[e.target.options.selectedIndex].tipo
            );
            setFieldValue("searchValue", "");
            onSetFocusChanged && onSetFocusChanged();
          }}
          aria-label="Default select example"
        >
          {list};
        </Form.Select>
      )}
    </Field>
  );
};

const FormObserverFilter = (props) => {
  const { values, setFieldValue } = useFormikContext();
  const { setOperator } = props;
  useEffect(() => {
    setOperator && setOperator(values.tipo);
    values.tipo === "text"
      ? setFieldValue("searchoperator", "Contains")
      : setFieldValue("searchoperator", "eq");
  }, [values.tipo]);
};

const SearchComp = (props) => {
  const { title, botaoDisabilitado, to, criarOrcamento, hasBotao, records, storagename } = props;

  const constantes = T();
  const onSearch = props.onSearch && props.onSearch.bind(this);
  const [operator, setOperator] = useState("number");
  
  const refInput = useRef(null);


  function getFilter() {
    const operatorOptions = operator === "text"
      ? [
        { key: "Contains", value: constantes.Contem },
        { key: "StartsWith", value: constantes.Iniciacom },
      ]
      : operator === "checkbox" 
      ? [
        { key: "eq", value: constantes.Igual },
      ]
      : [
        { key: "eq", value: constantes.Igual },
        { key: "ge", value: constantes.Apartir },
        { key: "le", value: constantes.Ate }
      ];
    return (

      <Field name={"searchoperator"}>
        {({ field, form }) => (
          <Form.Select 
           {...field}           
           aria-label="Default select example">
            {operatorOptions.map((option, index) => (
              <option key={index} value={option.key} type={operator === "text" ? "text" : "number"}>
                {option.value}
              </option>
            ))}
          </Form.Select>
        )}
      </Field>
    )
  }
  // Recupera os Dados e exibe nos Selects

  const initialValues =
    storagename ?
      {
        tipo: localStorage.getItem(`saved_${storagename}`) ?? "number",
        searchoperator: localStorage.getItem(`savedSearchOperator_${storagename}`) ?? "eq",
        searchField: localStorage.getItem(`savedSearchField_${storagename}`) ?? "Id",
        searchValue: "",
      } : {
        tipo: "number",
        searchoperator: "eq",
        searchField: "Id",
      };

  return (
    <Formik
      initialValues={initialValues}

      onSubmit={(values) => {
        //armazena os dados no storage
        if (storagename) {
          localStorage.setItem(`saved_${storagename}`, values.tipo);
          localStorage.setItem(`savedSearchOperator_${storagename}`, values.searchoperator);
          localStorage.setItem(`savedSearchField_${storagename}`, values.searchField);
        }

        onSearch && onSearch(values);
      }}
    >
      {({ values, handleSubmit, setFieldValue}) => (
        <Form onSubmit={handleSubmit}>
          <Container>
            <div className="d-flex align-items-center justify-content-between my-3">
              <h1 className="text-black-50">
                {`${title ? title : constantes.Pesquisa}`}
              </h1>
              {hasBotao && (
                <Button
                  bsPrefix="ButtonStyle NovoOrcamento"
                  disabled={botaoDisabilitado}
                  href={to}
                  onClick={criarOrcamento}
                >
                  {constantes.Novo}
                </Button>
              )}
            </div>
            <hr className="" />
            <Card>
              <Card.Body>
                <div>
                  <h4> {constantes.PesquisarPor} </h4>
                </div>
                <InputGroup style={{ flex: 1 }}>
                  <FormObserverFilter setOperator={setOperator} />
                  <Container fluid>
                    <Row className="d-flex justify-content-center gap-0">
                      <Col xs={12} md={2}>
                        {props.campos && <GetCollumns {...props} onSetFocusChanged={() => {                          
                          refInput?.current?.focus();
                        }} />}
                      </Col>
                      <Col xs={12} md={2}>
                        {props.campos && getFilter()}
                      </Col>
                      <Col>
                      {props.campos && <Field name={"searchValue"}>
                          {({ field, form }) => (
                            values.tipo === 'date' ?
                              <DatePickerField
                                {...field}
                                {...props}
                                ref={refInput}
                              />
                              : values.tipo === 'checkbox' ?
                              <>
                              <input
                                {...field}
                                ref={refInput}
                                type={values.tipo}
                              />
                              </>
                              :
                              <input
                                {...field}
                                ref={refInput}
                                type={values.tipo}
                                className="form-control form-control-search"
                                placeholder={constantes.DigiteAPesquisa}
                                onKeyDown={blockInvalidChar}
                                onWheel={(e) => e.target.blur()}
                              />
                          )}
                        </Field>}
                      </Col>
                      <Col xs={12} md={2}>
                        <Button

                          bsPrefix="ButtonStyle BtnBuscar"
                          type="submit"
                        >
                          {constantes.Buscar}
                        </Button>

                      </Col>
                    </Row>
                    {records && <Row>
                      <Col>
                        {constantes.TotalRegistros}
                      </Col>
                      <Col>{records}</Col>
                    </Row>}
                  </Container>
                </InputGroup>
              </Card.Body>
            </Card>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default SearchComp;
