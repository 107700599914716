import { Accordion, Button, Container, Stack } from "react-bootstrap";
import IdentificacaoItemCtx from "./itemorcamentovenda-identificacao";
import SelecaoReferenciaCtx from "./itemorcamentovenda-selecaoreferencia";
import SelecaoProdutoCtx from "./itemorcamentovenda-selecaoproduto";
import ItemFinameCtx from "./itemorcamentovenda-itemfiname";
import QuantidadeEPrecosCtx from "./itemorcamentovenda-quantidadeepreco";
import ItemOrcamentoVendaModalsCtx from "./itemorcamentovenda-modals";
import { useContext } from "react";
import { ItemOrcamentoVendaContext } from "../../contexts/itemorcamentovenda-context";
import ItemOrcamentoVendaDescontosCtx from "./itemorcamentovenda-descontos";

import NewCheckBoxControl from "../controls_comp/control-checkbox.jsx";
import NewGruposProdutosVendasControl from "../controls_comp/control-gruposprodutosvenda.jsx";
import CampoMemo from "../campos_comp/campo-memo.jsx";

const ItemOrcamentoVenda = (props) => {

    const {
        constantes,
        itensFiname,
        deveMostrarSobMedida,
        somenteLeitura,
        continuar
    } = useContext(ItemOrcamentoVendaContext);

    return (
        <>
            <ItemOrcamentoVendaModalsCtx />
            <Accordion defaultActiveKey="0">

                <Stack gap={2}>
                    <IdentificacaoItemCtx />
                    <SelecaoReferenciaCtx />
                    <SelecaoProdutoCtx />
                    <QuantidadeEPrecosCtx />
                    {itensFiname && itensFiname.length > 0 &&
                        <ItemFinameCtx />
                    }

                    {
                        deveMostrarSobMedida &&
                        <Container>
                            <NewCheckBoxControl disabled={somenteLeitura} name="ItemOrcamentoVenda.SobMedida" placeholder={constantes.SobMedida} />
                        </Container>
                    }

                    <Container>
                        <Accordion.Item eventKey="0" >
                            <Accordion.Header>
                                {constantes.Descontos}
                            </Accordion.Header>
                            <Accordion.Body>
                                <ItemOrcamentoVendaDescontosCtx />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Container>

                    <Container>
                        <small>{constantes.IDProjetoGrupo}</small>
                        <NewGruposProdutosVendasControl disabled={somenteLeitura} />
                    </Container>

                    <Container>
                        <small>{constantes.Observacoes}</small>
                        <CampoMemo disabled={somenteLeitura} hideTitle name="ItemOrcamentoVenda.Observacoes" placeholder={constantes.Observacoes} />
                    </Container>

                    <Container>
                        <Button type="submit" variant="primary" disabled={somenteLeitura} >{continuar ? constantes.AddEContinuar : constantes.Confirmar}</Button>
                    </Container>
                </Stack>
            </Accordion>

        </>
    )

}

export default ItemOrcamentoVenda;