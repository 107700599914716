import React, {
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import SearchComp from "../../common/searchcomp";
import orcamentovendaService from "../../services/service.orcamentovenda";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NewAlertError from "../../components/ui_comp/Alerts/views.alert.error";
import T from "../../common/traducao";
import {
    GetPermissaoEditar,
    GetPermissaoExcluir,
    GetPermissaoInserir,
    GetPermissaoVisualizar,
    getFiltro,
    getToken,
    setFiltro,
} from "../../config/localstorageConfig/localstorage";
import jwt_token from "jwt-decode";
import '../../styles/botoesfiltrosrapidos.css'
import NewModalDuplicarOrcamento from "../../components/ui_comp/Modals/ModalDuplicarOrcamentoControl";
import NewModalGerarPedido from "../../components/ui_comp/Modals/ModalGeraPedidoControl";
import { Alert } from "react-bootstrap";
import ListComp2 from "../../common/listcomp2";
import ModalImprimir from "../../components/ui_comp/Modals/ModalPrintOut";
import NewImprimirOrcamento from "../../components/ui_comp/Modals/ModalPrintOut/ImprimirOrcamentoControl";
import ModalDelete from "../../components/ui_comp/Modals/ModalDelete";
import { Typography } from "@mui/material";
import { BsChatRightTextFill } from "react-icons/bs";
import { toast } from "react-toastify";
import ModalCancelarOrcamento from "../../components/ui_comp/Modals/ModalCancelar";

const OrcamentoVendaList = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [orcamentosvenda, setOrcamentosVenda] = useState();
    const [pages, setPages] = useState();
    const [records, setRecords] = useState();
    const [filterstate, setFilterState] = useState();
    const [permiteConsultarStatus, setPermiteConsultarStatus] = useState(false);
    const [modalExcluir, setModalExcluir] = useState(false);
    const [modalImprimir, setModalImprimir] = useState(false);
    const [modalDuplicar, setModalDuplicar] = useState(false);
    const [modalGerarPedido, setModalGerarPedido] = useState(false);
    const [modalCancelarOrcamento, setModalCancelarOrcamento] = useState(false);
    const [IndexOrcamentoSelecionado, setIndexOrcamentoSelecionado] = useState();
    const [IndexDeletarOrcamento, setIndexDeletarOrcamento] = useState();
    const [IndexImprimirOrcamento, setIndexImprimirOrcamento] = useState();
    const [IndexDuplicarOrcamento, setIndexDuplicarOrcamento] = useState();
    const [IndexGerarPedido, setIndexGerarPedido] = useState();
    const [IndexCancelarOrcamento, setIndexCancelarOrcamento] = useState();
    //const token = Cookies.get(TOKEN_KEY);
    const ordertype = useRef();
    const orderField = useRef();

    const [permiteVisualizar, setPermiteVisualizar] = useState(false);
    const [permiteEditar, setPermiteEditar] = useState(false);
    const [permiteExcluir, setPermiteExcluir] = useState(false);
    const [permiteInserir, setPermiteInserir] = useState(false);

    const [redirect, setRedirect] = useState();

    const constantes = T();
    const navigate = useNavigate();
    const columns = [
        {
            field: "Id",
            headerName: "Id",
            width: 90,
            key: true,
        },
        {
            field: "DataCadastro",
            headerName: "Data Cadastro",
            width: 90,
            type: "date",
            format: "dd/MM/yyyyy",
        },
        {
            field: "DataPrevisaoFaturamento",
            headerName: "Data Previsão",
            width: 90,
            type: "date",
            format: "dd/MM/yyyyy",
        },
        {
            field: "DataValidade",
            headerName: "Data Validade",
            width: 90,
            type: "date",
            format: "dd/MM/yyyy",
        },
        {
            field: "CpfCnpjCliente",
            headerName: "CNPJ / CPF",
            width: 150,
        },
        {
            field: "NomeCliente",
            headerName: "Nome",
            width: 150,
        },
        {
            field: "ValorTotal",
            headerName: "Valor",
            width: 150,
            digits: 2,

        },
        {
            field: "Situacao",
            headerName: "Situação",
            width: 150,
            type: "custom",
            onGetCustom: (item) => {
                const style = getStyle(item);
                return <Button style={style}>{item}</Button>;
            },
        },

    ];

    const columnsFilter = [
        {
            field: "Id",
            headerName: "Número",
            tipo: "number",
        },
        {
            field: "CodCliente",
            headerName: "Cod. Cliente",
            tipo: "number",
        },
        {
            field: "NomeCliente",
            headerName: "Cliente",
            tipo: "text",
        },
        {
            field: "ObservacaoUsuario",
            headerName: "Obs Usuário",
            tipo: "text",
        },
    ];




    useEffect(() => {
        redirect && navigate(`/${redirect}`);
    }, [redirect]);

    useLayoutEffect(() => {
        // try {
        const token_salvo = getToken();
        if (token_salvo) {
            const token = jwt_token(token_salvo);
            setPermiteConsultarStatus(!token?.VisualizarApenasOrcamentoRascunho);

            const filter = GetSavedFilter() ?? {
                situacao: "Rascunho",
                campo: null,
                operacao: null,
                valor: null,
            };
            orderField.current = filter.campoorder;
            ordertype.current = filter.tipoorder;
            getOrcamentos(filter);
      
            setPermiteVisualizar(GetPermissaoVisualizar("orcamentovenda"));
            setPermiteEditar(GetPermissaoEditar("orcamentovenda"));
            setPermiteExcluir(GetPermissaoExcluir("orcamentovenda"));
            setPermiteInserir(GetPermissaoInserir("orcamentovenda"));
        } else {
            setRedirect('logout');
        }
    }, []);

    function getIdOrcamento(index) {
        return orcamentosvenda && orcamentosvenda[index]?.Id;
    }

    useEffect(() => {
        const id = getIdOrcamento(IndexOrcamentoSelecionado);
        id && navigate(`/orcamentosvenda/${id}`);
    }, [IndexOrcamentoSelecionado]);

    useEffect(() => {
        const id = getIdOrcamento(IndexDuplicarOrcamento);
        id && navigate(`/orcamentosvenda/duplicar/${id}`);
    }, [IndexDuplicarOrcamento]);

    async function deletarOrcamento(id) {
        setModalExcluir(false);
        await orcamentovendaService
            .delete(id)
            .then((response) => {
                getOrcamentos(filterstate);
                toast.success(`Orçamento ${id} deletado com sucesso`)
            })
            .catch((error) => {
                const _error =
                    error.code === "ERR_NETWORK"
                        ? "Server OffLine"
                        : error.response && error.response.data && error.response.data.error
                            ? error.response.data.error.message
                            : error.message;
                setError(_error);
            })
            .finally(() => { });
    }

    async function cancelarOrcamento(id, cancelamento) {

        await orcamentovendaService
            .Cancelar(id, cancelamento)
            .then((response) => {
                getOrcamentos(filterstate);
                setModalCancelarOrcamento(false);
                toast.success(`Orçamento ${id} cancelado com sucesso`)
            })
            .catch((error) => {
                const _error =
                    error.code === "ERR_NETWORK"
                        ? "Server OffLine"
                        : error.response && error.response.data && error.response.data.error
                            ? error.response.data.error.message
                            : error.message;
                toast.error(_error);
            })
            .finally(() => { });
    }

    async function ImprimirOrcamento(link) {
        setModalImprimir(false);
        await orcamentovendaService
            .imprimir(link)
            .then((response) => {

            })
            .catch((error) => {
                const _error =
                    error.code === "ERR_NETWORK"
                        ? "Server OffLine"
                        : error.response && error.response.data && error.response.data.error
                            ? error.response.data.error.message
                            : error.message;
                setError(_error);
            })
            .finally(() => { });
    }

    async function GerarPedido(id) {
        setModalGerarPedido(false);
        getOrcamentos(filterstate);

    }

    async function DuplicarOrcamento(id) {
        id && navigate(`/orcamentosvenda/duplicar/${id}`);
    }

    const btns = [
        {
            href: "/orcamentosvenda/",
            text: "Editar",
            isVisible: (arg) => {
                return permiteEditar && arg.Situacao === "Rascunho";
            },
            onClick: (e) => {
                setIndexOrcamentoSelecionado(e.target.attributes.value.value);
            },
        },
        {
            href: "/orcamentosvenda/del",
            text: "Deletar",
            variant: "danger",
            isVisible: (arg) => {
                return permiteExcluir && arg.Situacao === "Rascunho";
            },
            onClick: (e) => {
                setIndexDeletarOrcamento(e.target.attributes.value.value);
                setModalExcluir(true);
            },
        },
        ,
        {
            href: "/orcamentosvenda/cancelar",
            text: "Cancelar",
            isVisible: (arg) => {
                return ((arg.Situacao === "Rascunho") || (arg.Situacao === "Bloqueado") || (arg.Situacao === "Liberado"));
            },
            onClick: (e) => {
                setIndexCancelarOrcamento(e.target.attributes.value.value);
                setModalCancelarOrcamento(true);
            },
        },
        {
            href: "/orcamentosvenda/imprimir",
            text: "Imprimir",
            isVisible: (arg) => {
                return arg.Situacao !== "Cancelado";
            },
            onClick: (e) => {
                setIndexImprimirOrcamento(e.target.attributes.value.value);
                setModalImprimir(true);
            },
        },
        {
            href: "/orcamentosvenda/duplicar",
            text: "Duplicar",
            isVisible: (arg) => {
                return arg.Situacao !== "Cancelado";
            },
            onClick: (e) => {
                setIndexDuplicarOrcamento(e.target.attributes.value.value);
            },
        },
        {
            href: "/orcamentosvenda/gerarpedido",
            text: "Gerar Pedido",
            isVisible: (arg) => {
                return arg.Situacao === "Liberado";
            },
            onClick: (e) => {
                setIndexGerarPedido(e.target.attributes.value.value);
                setModalGerarPedido(true);
            },
        },
        {
            href: "/orcamentosvenda/",
            text: "Visualizar",
            isVisible: (arg) => {
                return permiteVisualizar && arg.Situacao !== "Rascunho";
            },
            onClick: (e) => {
                setIndexOrcamentoSelecionado(e.target.attributes.value.value);
            },
        },
    ];

    function GetSavedFilter() {
        const filtro = getFiltro("ORCAMENTOS");

        return filtro;

    }

    function getOrcamentos(filter) {
        setError(null);
        setLoading(true);
        setFiltro("ORCAMENTOS", filter);
        orcamentovendaService
            .getAll(filter)
            .then((response) => {
                setOrcamentosVenda(response.data.value);
                setPages(response.data.pages);
                setRecords(response.data.registros);
                setFilterState(filter);
            })
            .catch((error) => {
                const _error =
                    error.code === "ERR_NETWORK"
                        ? "Server OffLine"
                        : error.response && error.response.data && error.response.data.error
                            ? error.response.data.error.message
                            : error.message;
                setError(_error);

                error.code === "ERR_NETWORK" && navigate("/logout");

            })
            .finally(() => {
                setLoading(false);
            });
    }

    function onSearchTitle(searchValue, searchField, serachFilter) {
        let filter = {
            situacao: null,
            campo: searchField,
            operacao: serachFilter,
            valor: searchValue,
            campoorder: orderField.current,
            tipoorder: ordertype.current,
        };

        getOrcamentos(filter);
    }

    function getSearchView() {
        return permiteVisualizar ? (
            <SearchComp
                hasBotao={permiteInserir}
                to="/orcamentosvenda/New"
                botaoDisabilitado={loading}
                title={constantes.OrcamentosDeVenda}
                campos={columnsFilter}
                onSearch={(values) => {
                    onSearchTitle(
                        values.searchValue,
                        values.searchField,
                        values.searchoperator
                    );
                }}
            />
        ) : null;
    }

    function btnSituacaoOnClick(event) {
        let filter = {
            situacao: event.target.attributes.situacao.value,
            campo: null,
            operacao: null,
            valor: null,
            campoorder: orderField.current,
            tipoorder: ordertype.current,
        };

        getOrcamentos(filter);
    }

    function getStyle(Situacao) {
        if (Situacao === "Rascunho") {
            return { background: "#FFFFB0", color: "black" };
        } else if (Situacao === "Bloqueado") {
            return { background: "#B1D8D8", color: "black" };
        } else if (Situacao === "Liberado") {
            return { background: "#fff", color: "black" };
        } else if (Situacao === "Finalizado") {
            return { background: "#98C4F3", color: "black" };
        } else if (Situacao === "Cancelado") {
            return { background: "#FFBFBF", color: "black" };
        }
        return null;
    }

    function OrderChange(field, type) {
        orderField.current = field;
        ordertype.current = type;

        let filter = {
            ...filterstate,
            campoorder: field,
            tipoorder: type,
        };
        getOrcamentos(filter);
    }

    function getFilterSituacao() {
        return (
            <Container>
                {permiteConsultarStatus && (
                    <Row className="d-flex justify-content-center gap-2">
                        <Col xs={12} md={2}>
                            <Button
                                className="w-100"
                                style={getStyle("Rascunho")}
                                variant="primary"
                                disabled={loading}
                                onClick={btnSituacaoOnClick}
                                situacao="Rascunho"
                            >
                                {constantes.Rascunho}
                            </Button>
                        </Col>
                        <Col xs={12} md={2}>
                            <Button
                                className="w-100"
                                style={getStyle("Bloqueado")}
                                disabled={loading}
                                onClick={btnSituacaoOnClick}
                                situacao="Bloqueado"
                            >
                                {constantes.Bloqueado}
                            </Button>
                        </Col>
                        <Col xs={12} md={2}>
                            <Button
                                className="w-100"
                                style={getStyle("Liberado")}
                                disabled={loading}
                                onClick={btnSituacaoOnClick}
                                situacao="Liberado"
                            >
                                {constantes.Liberado}
                            </Button>
                        </Col>
                        <Col xs={12} md={2}>
                            <Button
                                className="w-100"
                                style={getStyle("Finalizado")}
                                disabled={loading}
                                onClick={btnSituacaoOnClick}
                                situacao="Finalizado"
                            >
                                {constantes.Finalizado}
                            </Button>
                        </Col>
                        <Col xs={12} md={2}>
                            <Button
                                style={getStyle("Cancelado")}
                                className="w-100"
                                disabled={loading}
                                onClick={btnSituacaoOnClick}
                                situacao="Cancelado"
                            >
                                {constantes.Cancelado}
                            </Button>
                        </Col>
                    </Row>
                )}
            </Container>
        );
    }

    function onPageSelect(number) {
        let filter = filterstate;
        filter.page = number;

        getOrcamentos(filter);
    }

    function getListView() {
        return (
            <>
                <ListComp2
                    data={orcamentosvenda}
                    pages={pages}
                    records={records}
                    columns={columns}
                    btntype={"dropdown"}
                    msgAviso={true}
                    onPageSelect={(number) => onPageSelect(number)}
                    sortname={orderField.current}
                    sorttype={ordertype.current}
                    onSortSelect={(item, sorttype) => {
                        if (sorttype === "sort") {
                            OrderChange(null, null);
                        } else {
                            OrderChange(item, sorttype);
                        }
                    }}
                    buttons={btns}
                    striped={false}
                    noselect
                />
            </>
        );
    }

    return permiteVisualizar ? (
        <Container fluid className="my-3">
            <NewAlertError error={error} onClose={() => setError(null)} />

            <ModalDelete
                constantes={constantes}
                id={getIdOrcamento(IndexDeletarOrcamento)}
                open={modalExcluir}
                close={() => {
                    setModalExcluir(false);
                }}
                onExcluir={(id) => {
                    deletarOrcamento(id);
                }}
            >
                <Typography sx={{ fontSize: 20 }}>
                    {" "}
                    <BsChatRightTextFill
                        style={{ marginBottom: "2px", marginRight: "8px" }}
                    />
                    {constantes.MsgDesejaRealmenteExcluir} {getIdOrcamento(IndexDeletarOrcamento)}
                </Typography>

            </ModalDelete>

            <ModalImprimir
                constantes={constantes}
                open={modalImprimir}
                close={() => {
                    setModalImprimir(false);
                }}
                onImprimir={(link) => {
                    ImprimirOrcamento(link);
                }}
            >
                <NewImprimirOrcamento id={getIdOrcamento(IndexImprimirOrcamento)} />
            </ModalImprimir>
            <ModalCancelarOrcamento
                id={getIdOrcamento(IndexCancelarOrcamento)}
                constantes={constantes}
                open={modalCancelarOrcamento}
                close={() => {
                    setModalCancelarOrcamento(false);
                }}
                onCancelar={(id, item) => {
                    cancelarOrcamento(id, item);
                }}
            >

            </ModalCancelarOrcamento>
            <NewModalGerarPedido
                id={getIdOrcamento(IndexGerarPedido)}
                modal={modalGerarPedido}
                toggle={() => {
                    setModalGerarPedido(false);
                }}
                onGerarPedido={(id) => {
                    GerarPedido(id);
                }}
            />
            <NewModalDuplicarOrcamento
                id={getIdOrcamento(IndexDuplicarOrcamento)}
                modal={modalDuplicar}
                toggle={() => {
                    setModalDuplicar(false);
                }}
                onDuplicar={(id) => {
                    DuplicarOrcamento(id);
                }}
            />

            {/* <Card>
                    <Card.Header>{getSearchView()}</Card.Header>
                    <Card.Body>
                        <blockquote className="blockquote mb-0">
                            {getFilterSituacao()}
                        </blockquote>
                    </Card.Body>
                </Card>
                {getListView()} */}

            <Container fluid className="my-3">
                {getSearchView()}
            </Container>
            <Container fluid className="my-3">
                {getFilterSituacao()}
            </Container>
            <Container fluid className="my-3">
                {getListView()}
            </Container>
        </Container>
    ) : (
        <>
            <Container>
                <Alert variant="danger">
                    <Alert.Heading>{constantes.Permissao}</Alert.Heading>
                    <p>{constantes.Msgsempermissao}</p>
                </Alert>
            </Container>
        </>
    );
};

export default OrcamentoVendaList;
