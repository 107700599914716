import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";

class FormaPagamentoService extends ServiceBase {

  getAll(filter) {
    //return http.get("/clientes?$top=10");
    return http.get("/formaspagamento" + this.getFilter(filter));
  }

  get(id) {
    return http.get(`/formaspagamento/${id}`);
  }

}

export default new FormaPagamentoService();