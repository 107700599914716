import { useContext, useState } from "react";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import TotalControl from "../controls_comp/control-total";
import { Alert } from "react-bootstrap";
import { format_currency_2 } from "../../common/format";

const OrcamentoVendaTotaisCtx = () => {

    const {
        orcamentoVenda,
        somenteLeitura,
        orcamentoEdicao,
        recalculando,
        constantes,
    } = useContext(OrcamentoVendaContext);

    const TotalOriginalOrcamentoEditado = (props) => {
        return !somenteLeitura && props.TotalOriginal ? (
            <Alert variant="success">
                <Alert.Heading>
                  {constantes.MsgAvisodeEditarOrcamento} {" "}
                    {format_currency_2.format(props.TotalOriginal ?? 0)}
                </Alert.Heading>
            </Alert>
        ) : null;
    };
    

    return (
        <>
            <TotalControl totais={orcamentoVenda?.Totais} recalculando={recalculando} />
            <TotalOriginalOrcamentoEditado
                TotalOriginal={orcamentoEdicao?.Totais.ValorTotal}
                somenteLeitura={somenteLeitura}

            />
        </>
    )

}

export default OrcamentoVendaTotaisCtx;