import { Col, Form, Row } from "react-bootstrap";
import { useContext, useEffect, useRef, useState } from "react";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import NewSearchListTabelaFretes from "../searchs_comp/search-List.Tabela.Fretes";
import NewModoUsoFrete from "../controls_comp/control-modousofrete";
import CheckBoxControl from "../controls_comp/control-checkbox";
import CalculatedControl from "../controls_comp/control-calculated"; 
import { useFormikContext } from "formik";
import TipoFreteControl from "../controls_comp/control-tipofrete";
import ModoUsoFreteControl from "../controls_comp/control-modousofrete";



const OrcamentoVendaFreteCtx = () => {

    const {
        orcamentoVenda,
        somenteLeitura,
        constantes,
        handleLoad,
        handleError,
        valorFreteCalculado,
        configs,
        updateTabelaFrete,
        updateTipoFrete,
        updateModoUsoFrete,
    } = useContext(OrcamentoVendaContext);

    const {
        values,
        setFieldValue,
    } = useFormikContext();

    const [calcularTipoFrete, setCalcularTipoFrete] = useState(false);
    const [calcularModoUsoFrete, setCalcularModoUsoFrete] = useState(false);

    useEffect(() => {
        calcularModoUsoFrete && updateModoUsoFrete(values.OrcamentoVenda.ModoUsoFrete, setFieldValue, values)
        setCalcularModoUsoFrete(false);
    }, [calcularModoUsoFrete])

    useEffect(() => {
        calcularTipoFrete && updateTipoFrete(values.OrcamentoVenda.TipoFrete, setFieldValue, values)
        setCalcularTipoFrete(false);
    }, [calcularTipoFrete])

    return (
        <>
            <Row>
                <Col xs={12} md={6}>
                    <NewSearchListTabelaFretes
                        disabled={somenteLeitura}
                        nameId="OrcamentoVenda.TabelaFrete.Id"
                        nameDesc="OrcamentoVenda.TabelaFrete.Nome"
                        onLoad={handleLoad}
                        onError={handleError}
                        placeholder="Tabela Frete"
                        onItemSelected={(item) => {
                            updateTabelaFrete(item, setFieldValue, values);
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} className="mb-3">
                    <TipoFreteControl
                        disabled={somenteLeitura}
                        onChange={(e) => {
                            setCalcularTipoFrete(true);
                        }}
                    />
                </Col>
            </Row>
            {!configs?.DesabilitarDestacarFrete &&
                <Row>
                    <Col xs={12} md={6} className="mb-3">
                        {!values.Configs?.DesabilitarModoUsoFrete && (
                            <ModoUsoFreteControl
                                disabled={
                                    somenteLeitura || configs.Disabled?.ModoUsoFrete
                                }
                                onChange={(e) => {
                                    setCalcularModoUsoFrete(true);
                                }}
                            />
                        )}
                    </Col>
                </Row>
            }
            <Row>
                {!configs?.DesabilitarDestacarFrete && (
                    <>
                        <Col
                            xs={12}
                            md={3}
                        >
                            <CheckBoxControl
                                name="OrcamentoVenda.FreteFixo"
                                placeholder="Fixar Valor Frete"
                                disabled={
                                    somenteLeitura || configs.Disabled?.FreteFixo
                                }
                            />
                            <CalculatedControl
                                heightLabel={0}
                                name="OrcamentoVenda.ValorFrete"
                                valuecalculated={valorFreteCalculado}
                                placeholder="Valor Frete"
                                disabled={
                                    somenteLeitura || configs.Disabled?.ValorFrete
                                }
                            />

                        </Col>
                    </>
                )}
            </Row>
            <Row>
                {orcamentoVenda?.Cliente?.ClienteRetira &&
                    <Col
                        xs={12}
                        md={3}
                        className="d-flex align-items-center mb-0"
                    >

                        <CheckBoxControl
                            name="OrcamentoVenda.ClienteRetira"
                            placeholder={constantes?.ClienteRetira}
                            disabled={somenteLeitura}
                        />
                    </Col>
                }
            </Row>
        </>
    )

}

export default OrcamentoVendaFreteCtx;