import { useContext } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { ClienteContext } from "../../contexts/clientes-context";
import { useFormikContext } from "formik";
import SearchListClasseEntidades from "../searchs_comp/search-List.Classe.Entidade";
import AtributosCliente from "../atributos_comp/atributo-cliente-control";

export default function ClienteTabDadosAdicionais() {

  const {
    constantes,
    handleLoad,
    somenteLeitura,
    updateClasseEntidade,
    configAtributosCliente
  } = useContext(ClienteContext)

  const {
    values,
    setValues
  } = useFormikContext()

  return (
    <Box component={Paper}>
      <Box
        display="flex"
        alignItems="center"
        p={2}
        height={50}
        sx={{ backgroundColor: "rgba(59, 134, 124, 0.08)" }}
      >
        <Typography variant="h1" fontSize={22} fontWeight="sembold">
          <MdFormatListBulletedAdd
            style={{ marginBottom: "5px", marginRight: "8px" }}
          />
          {constantes.DadosAdicionais}
        </Typography>
      </Box>
      <Grid container spacing={2} p={2}>
        <Grid
          spacing={2}
          container
          item
          xs={12}
          sm={12}
          md={12}
          display="flex"
          alignItems="end"
        >
          <Grid item xs={12} sm={12} md={12}>

            <SearchListClasseEntidades
              disabled={somenteLeitura}
              name="classeEntidade"
              nameId="ClasseEntidade.Id"
              nameDesc="ClasseEntidade.Nome"
              placeholder={constantes.ClasseEntidade}
              onLoad={handleLoad}
              onItemSelected={(item) => {
                updateClasseEntidade(item, setValues)
              }} />

          </Grid>

        </Grid>

      </Grid>
      {configAtributosCliente && configAtributosCliente.length > 0 &&
        <>
          <Box
            display="flex"
            alignItems="center"
            p={2}
            height={50}
            sx={{ backgroundColor: "rgba(59, 134, 124, 0.08)" }}
          >
            <Typography variant="h1" fontSize={22} fontWeight="sembold">
              <MdFormatListBulletedAdd
                style={{ marginBottom: "5px", marginRight: "8px" }}
              />
              {constantes.Atributos}
            </Typography>
          </Box>
          <Grid container spacing={2} p={2}>
            <Grid
              spacing={2}
              container
              item
              xs={12}
              sm={12}
              md={12}
              display="flex"
              alignItems="end"
            >
              <Grid item xs={12} sm={12} md={12}>
                <AtributosCliente somenteLeitura={somenteLeitura} configAtributosCliente={configAtributosCliente} atributos={values.Atributos} />
              </Grid>

            </Grid>

          </Grid>
        </>
      }
    </Box>
  );
}
