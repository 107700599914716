import T from "../../../common/traducao";

export const Atalhos = () => {
  const constantes = T();
  return [
    {
      id: 1,
      label: constantes.AtalhoGravaItem,
    },
    {
      id: 2,
      label: constantes.AtalhoCodProduto,
    },
    {
      id: 3,
      label: constantes.AtalhoRefencia,
    },
    {
      id: 4,
      label: constantes.AtalhoQuantidade,
    },
    {
      id: 5,
      label: constantes.AtalhoCancelarItem,
    },
    {
      id: 6,
      label: constantes.FecharModalAtalhos,
    },
  ];
};
