import { GetAtributos, GetAtributosClientes, SetAtributos, SetAtributosClientes } from "../config/localstorageConfig/localstorage";
import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";

class AtributoService extends ServiceBase {

    getAll(filter) {
        return http.get("/atributos/valoresfilial");
    }

    getValoresFilial() {
        return http.get("/atributos/valoresfilial");
    }
    getValoresCliente() {
        return http.get("/atributos/valorescliente");
    }

    async setCache() {
        const atributos = GetAtributos();
        if (!atributos) {
            this.getValoresFilial()
                .then(response => { SetAtributos(response.data.value) })
                .catch(e => {
                    console.error(e);
                })
        };

    }

    async setCacheClientes() {
        const atributos = GetAtributosClientes();
        if (!atributos) {
            this.getValoresCliente()
                .then(response => {
                    console.error(response);
                    SetAtributosClientes(response.data.value)
                })
                .catch(e => {
                    console.error(e);
                })
        };

    }

    get(id) {
        return http.get(`/atributos/${id}`);
    }
}

export default new AtributoService();
