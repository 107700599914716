import { useContext } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import { ClienteContext } from "../../contexts/clientes-context";
import { MdOutlinePayments } from "react-icons/md";
import SearchListMunicipios from "../searchs_comp/search-List.Municipios";
import EditCepControl from "../controls_comp/control-edit-cep";
import EditControl from "../controls_comp/control-edit";
export default function ClientTabEnderecoCobranca() {
  const {
    constantes,
    handleLoad,
    camposDesabilitados,
    somenteLeitura
  } = useContext(ClienteContext);

  const { values, setFieldValue, setValues } = useFormikContext();

  return (
    <Box component={Paper}>
      <Box
        display="flex"
        alignItems="center"
        p={2}
        height={50}
        sx={{ backgroundColor: "rgba(59, 134, 124, 0.08)" }}
      >
        <Typography variant="h1" fontSize={22} fontWeight="sembold">
          <MdOutlinePayments
            style={{ marginBottom: "5px", marginRight: "2px" }}
          />
          {constantes.EnderecodeCobranca}
        </Typography>
      </Box>

      <Grid container spacing={2} p={2}>
        <Grid
          spacing={2}
          container
          item
          xs={12}
          sm={12}
          md={12}
          display="flex"
          alignItems="end"
        >
          <Grid ml={2} xs={12} sm={3.6} md={4.2}>
            <EditCepControl
              disabled={somenteLeitura}
              name="CepCobranca"
              placeholder={constantes?.Cep}
              type="text"
              onCepResponse={(enderecoResponse, municipioResponse) => {
                setValues({
                  ...values,
                  EnderecoCobranca: enderecoResponse.logradouro,
                  BairroCobranca: enderecoResponse.bairro,
                  MunicipioCobranca: municipioResponse
                })
              }}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={10}>
            <EditControl
              inputProps={{ maxLength: 50 }}
              disabled={somenteLeitura || camposDesabilitados.EnderecodeCobranca}
              name="EnderecoCobranca"
              placeholder={constantes?.Enderecodacobranca}
              type="text" />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5} md={5}>
          <EditControl
            inputProps={{ maxLength: 50 }}
            disabled={somenteLeitura || camposDesabilitados.EnderecodeCobranca}
            name="NumeroEnderecoCobranca"
            placeholder={constantes?.Numerodoendereco}
            type="text" />
        </Grid>

        <Grid item xs={12} sm={5} md={5}>
          <EditControl
            inputProps={{ maxLength: 50 }}
            disabled={somenteLeitura || camposDesabilitados.EnderecodeCobranca}
            name="ComplementoEnderecoCobranca"
            placeholder={constantes?.Complemento}
            type="text" />
        </Grid>
        <Grid item xs={12} sm={10} md={10}>
          <EditControl
            inputProps={{ maxLength: 50 }}
            disabled={somenteLeitura || camposDesabilitados.EnderecodeCobranca}
            name="BairroCobranca"
            placeholder={constantes?.Bairro}
            type="text" />
        </Grid>

        <Grid item xs={12} sm={10} md={10}>
          <SearchListMunicipios
            name="MunicipioCobranca"
            nameId="MunicipioCobranca.Id"
            nameDesc="MunicipioCobranca.Nome"
            placeholder={constantes.Municipio}
            disabled={somenteLeitura || camposDesabilitados.EnderecodeCobranca}
            onLoad={handleLoad}
            onItemSelected={(item) => {
              setFieldValue("MunicipioCobranca", item);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
