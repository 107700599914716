import { Accordion } from "react-bootstrap";
import UsuarioSimulacaoAcesso from "./usuario-simulacaoacesso";
import UsuarioConfiguracoes from "./usuario-configuracoes";
import { useContext } from "react";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import { GetConfiguracoes } from "../../config/localstorageConfig/localstorage";

const Usuario = () => {

    const configuracoes = GetConfiguracoes();
    const { constantes } = useContext(OrcamentoVendaContext);
    
    return (
        <>
            {configuracoes?.Usuario?.PerfilAcesso?.PermitirSimulacaoWeb &&
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                           {constantes.AcessoSimulado}
                        </Accordion.Header>
                        <Accordion.Body>
                            <UsuarioSimulacaoAcesso />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>}

            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        {constantes.Configuracoes}
                    </Accordion.Header>
                    <Accordion.Body>
                        <UsuarioConfiguracoes configuracoes={configuracoes}/>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default Usuario;