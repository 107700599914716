import { useEffect, useState } from "react";
import NewSelectControl from "./control-selectcontrol.jsx";
import { GetOperacoes, getToken } from "../../config/localstorageConfig/localstorage.js";
import jwt_token from "jwt-decode";

const OperacaoVendaControl = (props) => {

    const [operacoes, setOperacoes] = useState([]);
    const [permitirAlterarOperacaoVenda, setPermitirAlterarOperacaoVenda] = useState(false);

    useEffect(() => {
        const _operacoes = GetOperacoes();
        const newoperacoes = _operacoes && _operacoes.map((item, i) => {
            return { Id: item.Id, Descricao: item.Descricao, Valor: item.TipoOperacao, TipoOperacao: item.TipoOperacao }
        });

        setOperacoes(newoperacoes);

        const token = jwt_token(getToken());
        setPermitirAlterarOperacaoVenda(!token?.PermitirAlterarOperacaoVenda);

    }, [])

    return (
        <NewSelectControl {...props} name={"OrcamentoVenda.TipoOperacao"} placeholder={"Operação de Venda"}
            itens={operacoes} disabled={permitirAlterarOperacaoVenda || props?.disabled}
        />
    )
}

export default OperacaoVendaControl;