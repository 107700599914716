import { constantes_default } from "../common/traducao";
import { SetTextosIdioma } from "../config/localstorageConfig/localstorage";
import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";


class IdiomasVendaService extends ServiceBase {

    getAll() {
        return http.post("/Idiomas");
    }

    getTraducoes(listachaves) {
        const idiomaenvio = listachaves; // this.getIdiomaEnvio(idioma, listachaves);
        return http.post("/Idiomas", idiomaenvio);

    }

    async setCache(idioma) {
        const _idioma = JSON.parse(JSON.stringify(constantes_default));
        _idioma.$IdiomaDestino = idioma;

        http.post("/Idiomas", _idioma) //this.getIdiomaEnvio(idioma, initial_textos_idiomas))
            .then(response => {
                SetTextosIdioma(idioma, response.data);
            })
            .catch(e => {
                console.error(e);
            })
    }
}

export default new IdiomasVendaService();