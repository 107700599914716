import SelectControl from "./control-selectcontrol.jsx";
import T from '../../common/traducao.js'

const CondicoesVendaControl = (props) => {

    const constantes = T()

    const condicoes =
        [
            {
                Id: '',
                Valor: '',
                Descricao: ''
            },
            {
                Id: 'Consumo',
                Valor: 'Consumo',
                Descricao: constantes.Consumo
            },
            {
                Id: 'Revenda',
                Valor: 'Revenda',
                Descricao: constantes.Revenda
            },
            {
                Id: 'Industrialização',
                Valor: "Industrialização",
                Descricao: constantes.Industrializacao
            },
            {
                Id: 'Outros',
                Valor: "Outros",
                Descricao: constantes.Outros
            }
        ]

    return (
        <SelectControl  {...props} name={props.name} placeholder={constantes.DestinoOperacao}
            itens={condicoes}
        />
    )
}

export default CondicoesVendaControl;