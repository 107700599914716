import {
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Navbar,
  Row,
  Spinner,
} from "react-bootstrap";
import T from "../../../common/traducao";

const getBotoes = (props) => {
  const constantes = T()
  return (
    <div>
      <Button variant="success" onClick={() => props.onReturn("ok")}>
        {" "}
        {constantes.ok}
      </Button>
    </div>
  );
};

export const LoadModal = (props) => {
  const isLoading = !props.onReturn;
  return (
    <Modal show={props.show} centered onHide={props.onHide} backdrop="static" keyboard={false}
    >
      <ModalHeader toogle={props.onHide}></ModalHeader>
      <ModalBody>
        <Container className="d-flex flex-column align-items-center">
          <div className="mb-3">{props.text ? props.text : "Carregando..."}</div>
          {isLoading && (
            <div className="d-flex justify-content-center gap-1">
              <Spinner animation="grow" variant="primary" />
              <Spinner animation="grow" variant="secondary" />
              <Spinner animation="grow" variant="success" />
              <Spinner animation="grow" variant="danger" />
              <Spinner animation="grow" variant="warning" />
              <Spinner animation="grow" variant="info" />
              <Spinner animation="grow" variant="light" />
              <Spinner animation="grow" variant="dark" />
            </div>
          )}
          {!isLoading && getBotoes()}
        </Container>
      </ModalBody>
    </Modal>
  );
};


export const LoadingProgress = ({ labelLoading }) => {
  return (
    <>
      <Spinner
        animation="border"
        style={{ color: "#009180" }}
        role="status"
      />
      {labelLoading && (
        <span style={{ fontSize: 18, color: "#009180", padding: "5px 0" }}>
          {labelLoading}...
        </span>
      )}

    </>

  )
}

export const MenuLoadingProgress = ({ labelLoadingProgress }) => {

  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Row>
        <Navbar.Brand
          href={"/home"}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "90vh",
          }}
        >
          <Spinner
            animation="border"
            style={{ color: "#009180" }}
            role="status"
          />
          <span style={{ fontSize: 18, color: "#009180", padding: "5px 0" }}>
            {labelLoadingProgress}
          </span>
        </Navbar.Brand>
      </Row>
    </Container>
  )
}