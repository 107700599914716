import { Form, Formik } from "formik";
import { Component } from "react";
import ListComp from "../../common/listcomp";
import SearchComp from "../../common/searchcomp";
import clienteService from "../../services/service.cliente"

const initialValues = {
    Id: 0
}

class ExampleList extends Component {

    columns = [
        {
            field: 'Id',
            headerName: 'Codigo',
            width: 90,
            key: true
        },
        {
            field: 'NomeFantasia',
            headerName: 'Nome',
            width: 150,
        },
        {
            field: 'CPFCNPJ',
            headerName: 'CPF/CNPJ',
            width: 150,
        },
        {
            field: 'UF',
            headerName: 'Estado',
            width: 150,
        },
        {
            field: 'Pais',
            headerName: 'Pais',
            width: 150,
        },
    ]

    columnsFilter = [
        {
            field: 'Id',
            headerName: 'Codigo',
            tipo: 'number'
        },
        {
            field: 'NomeFantasia',
            headerName: 'Nome',
            tipo: 'text'
        },
        {
            field: 'CPFCNPJ',
            headerName: 'CPF/CNPJ',
            tipo: 'text'
        },
        {
            field: 'UF',
            headerName: 'Municipio',
            tipo: 'text'
        },
    ]

    constructor(props) {
        super(props)

        this.state = {
            data: null, //data_mock_clientes.value,
            filter: null
        }

        this.onSearchTitle = this.onSearchTitle.bind(this);
    }

    placeholder = "Listagem";

    onPageSelect(number) {

        const { filter } = this.state;

        let _filter = filter;
        _filter.page = number;

        this.onFilterSearchTitle(filter);
    }

    onFilterSearchTitle(filter) {

        clienteService.getAll(filter)
            .then(response => {
                this.setState(
                    {
                        data: response.data.value,
                        pages: response.data.pages,
                        filter: filter
                    });
            })
            .catch(e => {
                console.error(e);
            });
    }

    onSearchTitle(searchValue, searchField, serachFilter) {

        let filter = {
            situacao: null,
            campo: searchField,
            operacao: serachFilter,
            valor: searchValue,
            page: 1
        };
        this.onFilterSearchTitle(filter);
    }

    render() {
        return (
            <Formik
                initialValues={initialValues}
            >
                {() =>
                    <>
                        <SearchComp
                            campos={this.columnsFilter}
                            title={this.placeholder}
                            onSearch={(values) => {
                                this.onSearchTitle(values.searchValue, values.searchField, values.searchoperator);
                            }}
                        />
                        <Form>
                            <ListComp
                                data={this.state.data}
                                pages={this.state.pages}
                                columns={this.columns}
                                selecionar
                                onItemSelected={(props) => this.onItemSelected(props)}
                                onPageSelect={(number) => this.onPageSelect(number)}
                            />
                            
                        </Form>
                    </>
                }
            </Formik>
        )
    }
}

export default ExampleList;