import React, { forwardRef } from 'react';
import { MapControl } from '@vis.gl/react-google-maps';
import { PlaceAutocompleteClassic } from './map-autocomplete-classic';

export const CustomMapControl = forwardRef((props, ref) => {

  const {
    controlPosition,
    onPlaceSelect,
    handleSelectedMarker,
    defaultValue,
    markers,
    places
  } = props;

  return (
    <MapControl position={controlPosition}>
      <div className="autocomplete-control">
        <PlaceAutocompleteClassic
          places={places}
          markers={markers}
          defaultValue={defaultValue}
          onMarkerSelected={() => {
            handleSelectedMarker && handleSelectedMarker();
          }} 
          onPlaceSelect={onPlaceSelect}>
        </PlaceAutocompleteClassic>
      </div>
    </MapControl>
  );
});
