import React, { useState } from "react";
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

const PaginationControl = ({ pages, onPageSelect, records }) => {
  const [pageActive, setPageActive] = useState(1);

  const handleChangePage = (event, value) => {
    setPageActive(value);
    onPageSelect(value);
  }

  return (
    <Box marginBottom={2} sx={{ width: '100%' }}>
      <Stack
        justifyContent="center"
        alignItems="center"
      >
        {pages &&
          <Pagination
            variant="outlined"
            shape="rounded"
            siblingCount={2}
            count={pages}
            page={pageActive}
            onChange={handleChangePage}
            renderItem={(item) => (
              <PaginationItem
                style={
                  {
                    color: "#009180",
                    fontSize: 15
                  }
                }
                {...item}
              />
            )}
          />}
        {pages && records && <Typography style={
          {
            color: "#009180",
            fontSize: 12
          }
        }  >Total de registros: {records} </Typography>}
      </Stack>
    </Box>
  );
};

export default PaginationControl;