import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { GetIdiomaSelecionado, SetIdiomaSelecionado, login } from "../../config/localstorageConfig/localstorage";
import AtributoService from "../../services/service.atributo";
import UsuarioService from "../../services/service.usuario";
import OperacaoVendaService from "../../services/service.operacaovenda";
import TipoFreteService from "../../services/service.tabelafrete";
import ReponsavelService from "../../services/service.responsavel";
import IdiomaService from "../../services/service.idioma";
import jwt_token from "jwt-decode";
import MenuLoading from "../menu_comp/MenuLoading";
import { Container, Navbar, Row, Spinner } from "react-bootstrap";
import T from "../../common/traducao";
import { LoadingProgress } from "../ui_comp/Loading";

export default function AccessExternal() {
  let { token, redirecty } = useParams();
  
  const navigate = useNavigate();
  const constantes = T();

  const max_Reload = 1;
  let reloadCount = localStorage.getItem('reloadCount');

  if (reloadCount === null) {
    reloadCount = 0;
  } else {
    reloadCount = parseInt(reloadCount);
  }

  async function verificarToken() {
    const jwt_parsed = jwt_token(token);
    const loginUsusario = jwt_parsed.LoginUsuario;

    try {
      if (token) {
        login(token, loginUsusario);
        await AtributoService.setCache();
        await AtributoService.setCacheClientes();
        await OperacaoVendaService.setCache();
        await TipoFreteService.setCache();
        await ReponsavelService.setCache();
        await IdiomaService.setCache("pt-br");
        await IdiomaService.setCache("es-es");
        await UsuarioService.setCachePermissoes();
        await UsuarioService.setCacheConfiguracoes();
        await ConfigService.setCacheServerVersion();
        localStorage.setItem("@hideMenu", JSON.stringify(true));
        const idiomaselecionado = GetIdiomaSelecionado();
        SetIdiomaSelecionado(idiomaselecionado ?? "pt-br");

      } else {
        console.log("algo deu errado em acesso por links externos");
      }
    } catch (error) {
      console.log(error);
    }
  }

 
  useEffect(() => {
    if ( redirecty) {
      verificarToken()
        .then(() => {
          if (reloadCount < max_Reload) {
            reloadCount++;
            localStorage.setItem('reloadCount', reloadCount);
            window.location.reload();
          } else {
            setTimeout(() => {
              redirecty &&
              navigate(`/${redirecty}`);
            },1500)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [redirecty]);

  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Row>
        <Navbar.Brand
          href={"/home"}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "90vh",
          }}
        >
          <LoadingProgress labelLoading={constantes.Carregando}/>
        </Navbar.Brand>
      </Row>
    </Container>
  );
}