import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { AssistenciasContext } from "../../contexts/assistencias-context";
import { Add, Delete, RemoveRedEye } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import "react-datepicker/dist/react-datepicker.css";
import { IoMdDocument } from "react-icons/io";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { toast } from "react-toastify";

function AssistenciaComentario() {
  const {
    responsaveis,
    comentarioSelecionado,
    configPermissoesAssistencia,
    constantes,
    setComentarioSelecionado,
    formatFilesSize,
    setModalContentPreviewAnexosComentarios,
    abrirmodalAnexoComentario
  } = useContext(AssistenciasContext);

  const data_atual = new Date();
  const dataformatada = data_atual.toLocaleDateString("pt-BR", {
    timeZone: "UTC",
  });

  const pdfjs = require("pdfjs-dist");
  const handleDeleteAnexos = (index) => {
    const newFiles = [...comentarioSelecionado?.AnexosComentarios];
    newFiles.splice(index, 1);
    handleInputChange("AnexosComentarios", newFiles);
  };

  const handleViewAnexos = async (index) => {
    const file = comentarioSelecionado?.AnexosComentarios[index];

    if (file.type.startsWith("image/")) {
      setModalContentPreviewAnexosComentarios(
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img
            src={file.preview}
            alt={file.name}
            style={{ width: "100%", maxHeight: "100%" }}
          />
        </Box>
      );
    } else if (file.type === "application/xml") {
      try {
        const xmlContent = await file.text();
        setModalContentPreviewAnexosComentarios(
          <div style={{ overflow: "auto", maxHeight: "80vh" }}>
            <pre>{xmlContent}</pre>
          </div>
        );
      } catch (error) {
        console.error("Error reading XML file:", error);
        setModalContentPreviewAnexosComentarios(
          <div>Error reading XML file.</div>
        );
      }
    } else {
      setModalContentPreviewAnexosComentarios(
        <Worker
          workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
        >
          <Viewer fileUrl={URL.createObjectURL(file)} />
        </Worker>
      );
    }

    abrirmodalAnexoComentario();
  };

  const onDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: file.type.startsWith("image/")
          ? URL.createObjectURL(file)
          : null,
      })
    );

    const dataAnexosComentarios = Array.isArray(
      comentarioSelecionado?.AnexosComentarios
    )
      ? comentarioSelecionado?.AnexosComentarios
      : [];

    handleInputChange("AnexosComentarios", [
      ...dataAnexosComentarios,
      ...newFiles,
    ]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleInputChange = (fieldName, value) => {
    setComentarioSelecionado((prev) => ({ ...prev, [fieldName]: value }));
  };

  useEffect(() => {
    const responsavelSuporte = responsaveis.find((item) => item.Id === configPermissoesAssistencia?.ResponsavelSuporteId);
    responsavelSuporte &&
      setComentarioSelecionado(
        {
          Responsavel : responsavelSuporte
        }
      )
  }, [])

  return (
    <Grid container display={"flex"} justifyContent="center" spacing={3} p={0}>
      <Grid
        item
        flexDirection={"column"}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        display="flex"
      >
        <InputLabel sx={{ fontSize: 16, fontWeight: "bold", color: "#666464" }}>
          {constantes.DataComentario}: {dataformatada}{" "}
        </InputLabel>
      </Grid>

      {/* <Grid
        item
        flexDirection={"column"}
        xs={12}
        sm={12}
        md={12}
        lg={5}
        display="flex"
      >
        <InputLabel>{constantes.PrazoResposta}</InputLabel>
        <OutlinedInput
          sx={{
            padding: 0,
            margin: 0,
            height: "2.35rem",
            marginTop: "0.2rem",
          }}
          type="date"
          value={comentarioSelecionado?.PrazoResposta}
          onChange={(e) => handleValidacaoPrazoResposta(e)}
        />
      </Grid> */}
      <Grid
        item
        flexDirection={"column"}
        xs={12}
        sm={12}
        md={12}
        lg={10}
        display="flex"
      >
        <InputLabel>{constantes.Comentario}</InputLabel>
        <TextareaAutosize
          value={comentarioSelecionado?.Texto}
          onChange={(e) =>
            setComentarioSelecionado((prev) => ({
              ...prev,
              Texto: e.target.value,
            }))
          }
          fullWidth
          required
          minRows={4}
          style={{ borderRadius: 5, padding: 10 }}
          size="small"
          placeholder={"Comentário"}
        />
      </Grid>

      <Grid
        item
        flexDirection={"column"}
        xs={12}
        sm={12}
        md={12}
        lg={10}
        display="flex"
      >
        <InputLabel>{constantes.Anexos}</InputLabel>
        <Box
          {...getRootProps()}
          sx={{
            marginTop: "16px",
            padding: "16px",
            border: "2px dashed #ccc",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} multiple />
          <Typography color="textSecondary">
            Arraste e solte um novo arquivo ou clique para selecionar um
            arquivo.
          </Typography>
        </Box>

        <Box
          sx={{
            margin: "15px 0 ",
            maxHeight: "100px",
            overflowX: "auto",
          }}
        >
          <List>
            {comentarioSelecionado?.AnexosComentarios?.map((file, index) => (
              <ListItem key={index}>
                {file.type.startsWith("image/") ? (
                  <a href={file.preview} download={file.name}>
                    <img
                      src={file.preview}
                      alt={file.name}
                      style={{
                        marginRight: "16px",
                        maxHeight: "50px",
                        cursor: "pointer",
                      }}
                    />
                  </a>
                ) : (
                  <a
                    href={URL.createObjectURL(file)}
                    download={file.name}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                    }}
                  >
                    <IoMdDocument
                      style={{
                        marginRight: "16px",
                        cursor: "pointer",
                      }}
                    />
                  </a>
                )}
                <ListItemText
                  primary={file.name}
                  secondary={formatFilesSize(file.size)}
                />
                <ListItemSecondaryAction sx={{ display: "flex", gap: 1 }}>
                  <IconButton
                    edge="end"
                    onClick={() => handleViewAnexos(index)}
                  >
                    <RemoveRedEye />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteAnexos(index)}>
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>
    </Grid>
  );
}

export default AssistenciaComentario;
