import { useFormikContext } from "formik";
import { useContext } from "react";
import { Container } from "react-bootstrap";
import { ItemOrcamentoVendaContext } from "../../contexts/itemorcamentovenda-context";
import ModalComposicaoProdutoControl from "../ui_comp/Modals/ModalComposicaoProduto";
import ModalAtributosProdutoControl from "../ui_comp/Modals/ModalAtributosProduto";
import ErrosModal from "../../errors/ModalErrors";

const ItemOrcamentoVendaModalsCtx = () => {

    const {
        constantes,
        somenteLeitura,
        itensFiname,
        modalComposicao,
        setModalComposicao,
        setAtributos,
        atributos,
        modalAtributos,
        setModalAtributos,
        setTotalItem,
        precoUnitario,
        onToogleErro,
        loadErro,
        erros
    } = useContext(ItemOrcamentoVendaContext);

    const {
        values,
        setFieldValue,
        resetForm,
        handleChange
    } = useFormikContext();

    return (
        <Container>
            <ModalComposicaoProdutoControl
                id={values.ItemOrcamentoVenda?.CodProduto}
                modal={modalComposicao}
                toggle={() => { setModalComposicao(false) }}
            />
            <ModalAtributosProdutoControl
                id={values.ItemOrcamentoVenda?.CodProduto}
                atributos={atributos}
                somenteLeitura={somenteLeitura}
                modal={modalAtributos}
                quantidadeCalculada={values.ItemOrcamentoVenda?.Quantidade}
                setQuantidadeCalculada={(atributos, qtd) => {
                    setFieldValue("ItemOrcamentoVenda.Quantidade", Number(qtd));
                    setFieldValue("ItemOrcamentoVenda.Atributos", atributos);
                    setTotalItem(Number((Number(qtd) * precoUnitario).toFixed(2)));
                    setAtributos(atributos);
                    setModalAtributos(false);
                }}
                toggle={() => { setModalAtributos(false) }}
            />
            <ErrosModal onHide={onToogleErro} show={loadErro} erro={erros} />
        </Container>
    )


}

export default ItemOrcamentoVendaModalsCtx;

