import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";

class MotivoSituacaoService extends ServiceBase {

  getAll() {
    //return http.get("/clientes?$top=10");
    return http.get("/motivossituacao");
  }

  get(id) {
    return http.get(`/motivossituacao/${id}`);
  }
}

export default new MotivoSituacaoService();