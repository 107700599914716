import {
  Button,
  Container,
  Dropdown,
  Image,
  Row,
  Stack,
  Table,
} from "react-bootstrap";
import NewPaginationControl from "../components/controls_comp/control-Pagination";
import { useRef, useState, useContext } from "react";
import { format_percent_2, format_percent_3, format_percent_4 } from "./format";
import {
  faSort,
  faSortAsc,
  faSortDesc,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { GoAlertFill } from 'react-icons/go'
import T from "./traducao";
import "../styles/botoesBuscar.css"
import { Box, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";


const ListComp2 = (props) => {
  const constantes = T();
  const {
    title,
    selecionar,
    pages,
    records,
    data,
    striped,
    buttons,
    columns,
    msgAviso,
    onItemSelected,
    onSortSelect,
    onGetStyle,
    onPageSelect,
    onGetBtnGroup,
    noselect,
    sortname,
    sorttype,
    btntype,
    semcontainer,
    onRowClick,
    ativarSelectClientes,
    selectedRowIndex,
    onExpanded,
    activeExpand
  } = props;

  const [indexActive, setIndexActive] = useState();
  const currentsortname = useRef(sortname);
  const currentsorttype = useRef(sorttype);


  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const ItemChecked = (props) => {
   // const [expanded, setExpanded] = useState();
   const {itemExpand, expanded, setExpanded} = props;
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: 0,
          padding: 0.2,
          background: "transparent",
          width: "100%",
          height: "100%",
        }}
      >
        {itemExpand?.length !== 0 && (
          <Button
            style={{
              display: "flex",
              width: "100%",
              background: "transparent",
              padding: 1,
              color: itemExpand?.length === 0 ? "#797979" : "#000000",
              border: "none",
            }}
            onClick={() => {
              setExpanded && setExpanded();             
            }}
            disabled={itemExpand?.length === 0}
          >
            <>
              {itemExpand?.length !== 0 && expanded ? (
                <ExpandLessIcon color="black" />
              ) : (
                <ExpandMoreIcon color="black" />
              )}
            </>
          </Button>
        )}
      </Box>
    )
  }

  function getSwitchType(item, obj, itemExpand, expanded, setExpanded) {
    switch (item.type) {
      case "checkbox":
        return <input readonly type={"checkbox"} checked={obj}></input>;
      case "custom":
        return item.onGetCustom ? item.onGetCustom(obj) : obj;
      case "date":
        const data = new Date(obj);
        const dataformatada = data.toLocaleDateString("pt-BR", {
          timeZone: "UTC",
        });
        return dataformatada === "30/12/1899" ? null : dataformatada;
      case "image":
        return <Image width={100} src={obj ? obj : null} fluid />;
      case "expand":
        return (
          itemExpand?.length === 0
            ? null
            :
            (
              <ItemChecked itemExpand={itemExpand} expanded={expanded} setExpanded={setExpanded}/>
            )
        )

      default:
        return obj;
    }
  }

  function getMap(itemdata) {
    return columns.map((item) => {
      return {
        indexCampo: 0,
        indexDado: Object.keys(itemdata).findIndex(
          (element) => element === item.field
        ),
        key: item.key ?? null,
        type: item.type ?? null,
        digits: item.digits ?? null,
        align: item.align ?? null,
        onGetStyle: item.onGetStyle ?? null,
        onGetCustom: item.onGetCustom ?? null,
        indexFieldExpand: Object.keys(itemdata).findIndex(
          (element) => element === item.fieldExpand
        ),
      };
    });
  }

  function GetSortType() {
    switch (currentsorttype.current) {
      case "sort":
        return "asc";
      case "asc":
        return "desc";
      case "desc":
        return "sort";
      default:
        return "sort";
    }
  }

  const GetHeader = (args) => {
    const { item } = args;

    const icon =
      currentsorttype.current === "sort"
        ? faSort
        : currentsorttype.current === "asc"
          ? faSortAsc
          : faSortDesc;
    const retorno = (
      <Stack direction="horizontal" gap={2}>
        <small>{item.headerName}</small>
        <Button
          variant="outline-secondary"

          style={{

            borderColor: "white",
          }}
          onClick={(e) => {
            const sorttype = GetSortType();
            onSortSelect && onSortSelect(item.field, sorttype);
            currentsortname.current = item.field;
            currentsorttype.current = sorttype;
          }}
        >
          <FontAwesomeIcon
            icon={currentsortname.current === item.field ? icon : faSort}
            style={{
              width: 10,
            }}
          ></FontAwesomeIcon>
        </Button>
      </Stack>
    );

    return onSortSelect ? retorno : item.headerName;
  };

  const SearchControl = () => { };

  function Headers() {
    return (
      data &&
      data.length > 0 &&
      columns &&
      columns.map((item, i) => {
        return (
          <th align={item.align ?? "left"} key={i}>
            <GetHeader item={item} />
          </th>
        );
      })
    );
  }

  function getStyle(itemdata, i) {
    return indexActive === i
      ? noselect
        ? null
        : { background: "#017ebc", color: "white" }
      : onGetStyle
        ? onGetStyle(itemdata)
        : null;
  }

  function ItensBodyTable(args) {
    const { itemdata, indexlinha, expanded, setExpanded } = args;
    let indexlist = getMap(itemdata);

    return indexlist.map((item, i) => {
      let obj = Object.values(itemdata)[item.indexDado];
      const itemExpand = Object.values(itemdata)[item.indexFieldExpand];
      if (item.digits && item.digits > 0) {
        switch (item.digits) {
          case 2:
            obj = format_percent_2.format(obj);
            break;
          case 3:
            obj = format_percent_3.format(obj);
            break;
          case 4:
            obj = format_percent_4.format(obj);
            break;
          default:
            obj = format_percent_2.format(obj);
            break;
        }
      }

      const style = item.onGetStyle
        ? item.onGetStyle(obj)
        : getStyle(itemdata, indexlinha);

      return item.key ? (
        <th style={style} scope="row" align={item.align ?? "left"} key={i}>
          <td style={{ display: "flex", gap: 6, width: 48 }}>
            {getSwitchType(item, obj, itemExpand, expanded, setExpanded)}
            {itemdata?.PrecoTabela === 0 ||
              itemdata?.PrecoVenda === 0 &&
              itemdata?.SituacaoProduto === "Bloqueado" ? (
              <GoAlertFill size={18} style={{ marginTop: "3.5px" }} />
            ) : (
              ""
            )}
          </td>
        </th>
      ) : (
        <>
          <td style={style} align={item.align ?? "left"} key={i}>
            {getSwitchType(item, obj, itemExpand, expanded, setExpanded)}
          </td>
        </>
      );
    });
  }

  function ButtonBodyTable(args) {
    const { itemdata, indexlinha } = args;

    let retorno = null;

    if (btntype && btntype === "dropdown") {
      const retorno_botoes = buttons?.map((btn, index) => {
        const isVisible = btn.isVisible ? btn.isVisible(itemdata) : true;
        const _retorno = isVisible && (
          <DropdownItem
            key={index}
            value={indexlinha}
            variant={btn.variant}
            onClick={(e) => {
              btn.onClick(e);
            }}
          >
            {btn.text}
          </DropdownItem>
        );
        return _retorno;
      });

      retorno = retorno_botoes && retorno_botoes.length > 0 && (
        <Dropdown style={{ position: "inherit" }}>
          <DropdownToggle variant="light" id="dropdown-basic">
            {constantes.Acoes}
          </DropdownToggle>
          <DropdownMenu>{retorno_botoes}</DropdownMenu>
        </Dropdown>
      );
    } else {
      let retorno_botoes =
        buttons &&
        buttons.map((btn, index) => {
          const isVisible = btn.isVisible ? btn.isVisible(itemdata) : true;
          const icon = btn.Icon ?? null;
          const btncalc = btn.getBtn ? (
            btn.getBtn(itemdata, indexlinha)
          ) : (
            <Button
              key={index}
              variant={btn.variant}
              onClick={btn.onClick}
              value={indexlinha}
            >
              {btn.text}
            </Button>
          );
          const _retorno = isVisible ? (
            <>
              {isVisible && " "}
              {isVisible && btncalc}
            </>
          ) : null;
          if (_retorno) {
            if (!retorno) {
              retorno = [];
            }
            retorno = retorno.concat(_retorno);
          }
          return _retorno;
        });
    }

    return retorno ? (
      <Stack direction="horizontal" gap={1}>
        {" "}
        {retorno}{" "}
      </Stack>
    ) : null;
  }
  const getExpanded = (itemdata) => {

    const _onExpanded = onExpanded && onExpanded(itemdata);
    return _onExpanded && (
      <tr key={itemdata.$id} >
        <td style={{ width: "100%", padding: 0, }} colSpan={columns.length}>
          {_onExpanded}
        </td>
      </tr>
    )
  }




  const BodyTable = () => {

    return (
      data?.length !== 0 ? (
        <Table
          responsive
          hover
          striped={striped ?? true}
          style={{
            boxShadow: "0 5px 15px rgba(0, 0, 0, 0.2)",
            border: "1px solid #e0e0e0",
            borderRadius: "10px",
          }}
          size="sm"
        >
          {(!onExpanded) &&
            <thead>
              <tr>
                <Headers />
              </tr>
            </thead>}
          <tbody>
            {


              data?.map((itemdata, i) => {

                const [expanded, setExpanded] = useState(false);

                return (
                  <>
                    {itemdata.group && (
                      <tr
                        align={itemdata.align ?? "left"}
                        key={i}

                        style={{
                          height: "60px",
                          padding: "5px",
                          backgroundColor: "#f5f5f5",
                        }}
                      >
                        <td colSpan={columns.length} align={"left"} key={i}>
                          {itemdata.group}
                        </td>
                        <td align={"left"} key={i}>
                          {onGetBtnGroup && onGetBtnGroup(itemdata, i)}
                        </td>
                      </tr>
                    )}
                    <tr
                      key={i}
                      onClick={() => {
                        setIndexActive(i);
                        onRowClick && onRowClick(i);
                        setCheckboxChecked(true)
                      }}
                      onDoubleClick={() => {
                        onItemSelected && onItemSelected(data[i]);
                      }}
                      style={ativarSelectClientes ? {
                        height: "60px",
                        padding: "5px",
                        fontSize: indexActive === i ? "15px" : "15px",
                        color: indexActive === i ? "#009682" : "black",
                        fontWeight: indexActive === i ? "bold" : "normal",
                        transition: "color 0.6s ease",
                        cursor: "pointer",

                      } : {
                        height: "60px",
                        padding: "5px",
                        transition: "color 0.6s ease",
                        cursor: "pointer"
                      }
                      }
                    >

                      <ItensBodyTable itemdata={itemdata} indexlinha={i} expanded={expanded} setExpanded={() => setExpanded(!expanded)} />
                      <td colSpan={columns.length} align={"left"}>
                        <ButtonBodyTable itemdata={itemdata} indexlinha={i} />
                      </td>
                    </tr>
                    {expanded && getExpanded(itemdata)}
                  </>
                );
              })
            }
          </tbody>
        </Table>
      ) : (
        <>
          {msgAviso && (
            <Box sx={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center", margin: "10rem 0" }}>
              <Typography sx={{
                fontSize: {
                  xs: "0.8rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1rem",
                  xl: "1.2rem",
                },
                color: "#919191",
                fontWeight: "bold",
                textAlign: "center",
              }}>{constantes.MsgFiltroNaoEncontradoOrcamentos}</Typography>
            </Box>
          )}



        </>

      )

    );
  };

  function Children() {
    return (
      <>
        <h4>{title}</h4>
        <SearchControl />
        <BodyTable />
        {data?.length !== 0 && (
          <NewPaginationControl
            pages={pages}
            records={records}
            onPageSelect={(number) => {
              onPageSelect && onPageSelect(number);
            }}
          />
        )}

        {selecionar && (!activeExpand) && (
          <div>
            <Row>
              <Button
                bsPrefix="ButtonStyle selecionar"
                type="submit"

                onClick={() => {
                  onItemSelected && onItemSelected(data[indexActive]);
                }}
                disabled={indexActive === undefined}
              >
                {constantes.Selecionar}
              </Button>
            </Row>
          </div>
        )}
      </>
    );
  }

  return semcontainer ? Children() : <Container>{Children()}</Container>;
};

export default ListComp2;
