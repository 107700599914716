import { React, useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik, useField, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Accordion,
  Stack,
  Spinner,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "react-bootstrap";
import EditControl from "../controls_comp/control-edit.jsx";
import NewSelectControl from "../controls_comp/control-selectcontrol.jsx";
import ErrosModal from "../../errors/ModalErrors/index.jsx";
import NewGruposProdutosVendasControl from "../controls_comp/control-gruposprodutosvenda.jsx";
import OrcamentoVendaService from "../../services/service.orcamentovenda.js";
import T from "../../common/traducao.js";
import ModalComposicaoProdutoControl from "../ui_comp/Modals/ModalComposicaoProduto.jsx";
import ModalAtributosProdutoControl from "../ui_comp/Modals/ModalAtributosProduto.jsx";
import CalculatedControl from "../controls_comp/control-calculated.jsx";
import {
  format_currency_2,
  format_currency_4,
  format_percent_2,
} from "../../common/format.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faBox } from "@fortawesome/free-solid-svg-icons";
import NewSearchListReferenciaProduto from "../searchs_comp/search-List.Referencia.Produto.jsx";
import NewCheckBoxControl from "../controls_comp/control-checkbox.jsx";
import ItemOrcamentoVendaCondicoesEspeciaisCtx from "../item_orcamento_venda/itemorcamentovenda-condicoesespeciais.jsx";
import "../../styles/botoesBuscar.css";

import CampoMemo from "../campos_comp/campo-memo.jsx";
import CamposSearchListProduto from "../campos_comp/campo-searchlist-produto.jsx";
import NewComposicaoProdutoControl from "../composicao_comp/composicao-ProdutoControl.jsx";

import { useAtalhos } from "../../hooks/useAtalhos/index.js";
import ModalAtalhos from "../ui_comp/Modals/ModalAtalhos.jsx";

const initialValues = {
  ItemOrcamentoVenda: {
    Item: 1,
    Id: 0,
    Quantidade: null,
    CodProduto: "",
    NomeProduto: "",
    Referencia: "",
    UnidadeMedida: "PC",
    PercentualDesconto: 0,
    PercentualAcrescimo: 0,
    DescontoEspecial: 0,
    PrecoTabela: 0,
    PrecoTabelaComDesconto: 0,
    PrecoUnitario: 0,
    TotalItem: 0,
    Projeto: null,
    Observacoes: null,
    TabelaPrecoId: null,
  },
};

const ItemOrcamentoVendaNew = (props) => {
  const {
    ItensFiname,
    TabelaPrecoId,
    selectedItem,
    indexselectedItem,
    cfop,
    maioritem,
    orcamentovenda,
    configs,
    somenteLeitura,
    descontosTabela,
    toggle
  } = props;

  const itensFiname = ItensFiname?.map((item, index) => {
    return { Id: index, Valor: item.NomeProduto };
  });

  const _initialvalues = { ItemOrcamentoVenda: selectedItem };

  initialValues.ItemOrcamentoVenda.TabelaPrecoId = TabelaPrecoId;
  initialValues.ItemOrcamentoVenda.Cfop = cfop;
  initialValues.ItemOrcamentoVenda.Item = maioritem ? maioritem + 1 : 1;
  const formikRef = useRef(null)
  const [modalComposicao, setModalComposicao] = useState(false);
  const [modalAtributos, setModalAtributos] = useState(false);
  const [modalAtalhos, setModalAtalhos] = useState(false);

  const [multiplos, setMultiplos] = useState(
    _initialvalues?.ItemOrcamentoVenda?.QtdeMultiplaVenda
  );

  const [devePreencherAtributos, setDevePreencherAtributos] = useState(
    configs?.UsarAtributosVenda &&
    _initialvalues?.ItemOrcamentoVenda?.FormulaParaQuantidadeId > 0
  );
  const [deveMostrarSobMedida, setDeveMostrarSobMedida] = useState(
    _initialvalues?.ItemOrcamentoVenda?.PermitirSobMedida
  );
  const [permiteFracionario, setPermiteFracionario] = useState(
    _initialvalues?.ItemOrcamentoVenda?.TipoUnidadeMedida == "Fracionada"
  );

  const refProduto = useRef(null);
  const refReferencia = useRef(null);
  const refQuantidade = useRef(null);

  const [focusNoCampoQuantidade, setFocusNoCampoQuantidade] = useState();
  const [focusNoCampoProduto, setFocusNoCampoProduto] = useState();

  const handleOpenModalAtalhos = () => {
    setModalAtalhos(!modalAtalhos);
  };

  const handleAtalhoCancelItem = () => {
    toggle()
    setModalAtalhos(false)
  }

  const setFocusCodProduto = () => {
    refProduto.current?.focus();
  }

  const setFocusReferencia = () => {
    refReferencia.current?.focus();
  }
  const setFocusQuantidade = () => {
    refQuantidade.current?.focus();
  }

  useEffect(() => {
    setFocusNoCampoProduto(true);
  }, [])

  const handleAtalhos = (event) => {
    const atalhoAbrirModal = "KeyM"
    const atalhoFecharModalItemAtalhos = "KeyL"
    const atalhoConfirmarItem = "KeyC"
    const atalhoFocoProduto = "KeyP"
    const atalhoFocoReferencia = "KeyR"
    const atalhoQuantidade = "KeyQ"

    if (event.altKey && (event.code.toLowerCase() === atalhoAbrirModal.toLowerCase())) {
      handleOpenModalAtalhos();
    } else if (event.altKey && (event.code.toLowerCase() === atalhoFecharModalItemAtalhos.toLowerCase())) {
      handleAtalhoCancelItem();
    } else if (event.altKey && (event.code.toLowerCase() === atalhoConfirmarItem.toLowerCase())) {
      formikRef && formikRef.current.handleSubmit()
    } else if (event.altKey && (event.code.toLowerCase() === atalhoFocoProduto.toLowerCase())) {
      setFocusCodProduto();
    } else if (event.altKey && (event.code.toLowerCase() === atalhoFocoReferencia.toLowerCase())) {
      setFocusReferencia();
    } else if (event.altKey && (event.code.toLowerCase() === atalhoQuantidade.toLowerCase())) {
      setFocusQuantidade();
    }
  };

  useAtalhos(handleAtalhos);

  const [atributos, setAtributos] = useState(
    _initialvalues?.ItemOrcamentoVenda?.Atributos
  );

  const [unidadeMedida, setUnidadeMedida] = useState(
    _initialvalues?.ItemOrcamentoVenda?.UnidadeMedidaComercial
  );
  const [precoTabela, setPrecoTabela] = useState(
    _initialvalues?.ItemOrcamentoVenda?.PrecoTabela
  );
  const [precoTabelaComDesconto, setPrecoTabelaComDesconto] = useState(
    _initialvalues?.ItemOrcamentoVenda?.PrecoTabelaComDesconto
  );
  const [precoUnitario, setPrecoUnitario] = useState(
    _initialvalues?.ItemOrcamentoVenda?.PrecoUnitario
  );
  const [totalItem, setTotalItem] = useState(
    _initialvalues?.ItemOrcamentoVenda?.TotalItem
  );
  const [calcular, setCalcular] = useState(false);

  const [loadErro, setLoadErro] = useState(false);
  const [erros, setErros] = useState(null);
  const [loadingDesconto, setLoadingDesconto] = useState(false);
  const [loadingGlobal, setLoadingGlobal] = useState(false);

  const [modalDescontoEspecial, setModalDescontoEspecial] = useState();

  const [ultimoProjeto, setUltimoProjeto] = useState();

  const constantes = T();

  function onLoad(loading) {
    //setLoad(loading);
  }

  function onError(error) {
    const _erro = error?.response
      ? error?.response.data.error.message
      : error.message;

    setErros(_erro);
    setLoadErro(true);
  }

  function onToogleErro() {
    setLoadErro((prevvalue) => !prevvalue);
    setErros(null);
  }

  function AtualizarCalculo(
    setFieldValue,
    values,
    resetForm,
    codproduto,
    nomeproduto
  ) {
    if (orcamentovenda && codproduto) {
      onLoad && onLoad(true);

      const _orcamentovenda = JSON.parse(JSON.stringify(orcamentovenda));

      const _itemorcamentovenda = JSON.parse(
        JSON.stringify(values.ItemOrcamentoVenda)
      );
      _itemorcamentovenda.CodProduto = codproduto;
      _itemorcamentovenda.NomeProduto = nomeproduto;
      _itemorcamentovenda.IdentificacaoProjeto =
        _itemorcamentovenda.IdentificacaoProjeto ?? ultimoProjeto;

      let indexitem = -1;
      if (selectedItem && indexselectedItem != null) {
        _orcamentovenda.Itens[indexselectedItem] = _itemorcamentovenda;
        indexitem = indexselectedItem;
      } else {
        _orcamentovenda.Itens =
          _orcamentovenda.Itens.concat(_itemorcamentovenda);
        indexitem = orcamentovenda.Itens.length;
      }

      OrcamentoVendaService.Calcular(_orcamentovenda)
        .then((response) => {
          const itemorcamentovenda = response.data.Itens[indexitem];

          if (itemorcamentovenda.Quantidade === 0) {
            itemorcamentovenda.Quantidade = null;
            itemorcamentovenda.TotalItem = 0;
          }

          setFieldValue("ItemOrcamentoVenda", itemorcamentovenda);
          SetDadosProdutoDeItem(itemorcamentovenda);

          if (itemorcamentovenda.Quantidade === 0) {
            setTotalItem(0);
          }
          setLoadingDesconto(false);
          setLoadingGlobal(false);
          //     setAtributos(itemorcamentovenda.Atributos);
        })
        .catch((error) => {
          const _error = error?.response?.data
            ? error.response.data.error
            : error;
          onError && onError(_error);
          resetForm();
        })
        .finally(() => {
          onLoad && onLoad(false);
          setCalcular(false);
        });
    }
  }

  function SetDadosProdutoDeItem(item) {
    setUnidadeMedida(item?.UnidadeMedidaComercial);
    setPrecoTabela(item?.PrecoTabela);
    setPrecoTabelaComDesconto(item?.PrecoTabelaComDesconto);
    setPrecoUnitario(item?.PrecoUnitario);
    setTotalItem(item?.TotalItem);
    setPermiteFracionario(item?.TipoUnidadeMedida == "Fracionada");
    setMultiplos(item?.QtdeMultiplaVenda);
    setDevePreencherAtributos(
      configs?.UsarAtributosVenda && item?.FormulaParaQuantidadeId > 0
    );
    setDeveMostrarSobMedida(item?.PermitirSobMedida);
  }

  const IdentificacaoItem = () => {
    const { values, setFieldValue, resetForm } = useFormikContext();

    return (
      <Container>
        <Stack direction="horizontal" gap={3}>
          <EditControl
            name="ItemOrcamentoVenda.Item"
            placeholder={constantes.Item}
            type="number"
            disabled
          />
          <EditControl
            name="ItemOrcamentoVenda.Id"
            placeholder="Id"
            type="number"
            disabled
          />
          <div className="p-2 ms-auto"></div>
          <div className="vr" />
          <div className="p-0">
            <Stack>
              <strong>{constantes.Atalhos}</strong>
              {values?.ItemOrcamentoVenda.TabelaPrecoId && (
                <small>
                  {" "}
                  {constantes.UsandoTabeladePreco}{" "}
                  {values?.ItemOrcamentoVenda?.TabelaPrecoId}{" "}
                </small>
              )}
              {values?.ItemOrcamentoVenda.Cfop && (
                <small>
                  {constantes.Usandocfop} {values?.ItemOrcamentoVenda?.Cfop.Id}
                </small>
              )}
              {values?.ItemOrcamentoVenda.QtdeMultiplaVenda && (
                <small>
                  {" "}
                  {constantes.Multiplode}{" "}
                  {values?.ItemOrcamentoVenda.QtdeMultiplaVenda}
                </small>
              )}
            </Stack>
          </div>
        </Stack>
      </Container>
    );
  };

  const SelecaoReferencia = () => {
    const { values, setFieldValue, resetForm } = useFormikContext();

    return (
      <Container>
        <Stack direction="horizontal" gap={3}>
          <Stack>
            <Form.Label className="mb-0">{constantes.Produto}</Form.Label>
            <CamposSearchListProduto
              autoFocus={focusNoCampoProduto}
              ref={refProduto}
              idTabelaPreco={TabelaPrecoId}
              disabled={somenteLeitura}
              hideTitle
              nameId="ItemOrcamentoVenda.CodProduto"
              //nameDesc="ItemOrcamentoVenda.NomeProduto"
              placeholder="Produto"
              onLoad={onLoad}
              onError={onError}
              style={{ width: 1000 }}
              onBlur={(e) => {
                setFocusNoCampoProduto(false)
                setFocusNoCampoQuantidade(true)
              }}
              onItemSelected={(item) => {
                values.ItemOrcamentoVenda.FormulaParaQuantidadeId =
                  item.FormulaParaQuantidadeId;
                setDevePreencherAtributos(
                  configs?.UsarAtributosVenda &&
                  item?.FormulaParaQuantidadeId > 0
                );
                setDeveMostrarSobMedida(item?.PermitirSobMedida);
                setLoadingGlobal(true);

                AtualizarCalculo(
                  setFieldValue,
                  values,
                  resetForm,
                  item.Id,
                  item.Nome
                );
                setFocusNoCampoProduto(false)
                setFocusNoCampoQuantidade(true)

              }}
            />
          </Stack>
          <div className="vr" />

          <Stack>
            <Form.Label className="mb-0">{constantes.Referencia}</Form.Label>
            <NewSearchListReferenciaProduto
              ref={refReferencia}
              disabled={somenteLeitura}
              idTabelaPreco={TabelaPrecoId}
              hideTitle
              nameId="ItemOrcamentoVenda.ReferenciaProduto"
              //nameDesc="ItemOrcamentoVenda.NomeProduto"
              placeholder={constantes.Referencia}
              onLoad={onLoad}
              onError={onError}
              style={{ width: 1000 }}
              onItemSelected={(item) => {
                values.ItemOrcamentoVenda.FormulaParaQuantidadeId =
                  item.FormulaParaQuantidadeId;
                setDevePreencherAtributos(
                  configs?.UsarAtributosVenda &&
                  item?.FormulaParaQuantidadeId > 0
                );
                setDeveMostrarSobMedida(item?.PermitirSobMedida);

                //values.ItemOrcamentoVenda.CodProduto = item.Id;
                AtualizarCalculo(
                  setFieldValue,
                  values,
                  resetForm,
                  item.Id,
                  item.Nome
                );
              }}
            />
          </Stack>
        </Stack>
      </Container>
    );
  };

  const SelecaoProduto = () => {
    const { values, setFieldValue, resetForm, handleChange } = useFormikContext();

    return (
      <Container>
        <Stack direction="horizontal" gap={2}>
          <div className="p-0" style={{ width: "98%" }}>
            <Form.Control
              name={"ItemOrcamentoVenda.NomeProduto"}
              className="me-auto"
              value={values.ItemOrcamentoVenda.NomeProduto}
              onChange={handleChange}
              type="text"
              placeholder={""}
              // style={{ flex: 10 }}
              disabled
            />
          </div>
          <div className="p-0">
            {loadingGlobal ? (
              <Spinner animation="border" size="10" variant="secondary" />
            ) : (
              <Button
                variant="outline-secondary"
                disabled={!values.ItemOrcamentoVenda.CodProduto}
                type="button"
                onClick={(e) => {
                  setModalComposicao(true);
                }}
                style={{ width: 60, alignContent: "bottom" }}
              >
                <FontAwesomeIcon icon={faBox}></FontAwesomeIcon>
              </Button>
            )}
          </div>
        </Stack>
      </Container>
    );
  };

  const addCondicaoEspecial = (condicao, setFieldValue, values, resetForm) => {
    const itemOrcamento = {
      ...values.ItemOrcamentoVenda,
      CondicoesEspeciais: [
        ...(values.ItemOrcamentoVenda.CondicoesEspeciais ?? []),
        condicao,
      ],
    };

    values.ItemOrcamentoVenda = itemOrcamento;

    AtualizarCalculo(
      setFieldValue,
      values,
      resetForm,
      values.ItemOrcamentoVenda.CodProduto
    );
  };

  const ItemOrcamentoVendaCondicaoEspecial = (props) => {
    const { setFieldValue, values, resetForm } = useFormikContext();

    const refDescontoEspecial = useRef();
    const refJustificativa = useRef();

    return (
      <>
        {/* {vendedorSimulado && */}
        <Modal
          {...props}
          fullscreen={"xl-down"}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalDescontoEspecial}
          onHide={() => {
            setModalDescontoEspecial(false);
          }}
          backdrop="static"
          keyboard={false}
          className={props.className}
        >
          <ModalHeader toggle={toggle} closeButton></ModalHeader>
          <ModalBody>
            <Form.Label>{constantes.DescontoEspecialExtra}</Form.Label>
            <Form.Control
              type="Number"
              ref={refDescontoEspecial}
              onWheel={(e) => e.target.blur()}
            />
            <Form.Label>{constantes.Justificativa}</Form.Label>
            <Form.Control ref={refJustificativa} />
          </ModalBody>
          <ModalFooter>
            <Button
              bsPrefix="ButtonStyle DescontoEspecialOk"
              onClick={() => {
                addCondicaoEspecial(
                  {
                    PercentualDescontoEspecial: Number(
                      refDescontoEspecial.current.value
                    ),
                    Justificativa: refJustificativa.current.value,
                  },
                  setFieldValue,
                  values,
                  resetForm
                );
                setModalDescontoEspecial(false);
              }}
            >
              {constantes.ok}
            </Button>
          </ModalFooter>
        </Modal>
        {configs?.HabilitarDescontoEspecial && !somenteLeitura && (
          <Button
            bsPrefix="ButtonStyle adicionarDescontoEspecial"
            onClick={() => {
              setModalDescontoEspecial(true);
            }}
          >
            <FontAwesomeIcon icon={faAdd} />
          </Button>
        )}
      </>
    );
  };

  const CondicaoEspecialItem = (props) => {
    const {
      name,
      placeholder,
      namedefault,
      valuecalculated,
      hideTitle,
      disabled,
      lado,
      onBlur,
      value,
      heightLabel,
    } = props;
    const [fieldId, metaId, handleId] = useField(name ?? null);

    const hasValueCalculated = valuecalculated > 0 ? true : false;

    const { handleChange, errors, isValid } = useFormikContext();

    return (
      <>
        <Form.Label style={{ height: heightLabel ?? 40 }} htmlFor={placeholder}>
          {placeholder}
        </Form.Label>
        <InputGroup>
          <Form.Control
            {...props}
            style={{ maxWidth: 250 }}
            id={placeholder}
            name={name ?? null}
            type="number"
            value={value ? value : metaId.value}
            onChange={(e) => {
              handleChange(e);
            }}
            onWheel={(e) => e.target.blur()}
            aria-describedby={placeholder}
            placeholder="0,00"
            disabled={true}
          />
          <ItemOrcamentoVendaCondicaoEspecial />
        </InputGroup>

        {hasValueCalculated && (
          <Form.Text id={placeholder} muted>
            {constantes.Tabela} ({format_percent_2.format(valuecalculated ?? 0)}
            )
          </Form.Text>
        )}

        <ErrorMessage name={name}>
          {(msg) => <Badge bg="danger">{msg}</Badge>}
        </ErrorMessage>
      </>
    );
  };

  const Descontos = () => {
    const { setFieldValue, values, resetForm } = useFormikContext();

    return (
      <Container>
        <Row style={{ textAlign: "left" }}>
          {/* <Col style={{ textAlign: 'right' }} >
                        <Stack>
                            <p />
                            <p />
                            <p />
                            <Form.Label>%</Form.Label>
                            <Form.Label>Tabela</Form.Label>
                        </Stack>
                    </Col> */}
          {configs?.QuantidadeMaximaDescontos > 0 &&
            configs?.PermitirAlterarDescontosVenda && (
              <Col>
                <CalculatedControl
                  disabled={somenteLeitura}
                  name="ItemOrcamentoVenda.PercentualDesconto1Praticado"
                  valuecalculated={descontosTabela?.PercentualDesconto1Tabela}
                  placeholder={constantes.PercentualDesconto1}
                  onBlur={(e) => {
                    setCalcular(true);
                  }}
                />
              </Col>
            )}
          {configs?.QuantidadeMaximaDescontos > 1 &&
            configs?.PermitirAlterarDescontosVenda && (
              <Col>
                <CalculatedControl
                  disabled={somenteLeitura}
                  name="ItemOrcamentoVenda.PercentualDesconto2Praticado"
                  valuecalculated={descontosTabela?.PercentualDesconto2Tabela}
                  placeholder={constantes.PercentualDesconto2}
                  onBlur={(e) => {
                    setCalcular(true);
                  }}
                />
              </Col>
            )}

          {configs?.QuantidadeMaximaDescontos > 2 &&
            configs?.PermitirAlterarDescontosVenda && (
              <Col>
                <CalculatedControl
                  disabled={somenteLeitura}
                  name="ItemOrcamentoVenda.PercentualDesconto3Praticado"
                  valuecalculated={descontosTabela?.PercentualDesconto3Tabela}
                  placeholder={constantes.PercentualDesconto3}
                  onBlur={(e) => {
                    setCalcular(true);
                  }}
                />
              </Col>
            )}

          {configs?.QuantidadeMaximaDescontos > 3 &&
            configs?.PermitirAlterarDescontosVenda && (
              <Col>
                <CalculatedControl
                  disabled={somenteLeitura}
                  name="ItemOrcamentoVenda.PercentualDesconto4Praticado"
                  valuecalculated={descontosTabela?.PercentualDesconto4Tabela}
                  placeholder={constantes.PercentualDesconto4}
                  onBlur={(e) => {
                    setCalcular(true);
                  }}
                />
              </Col>
            )}
          {configs?.QuantidadeMaximaDescontos > 4 &&
            configs?.PermitirAlterarDescontosVenda && (
              <Col>
                <CalculatedControl
                  disabled={somenteLeitura}
                  name="ItemOrcamentoVenda.PercentualDesconto5Praticado"
                  valuecalculated={descontosTabela?.PercentualDesconto5Tabela}
                  placeholder={constantes.PercentualDesconto5}
                  onBlur={(e) => {
                    setCalcular(true);
                  }}
                />
              </Col>
            )}

          {/* </Row>
                <Row style={{ textAlign: 'left' }}> */}
          {configs?.HabilitarDescontoEspecial &&
            configs?.PermitirAlterarDescontosVenda && (
              <Col>
                <CondicaoEspecialItem
                  disabled={somenteLeitura}
                  name="ItemOrcamentoVenda.PercentualDescontoEspecialPraticado"
                  placeholder={constantes.PercentualDescontoEspecial}
                  onBlur={(e) => {
                    setCalcular(true);
                  }}
                />
              </Col>
            )}

          {configs?.PermitirAlterarAcrescimoVenda && (
            <Col>
              <CalculatedControl
                disabled={somenteLeitura}
                name="ItemOrcamentoVenda.PercentualAcrescimoPraticado"
                valuecalculated={descontosTabela?.PercentualAcrescimoTabela}
                placeholder={constantes.PercentualAcrescimo}
                onBlur={(e) => {
                  setCalcular(true);
                }}
              />
            </Col>
          )}
          {configs?.PermitirAlterarAcrescimoVenda && (
            <Col>
              <CalculatedControl
                disabled={somenteLeitura}
                name="ItemOrcamentoVenda.PercentualAcrescimoEspecialPraticado"
                valuecalculated={
                  descontosTabela?.PercentualAcrescimoEspecialTabela
                }
                placeholder={constantes.PercentualAcrescimoEspecial}
                onBlur={(e) => {
                  setCalcular(true);
                }}
              />
            </Col>
          )}
          <Col
            sm
            style={{ padding: 20, marginTop: "1.65rem", alignItems: "center" }}
          >
            {!somenteLeitura &&
              (loadingDesconto ? (
                <Spinner
                  animation="border"
                  style={{ marginTop: 2, alignItems: "center" }}
                  variant="primary"
                />
              ) : (
                <Button
                  disabled={somenteLeitura}
                  bsPrefix="ButtonStyle aplicarDescontoseAcrecimos"
                  type="button"
                  onClick={(e) => {
                    setLoadingDesconto(true);
                    AtualizarCalculo(
                      setFieldValue,
                      values,
                      resetForm,
                      values.ItemOrcamentoVenda.CodProduto
                    );
                  }}
                >
                  {constantes.AplicarDescontos}{" "}
                </Button>
              ))}
          </Col>
          {values.ItemOrcamentoVenda?.CondicoesEspeciais?.length > 0 &&
            configs?.HabilitarDescontoEspecial && (
              <Col>
                <ItemOrcamentoVendaCondicoesEspeciaisCtx />
              </Col>
            )}
        </Row>
      </Container>
    );
  };

  const ItemFiname = () => {
    const itens_finame = itensFiname && itensFiname.length > 0 && (
      <Container>
        <Row>
          <Col colSpan={3}>
            <Form.Label className="mb-0">{constantes.ItensFiname}</Form.Label>
            <NewSelectControl
              disabled={somenteLeitura}
              name="ItemOrcamentoVenda.ItemFiname"
              placeholder={constantes.ItemFiname}
              hideTitle
              itens={itensFiname}
            />
          </Col>
        </Row>
      </Container>
    );

    return itens_finame ?? null;
  };

  const Quantidade = () => {
    return devePreencherAtributos ? (
      <>
        <Form.Label className="mb-0">{constantes.Quantidade}</Form.Label>

        <Stack direction="horizontal" gap={2}>
          <EditControl
            ref={refQuantidade}
            disabled
            hideTitle
            type="Number"
            name="ItemOrcamentoVenda.Quantidade"
            placeholder={constantes.Quantidade}
            isValid={false}
          />
          <Button
            type="button"
            style={{
              backgroundColor: "#009180",
              color: "white",
              border: "1px solid rgba(76,77,76,0.1550595238095238)",
            }}
            onClick={(e) => {
              setModalAtributos(true);
            }}
          >
            <FontAwesomeIcon icon={faBox}></FontAwesomeIcon>
          </Button>
        </Stack>
      </>
    ) : (
      <EditControl
        autoFocus={focusNoCampoQuantidade}
        ref={refQuantidade}
        disabled={somenteLeitura || devePreencherAtributos || loadingGlobal}
        type="Number"
        onBlur={(e) => {
          setTotalItem(
            Number((Number(e.target.value) * precoUnitario).toFixed(2))
          );
          setFocusNoCampoProduto(false)
          setFocusNoCampoQuantidade(false)
        }}
        name="ItemOrcamentoVenda.Quantidade"
        placeholder={constantes.Quantidade}
        isValid={false}
      />
    );
  };

  const QuantidadeEPrecos = () => {
    const { values, setFieldValue, resetForm, handleChange } =
      useFormikContext();

    return (
      <Container>
        <Row>
          <Col>
            <Quantidade />
          </Col>

          <Col>
            <Form.Label className="mb-0">
              {constantes.UnidadeMedida}{" "}
              {loadingGlobal ? (
                <Spinner animation="border" size="sm" variant="secondary" />
              ) : (
                ""
              )}{" "}
            </Form.Label>

            <Form.Control value={unidadeMedida} disabled />
          </Col>
          <Col>
            <Form.Label className="mb-0">
              {constantes.PrecoTabela}{" "}
              {loadingGlobal ? (
                <Spinner animation="border" size="sm" variant="secondary" />
              ) : (
                ""
              )}{" "}
            </Form.Label>
            <Form.Control
              value={format_currency_2.format(precoTabela ?? 0)}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label className="mb-0">
              {constantes.PrecoTabelaComDesconto}{" "}
              {loadingGlobal ? (
                <Spinner animation="border" size="sm" variant="secondary" />
              ) : (
                ""
              )}{" "}
            </Form.Label>
            <Form.Control
              value={format_currency_2.format(precoTabelaComDesconto ?? 0)}
              disabled
            />
          </Col>
          <Col>
            <Form.Label className="mb-0">
              {constantes.PrecoUnitario}{" "}
              {loadingGlobal ? (
                <Spinner animation="border" size="sm" variant="secondary" />
              ) : (
                ""
              )}{" "}
            </Form.Label>
            <Form.Control
              value={format_currency_4.format(precoUnitario ?? 0)}
              disabled
            />
          </Col>
          <Col>
            <Form.Label className="mb-0">{constantes.TotalItem} </Form.Label>

            {/* {calcular && <Button
                            type="button"
                            onClick={() => {
                                AtualizarCalculo(
                                    setFieldValue,
                                    values,
                                    resetForm);
                            }

                            } >Calcular
                        </Button>}
                        {!calcular && */}
            <Form.Control
              style={{
                fontSize: 25,
                height: "4.2vh",
                textAlign: "right",
                fontWeight: "bold",
              }}
              value={format_currency_2.format(totalItem ?? 0)}
              disabled
            />
            {/* } */}
          </Col>
        </Row>
      </Container>
    );
  };

  const Modals = () => {
    const { values, setFieldValue, resetForm, handleChange } =
      useFormikContext();

    return (
      <Container>
        <ModalComposicaoProdutoControl
          id={values.ItemOrcamentoVenda.CodProduto}
          idBusca={""}
          modal={modalComposicao}
          closeModal={() => {
            setModalComposicao(false);
          }}
          apenasVisualizar={true}
          titleModal={constantes.ComposicaoProduto}
        >
          <NewComposicaoProdutoControl
            constantes={constantes}
            id={values.ItemOrcamentoVenda.CodProduto}
          />
        </ModalComposicaoProdutoControl>


        <ModalAtributosProdutoControl
          id={values.ItemOrcamentoVenda.CodProduto}
          atributos={atributos}
          somenteLeitura={somenteLeitura}
          modal={modalAtributos}
          quantidadeCalculada={values.ItemOrcamentoVenda.Quantidade}
          setQuantidadeCalculada={(atributos, qtd) => {
            setFieldValue("ItemOrcamentoVenda.Quantidade", Number(qtd));
            setFieldValue("ItemOrcamentoVenda.Atributos", atributos);
            setTotalItem(Number((Number(qtd) * precoUnitario).toFixed(2)));
            setAtributos(atributos);
            setModalAtributos(false);
          }}
          toggle={() => {
            setModalAtributos(false);
          }}
        />
        <ErrosModal onHide={onToogleErro} show={loadErro} erro={erros} />
      </Container>
    );
  };

  function isMultiplo(divisor, dividendo) {
    const retorno = divisor - dividendo * Math.trunc(divisor / dividendo);
    return retorno === 0;
  }

  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        initialValues={selectedItem ? _initialvalues : initialValues}
        //TotalItem: Yup.number().moreThan(0, " deve ser > 0").required("Deve ser preenchido"),//

        validationSchema={Yup.object({
          ItemOrcamentoVenda: Yup.object({
            CodProduto: Yup.string().required("Deve ser preenchido"),
            Quantidade: Yup.string().required("Deve ser preenchido"),
            PrecoUnitario: Yup.number()
              .moreThan(0, " deve ser > 0")
              .required("Deve ser preenchido"),
          }),
        })}
        validate={(values, isValidating) => {
          let errors = {};

          if (multiplos && multiplos > 0) {
            if (!isMultiplo(values.ItemOrcamentoVenda.Quantidade, multiplos)) {
              return {
                ItemOrcamentoVenda: {
                  Quantidade: `A Quantidade deve ser múltipla de ${multiplos}`,
                },
              };
            }
          }

          if (!permiteFracionario) {
            const valorfracionado =
              values.ItemOrcamentoVenda.Quantidade % 1 !== 0;

            if (valorfracionado) {
              return {
                ItemOrcamentoVenda: {
                  Quantidade: `Valor fracionado não é permitido para o item`,
                },
              };
            }
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
          setUltimoProjeto(values.ItemOrcamentoVenda.IdentificacaoProjeto);
          selectedItem
            ? props.onEditItem(values.ItemOrcamentoVenda)
            : props.onAddItem(values.ItemOrcamentoVenda);
          setSubmitting(false);
          resetForm();
          SetDadosProdutoDeItem(initialValues);
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmiting,
          resetForm,
          setFieldValue,
          setSubmitting,
          setFieldTouched,
          handleSubmit,
        }) => {


          return (
            <Form noValidate onSubmit={handleSubmit}>
              <Modals />
              <Accordion defaultActiveKey="0">
                <Stack gap={2}>
                  <IdentificacaoItem />
                  <SelecaoReferencia />
                  <SelecaoProduto />
                  <QuantidadeEPrecos />
                  <ItemFiname />

                  {deveMostrarSobMedida && (
                    <Container>
                      <NewCheckBoxControl
                        disabled={somenteLeitura}
                        name="ItemOrcamentoVenda.SobMedida"
                        placeholder={constantes.SobMedida}
                      />
                    </Container>
                  )}

                  {!configs?.DesabilitarCamposConformePerfil && (
                    <Container>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          {constantes.Descontos}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Descontos />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Container>
                  )}

                  <Container>
                    <small>{constantes.IDProjetoGrupo}</small>
                    <NewGruposProdutosVendasControl disabled={somenteLeitura} />
                  </Container>

                  <Container>
                    <small>{constantes.Observacoes}</small>
                    <CampoMemo
                      disabled={somenteLeitura}
                      hideTitle
                      name="ItemOrcamentoVenda.Observacoes"
                      placeholder={constantes.Observacoes}
                    />
                  </Container>

                  <Container>
                    <Button
                      type="submit"
                      bsPrefix="ButtonStyle confirmar"
                      variant="primary"
                      disabled={somenteLeitura}
                    >
                      {props.continuar
                        ? constantes.AddEContinuar
                        : constantes.Confirmar}
                    </Button>
                  </Container>
                </Stack>
              </Accordion>
            </Form>
          );
        }}
      </Formik>
      <ModalAtalhos
        maxWidthModal={"md"}
        id={""}
        idBusca={""}
        modal={modalAtalhos}
        closeModal={() => {
          handleOpenModalAtalhos();
        }}
        apenasVisualizar={true}
        titleModal={constantes.AtalhosLabel}
      />

    </>

  );
};

export default ItemOrcamentoVendaNew;
