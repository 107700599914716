
import { Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { Button } from "react-bootstrap";
import { BsChatRightTextFill } from "react-icons/bs";



const ModalDelete = ({constantes,id,open,close,onExcluir, children}) => {


    return (
        <>
            <Dialog
                open={open}
                onClose={close}
                maxWidth="sm"
                fullWidth
            >
                <DialogContent>
                   {children}
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{ background: "#af0728", border: "none" }}
                        onClick={() => onExcluir(id)}
                    >
                        {constantes.Excluir}
                    </Button>
                    <Button
                        style={{ background: " #009180", border: "none" }}
                        onClick={close}
                    >
                        {constantes.Cancelar}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ModalDelete;
