import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";
import { GetConfiguracoes, GetPermissoes, SetConfiguracoes, SetPermissoes } from "../config/localstorageConfig/localstorage";

class UsuarioService extends ServiceBase {

    getPermissoes() {
        return http.get("/Usuarios/Permissoes");
    }

    getConfiguracoes() {
        return http.get("/Usuarios/Configuracoes");
    }

    async setCachePermissoes() {
        const permissoes = GetPermissoes();
        if (!permissoes) {
            await this.getPermissoes()
                .then(response => {
                    SetPermissoes(response.data.value)
                })
                .catch(e => {
                    SetPermissoes({})
                    console.error(e);
                })
        };
    }

    async setCacheConfiguracoes() {
        const configuracoes = GetConfiguracoes();
        if (!configuracoes) {
            await this.getConfiguracoes()
                .then(response => {
                    SetConfiguracoes(response.data)
                })
                .catch(e => {
                    SetConfiguracoes({});
                    console.error(e);
                })
        };
        
    }

}

export default new UsuarioService();