import { useState } from "react";
import PedidoService from "../../services/service.pedido";
import NewSearchBarControlFormik from "../searchs_comp/search-Bar.Control.Formik";
import T from "../../common/traducao";


const CampoSearchListItensPedido = (props) => {

    const [itenspedido, setItensPedido] = useState();
    const [pages, setPages] = useState();
    const [filter, setFilter] = useState();

    const { idPedido } = props;

    const constantes = T()

    const columns = [
        {
            field: 'Id',
            headerName: constantes.Codigo,
            width: 90,
            key: true
        },
        {
            field: 'ProdutoId',
            headerName: constantes.CodigoProduto
        },
        {
            field: 'NomeProduto',
            headerName: constantes.Produto
        },
        {
            field: 'ProdutoReferencia',
            headerName: constantes.Referencia
        },
        {
            field: 'Quantidade',
            headerName: constantes.Quantidade
        },
        {
            field: 'Largura',
            headerName: constantes.Largura
        },
        {
            field: 'Espessura',
            headerName: constantes.Espessura
        },
        {
            field: 'PesoLiquido',
            headerName: constantes.PesoLiquido,
        },
        {
            field: 'PesoBruto',
            headerName: constantes.PesoBruto
        }
        
    ]

    const columnsFilter = [
        {
            field: 'ProdutoId',
            headerName: constantes.CodigoProduto
        },
        {
            field: 'NomeProduto',
            headerName: constantes.NomeProduto,
            tipo: 'text'
        }
    ]

    function onLoad(loading) {
        props.onLoad(loading)
    }

    function onError(error) {
        props.onError(error)
    }

    function onSearchTitle(filter) {
        onLoad(true);
        PedidoService.getItensPedido(idPedido, filter)
            .then(response => {
                const itenspedido = response.data.value;
                setItensPedido(itenspedido);
                setPages(response.data.pages);
                setFilter(filter);
            })
            .catch(e => {
                onError(e);
            })
            .finally(() => {
                onLoad(false);
            });

    }

    function onGetItem(id) {
        id && onLoad(true);
        id && PedidoService.getItemPedido(id)
            .then(response => {
                if (response.response?.data.error) {
                    onError(response.response?.data.error);
                } else {
                    // const traducao = ProdutoService.getProdutoTraduzido(response.data);
                    // const produto = { ...response.data, Nome: traducao };
                    response.data && onItemSelected(response.data);
                }
            })
            .catch(e => {
                onError(e);
            })
            .finally(() => {
                onLoad(false);
            });
    }

    function onItemSelected(item) {
        props.onItemSelected(item);
    }

    function onItemClear() {
        props.onItemClear();
    }



    return (
        <>
            <NewSearchBarControlFormik
                {...props}
                fullscreen
                pages={pages}
                onSearchTitle={onSearchTitle}
                onGetItem={onGetItem}
                onItemSelected={onItemSelected}
                onItemClear={onItemClear}
                data={itenspedido}
                columns={columns}
                storagename={"ItensPedido"}
                columnsFilter={columnsFilter}
            />
        </>
    )
}


export default CampoSearchListItensPedido;
