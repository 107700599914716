import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";

class vendedorService extends ServiceBase {

  getAll(filter) {
    return http.get("/Vendedores" + this.getFilter(filter));
  }

  getVinculados(filter) {
    return http.get("/Vendedores/Supervisionados" + this.getFilter(filter));
  }

  get(id) {
    return http.get(`/Vendedores/${id}`);
  }

}

export default new vendedorService();