import AssistenciaProvider from "../../contexts/assistencias-context";
import ViewAssistencias from "../../components/assistencia";

const ViewAssistenciasTabs = () => {
  return (
    <AssistenciaProvider>
        <ViewAssistencias />
      </AssistenciaProvider>
  );
};

export default ViewAssistenciasTabs;
