import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import SearchComp from "../../common/searchcomp";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NewAlertError from "../../components/ui_comp/Alerts/views.alert.error";
import T from "../../common/traducao";
import {
  GetPermissaoEditar,
  GetPermissaoExcluir,
  GetPermissaoInserir,
  GetPermissaoVisualizar,
  getToken,
} from "../../config/localstorageConfig/localstorage";
import jwt_token from "jwt-decode";
import { Alert } from "react-bootstrap";
import ListComp2 from "../../common/listcomp2";
import Cookies from "js-cookie";
import { TOKEN_KEY } from "../../config/localstorageConfig/localstorage";
import serviceCliente from "../../services/service.cliente";
import { PiListDashesFill } from "react-icons/pi";
import { FaRegCheckCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import { BiBlock } from "react-icons/bi";
import { Box } from "@mui/material";

const Clientes = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [clientes, setClientes] = useState();
  const [clientesId, setClientesId] = useState(null);
  const [pages, setPages] = useState();
  const [records, setRecords] = useState();
  const [filterstate, setFilterState] = useState();
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [IndexOrcamentoSelecionado, setIndexOrcamentoSelecionado] = useState();
  const token = Cookies.get(TOKEN_KEY);
  const ordertype = useRef();
  const orderField = useRef();

  const [permiteVisualizar, setPermiteVisualizar] = useState(false);
  const [permiteEditar, setPermiteEditar] = useState(false);
  const [permiteExcluir, setPermiteExcluir] = useState(false);
  const [permiteInserir, setPermiteInserir] = useState(false);

  const [redirect, setRedirect] = useState();

  const constantes = T();

  const columns = [
    {
      field: "Id",
      headerName: "Código",
      width: 100,
      key: true,
    },
    {
      field: "RazaoSocial",
      headerName: "Nome",
      width: 150,
    },
    {
      field: "NomeFantasia",
      headerName: "Apelido",
      width: 150,
    },
    {
      field: "CPFCNPJ",
      headerName: "CNPJ / CPF",
      width: 150,
    },

    {
      field: "DataCadastro",
      headerName: "Data Cadastro",
      width: 90,
      type: "date",
      format: "dd/MM/yyyyy",
    },
    {
      field: "TipoPessoa",
      headerName: "Tipo Pessoa",
      width: 150,
    },
    {
      field: "Endereco",
      headerName: "Endereço",
      width: 150,
    },
    {
      field: "EnderecoNumero",
      headerName: "Num.",
      width: 90,
    },
    {
      field: "Municipio",
      headerName: "Munícipio",
      width: 150,
    },
    {
      field: "UF",
      headerName: "UF",
      width: 150,
    },
    {
      field: "IdVendedor",
      headerName: "Código Vendendor",
      width: 150,
    },
    {
      field: "Email",
      headerName: "Email",
      width: 150,
    },

    {
      field: "IdentidadeEstrangeiro",
      headerName: "Ident.Estrangeiro",
      align: "left",
    },
    {
      field: "Situacao",
      headerName: "Situação",
      width: 10,
      type: "custom",
      onGetCustom: (item) => {
        const style = getStyle(item);

        return (
          <Box sx={{ width: "100%", justifyContent: "center" }}>
            <Button style={style}>
              <span>
                {item === "Bloqueado" ? (
                  <BiBlock
                    size={16}
                    style={{ marginTop: -3, }}
                  />
                ) : (
                  <FaRegCheckCircle
                    size={16}
                    style={{ marginTop: -3 }} />
                )}
              </span>

            </Button>
          </Box>

        );
      },
    },
  ];
  function getStyle(Situacao) {
    if (Situacao === "Ativo") {
      return {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 7,
        background: "#0d9b86",
        border: "0px solid #007a6a",
        fontSize: 15,
        gap: 12,


      };
    } else if (Situacao === "Bloqueado") {
      return {
        display: "flex",
        background: "Crimson",
        color: "white",
        marginLeft: 7,
        fontSize: 14,
        border: "0px solid #7f0017",


        gap: 3,
        alignItems: "center",
      };
    }
    return null;
  }

  const columnsFilter = [
    {
      field: "RazaoSocial",
      headerName: "Nome",
      tipo: "text",
    },

    {
      field: "NomeFantasia",
      headerName: "Apelido",
      tipo: "text",
    },
    {
      field: "Id",
      headerName: "Código",
      tipo: "number",
    },
    {
      field: "CPFCNPJ",
      headerName: "CPF/CNPJ",
      tipo: "text",
    },
    {
      field: "municipio",
      headerName: "Município",
      tipo: "text",
    },
    {
      field: "email",
      headerName: "E-mail",
      tipo: "text",
    },
    {
      field: "Pais",
      headerName: "Ident.Estrangeiro",
      tipo: "text",
    },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    redirect && navigate(`/${redirect}`);
  }, [redirect]);

  useEffect(() => {
    let filter = {
      campo: null,
      operacao: null,
      valor: null,
    };

    getClientes(filter);
  }, []);

  useLayoutEffect(() => {
    // try {
    const token_salvo = getToken();
    if (token_salvo) {
      const token = jwt_token(token_salvo);
      let filter = {
        campo: null,
        operacao: null,
        valor: null,
      };

      getClientes(filter);

      setPermiteVisualizar(GetPermissaoVisualizar("cliente"));
      setPermiteEditar(GetPermissaoEditar("cliente"));
      setPermiteExcluir(GetPermissaoExcluir("cliente"));
      setPermiteInserir(GetPermissaoInserir("cliente"));
    } else {
      setRedirect("logout");
    }
  }, []);

  function getIdOrcamento(index) {
    return clientes && clientes[index]?.Id;
  }

  useEffect(() => {
    const id = getIdOrcamento(IndexOrcamentoSelecionado);
    id && navigate(`/clientes/${id}`);
  }, [IndexOrcamentoSelecionado]);

  function getClientes(filter) {
    setError(null);
    setLoading(true);
    serviceCliente
      .getAll(filter)
      .then((response) => {
        setClientes(response.data.value);
        setPages(response.data.pages);
        setRecords(response.data.registros);
        setFilterState(filter);

      })
      .catch((error) => {
        const _error =
          error.code === "ERR_NETWORK"
            ? "Server OffLine"
            : error.response && error.response.data && error.response.data.error
              ? error.response.data.error.message
              : error.message;
        setError(_error);
        {
          error.code === "ERR_NETWORK" && navigate("/logout");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function onSearchTitle(searchValue, searchField, serachFilter) {
    let filter = {
      situacao: null,
      campo: searchField,
      operacao: serachFilter,
      valor: searchValue,
      campoorder: orderField.current,
      tipoorder: ordertype.current,
    };

    getClientes(filter);
  }

  function getSearchView() {
    return permiteVisualizar ? (
      <SearchComp
        hasBotao={permiteInserir}
        to="/clientes/new"
        botaoDisabilitado={loading}
        title={constantes.Clientes}
        campos={columnsFilter}
        onSearch={(values) => {
          onSearchTitle(
            values.searchValue,
            values.searchField,
            values.searchoperator
          );
        }}
      />
    ) : null;
  }

  function OrderChange(field, type) {
    orderField.current = field;
    ordertype.current = type;

    let filter = {
      ...filterstate,
      campoorder: field,
      tipoorder: type,
    };
    getClientes(filter);
  }

  function btnSituacaoOnClick(situacao) {
    let filter = {
      situacao: situacao,
      campo: null,
      operacao: null,
      valor: null,
      campoorder: orderField.current,
      tipoorder: ordertype.current,
    };

    getClientes(filter);
  }

  function onPageSelect(number) {
    let filter = filterstate;
    filter.page = number;

    getClientes(filter);
  }

  const handleRowClick = (index) => {
    setSelectedRowIndex(index);
  };

  const idDetalhesCliente = () => {
    const clientId = getIdOrcamento(selectedRowIndex);
    if (!clientId) {
      toast.warn("Selecione um Cliente para ver os Detalhes");
    } else {
      navigate(`/clientes/${clientId}`);
    }
  };
  function getListView() {
    return (
      <>
        <ListComp2
          data={clientes}
          pages={pages}
          records={records}
          columns={columns}
          alerta={true}
          btntype={"dropdown"}
          onPageSelect={(number) => onPageSelect(number)}
          sortname={orderField.current}
          sorttype={ordertype.current}
          onSortSelect={(item, sorttype) => {
            if (sorttype === "sort") {
              OrderChange(null, null);
            } else {
              OrderChange(item, sorttype);
            }
          }}
          striped={false}
          noselect
          ativarSelectClientes={true}
          onRowClick={(index) => handleRowClick(index)}
          selectedRowIndex={IndexOrcamentoSelecionado}
        />
      </>
    );
  }

  return permiteVisualizar ? (
    <Container fluid className="my-3">
      <NewAlertError error={error} onClose={() => setError(null)} />

      {/* <Card>
                  <Card.Header>{getSearchView()}</Card.Header>
                  <Card.Body>
                      <blockquote className="blockquote mb-0">
                          {getFilterSituacao()}
                      </blockquote>
                  </Card.Body>
              </Card>
              {getListView()} */}

      <Container style={{ padding: 0, margin: 0 }} fluid className="my-3">
        {getSearchView()}
      </Container>

      <Container style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <div>
          <Button
            onClick={() => {
              idDetalhesCliente();
            }}
            style={{
              background: selectedRowIndex !== null ? "#008977" : "grey",
              border: "none",
              transition: "background 0.8s ease",
              ...(window.innerWidth <= 600 && {
                fontSize: "15px",
                padding: 6
              }),
              ...(window.innerWidth <= 280 && {
                fontSize: "12px",
                width: "68px",
                padding: 4,
                marginRight: 4
              }),
            }}
          >
            <PiListDashesFill
              style={{ marginBottom: "3px", marginRight: "2px" }}
            />
            Detalhes
          </Button>

        </div>

        <div style={{ display: "flex", gap: 15 }}>
          <Button
            onClick={(e) => btnSituacaoOnClick("Ativo")}
            situacao="Ativo"
            style={{
              background: "#008977",
              border: "none",
              ...(window.innerWidth <= 600 && {
                fontSize: "14px",

                padding: 5
              }),
              ...(window.innerWidth <= 280 && {
                fontSize: "12px",
                width: "60px",
                padding: 2
              }),
            }}
          >
            <FaRegCheckCircle
              size={16}
              style={{
                marginTop: -3,
                marginRight: 4,

              }} />

            Ativos
          </Button>

          <Button
            onClick={(e) => btnSituacaoOnClick("Bloqueado")}
            situacao="Bloqueado"
            style={{
              background: "Crimson",
              border: "none",
              ...(window.innerWidth <= 600 && {
                fontSize: "12px",
                width: "100px",
                padding: 4
              }),
              ...(window.innerWidth <= 280 && {
                fontSize: "12px",
                width: "88px",
                padding: 2
              }),
            }}
          >
            <BiBlock
              size={16}
              style={{ marginTop: -3, marginRight: 4 }}
            />
            Bloqueados
          </Button>

        </div>

      </Container>
      <Container style={{ padding: 0, margin: 0 }} fluid className="my-3">
        {getListView()}
      </Container>
    </Container>
  ) : (
    <>
      <Container>
        <Alert variant="danger">
          <Alert.Heading>{constantes.Permissao}</Alert.Heading>
          <p>{constantes.Msgsempermissao}</p>
        </Alert>
      </Container>
    </>
  );
};

export default Clientes;
