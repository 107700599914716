import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import GruposProdutosVendaService from "../../services/service.gruposprodutosvenda";


const GruposProdutosVendasControl = (props) => {

    const [GruposProdutos, setGruposProdutos] = useState();
    const { values, handleChange } = useFormikContext();

    async function getGruposProdutosVendaService() {
        await GruposProdutosVendaService.getAll()
            .then((response) => {
                setGruposProdutos(response.data.value);

            })
            .catch((error) => console.error("GruposProdutos", error))
    }

    useEffect(() => {
        getGruposProdutosVendaService();
    }, [])

    function getGruposProdutosVenda() {
        return (
            <>
                <Form.Select
                    {...props}
                    value={values.ItemOrcamentoVenda?.IdentificacaoProjeto}
                    onChange={handleChange}
                    name={"ItemOrcamentoVenda.IdentificacaoProjeto"}
                >
                    <option key={-1} value={""}>{""}</option>
                    {GruposProdutos?.map((grupo, i) => {
                        return <option key={i} value={grupo.Nome}>{grupo.Nome}</option>
                    })}
                </Form.Select>
              
            </>
        )
    }

    return (
        getGruposProdutosVenda()
    )
}


export default GruposProdutosVendasControl;