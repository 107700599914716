

import { useEffect, useState } from "react";
import { GetIdiomaSelecionado, GetTextosIdioma, SetIdiomaSelecionado } from "../config/localstorageConfig/localstorage";

export const constantes_default =
{
  "$IdiomaDestino": "pt-br",
  "Atalhos": "Atalhos ALT + M",
  "AtalhosLabel": "Atalhos",
  "AtalhoGravaItem":"ALT + C: Gravar Item",
  "AtalhoCodProduto": "ALT + P: Ir para Cód. Produto",
  "AtalhoRefencia": "ALT + R: Ir para Referência",
  "AtalhoQuantidade": "ALT + Q: Ir para Quantidade",
  "AtalhoCancelarItem": "ALT + L: Cancelar Item",
  "FecharModalAtalhos": "Esc: Fechar Modal Atalhos",
  "Acoes": "Ações",
  "Assistencias": "Assistências",
  "Assistencia": "Assistência",
  "AnexarArquivos": "Anexar Arquivos",
  "AssistenciaTecnica": "Visita Técnica",
  "Acrescimo": "Acréscimo",
  "Anexos": "Anexos",
  "Administrativo": "Administrativo",
  "Adicionar": "Adicionar",
  "AVista": "À Vista",
  "APrazo": "A Prazo",
  "Aprovada": "Aprovada",
  "AguardandoEnvio": "Aguard Envio",
  "AguardandoRetorno": "Aguard Retorno",
  "AguardandoResposta": "Aguard Resposta",
  "Add": "Add",
  "Acessar": "Acessar",
  "ApelidoFantasia": "Apelido Fantasia",
  "ApelidoNomeFantasia": "Apelido / NomeFantasia",
  "AddEContinuar": "Add e Continuar",
  "AguardeImportandoItens": "Aguarde, importando itens",
  "AjustarEstoque": "Ajustar Estoque",
  "Aplicar": "Aplicar",
  "Atencao": "Atenção",
  "AlterarDescontoseAcrescimos": "Alterar descontos e acréscimos",
  "AplicarDescontoseAcrescimos": "Aplicar descontos e acréscimos",
  "AplicarDescontos": "Aplicar",
  "Apartir": "A partir",
  "Ate" : "Até",
  "Entre" : "Entre",
  "AdicionarComentarios": "Adicionar Comentários",
  "Atributos": "Atributos",
  "AtributosDoProduto": "Atributos do produto",
  "AtributosparaCalculodeQuantidade": "Atributos para Cálculo de Quantidade",
  "Atualizar": "Atualizar",
  "Admin": "Admin",
  "Bairro": "Bairro",
  "Bloqueado": "Bloqueado",
  "Buscar": "Buscar",
  "Bairrodocliente": "Bairro do cliente",
  "Bairrodorecebedor": "Bairro do recebedor",
  "BaseCalculoComissao": "Base Cálculo Comissão",
  "Bairrodecobranca": "Bairro de cobrança",
  "BemVindo": "Bem Vindo",
  "Cancelar": "Cancelar",
  "Clientes":" Clientes",
  "Cliente": "Cliente",
  "Comentario": "Comentário",
  "Comentarios":"Comentários",
  "Carregar": "Carregar",
  "Campospendentes": "Campos Pendentes",
  "Carregando": "Carregando",
  "Cancelado": "Cancelado",
  "ComposicaoProduto": "Composição Produto",

  "Cep": "Cep",
  "Condicoes": "Condições",
  "Causa": "Causa",
  "Cancelada":"Cancelada",
  "Codigo": "Código",
  "CodigoNota": "Código Nota",
  "CodigoProduto": "Código do Produto",
  "CodigoCliente": "Código do Cliente",
  "Comercial":"Comercial",
  "Modulo": "Módulo",
  "Cadastro": "Cadastro",
  "CodigoCausa": "Código da Causa",
  "CondicoesdeVenda":"  Condições de Venda",
  "Financeiro":"Financeiro",
  "ComDesconto": "Com Desconto",
  "Complemento": "Complemento",
  "Confirmar": "Confirmar",
  "Configuracoes": "Configurações",
  "Contem": "Contem",
  "Cancelar": "Cancelar",
  "CodigoVendas": "Código Vendas",
  "Cadastrar": "Cadastrar",
  "ContratacaoDestinatario": "Contratação de Destinatário",
  "ContratacaoRemetente": "Contratação de Remetente",
  "ContratacaoTerceiros": "Contratação de Terceiros",
  "CondicaodePagamento" : "Condição de Pagamento",
  "CpfCnpj": "CPF CNPJ",
  "CodigoCliente": "Código Cliente",
  "CodigoVendedor": "Código Vendedor",
  "ContribuinteICMScomIE": "Contribuinte ICMS com IE",
  "ContribuinteICMSisentodeIE": "Contribuinte ICMS isento de IE",
  "Complementodoenderecodecobranca": "Complemento do endereço de cobrança",
  "NãocontribuinteICMS": "Não contribuinte ICMS",
  "CpfCnpjCliente": "CPF / CNPJ",
  "CPFCNPJ":"CPF/CNPJ",
  "CadastrarProblema": "Cadastrar Problema",
  "CPFCNPJConsumidor": "CPF/CNPJ Consumidor",
  "CEPNaoEncontrado": "CEP não encontrado",
  "CNPJ":"CNPJ",
  "CancelamentoOrcamento" : "Cancelamento Orçamento",
  "CausaAssistencia": "Causa da Assistência",
  "CPF":"CPF",
  "CodigoTipoAssistencia": "Código Tipo Assistência",
  "Compras": "Compras",
  "CodigoCausa": "Código da Causa",
  "CodigoItem": " Código do Item",
  "CodigoConsumidor": "Código Consumidor",
  "Consumo": "Consumo",
  "Complemento": "Complemento",
  "Cubagem": "Cubagem",
  "Clientes": "Clientes",
  "Cliente": "Cliente",
  "CodigoPedido": "Código Pedido",
  "ClasseEntidade": "Classe Entidade",
  "Data": "Data",
  "DataEmissao" : "DataEmissao",
  "DataSaidaEntrada" : "DataSaidaEntrada",
  "DestinoOperacao": "Destino Operação",
  "DataCadastro": "Data Cadastro",
  "DataSolicitacao": "Data Solicitação",
  "DataPrevisaoFaturamento": "Data Previsão Faturamento",
  "DataPrevisaoFaturamentoNula" : "A data de previsão de faturamento deve ser preenchida",
  "DataReferencia": "Data de Referência",
  "DataValidade": "Data de Validade",
  "DataSaidaEntrada": "Data Saída Entrada",
  "Descricao": "Descrição",
  "DuplicadodoOrcamento": "Duplicado do orçamento",
  "Duplicar": "Duplicar",
  "Descontos": "Descontos",
  "Desconto": "Desconto",
  "Desconto1": "Desconto 1",
  "Desconto2": "Desconto 2",
  "Desconto3": "Desconto 3",
  "Desconto4": "Desconto 4",
  "Desconto5": "Desconto 5",
  "data": "Data",
  "DataPrevisaoVisitaTecnica": "Previsão para vísita técnica",
  "DataEnvio": "Data Envio",
  "DataRetorno": "Data Retorno",
  "DataFaturamento": "Data Faturamento",
  'DataPrevisaoFaturamento': 'Data Previsão Faturamento',
  "DisponibilidadeInicial": "Disponibilidade Inicial ",
  "DisponibilidadeFinal": "Disponibilidade Final",
  "DataEmissaoAssistencia":"Data Emissão",
  "DescricaoProblema": "Descrição Problema",
  "DataNascFund": "Data Nasc./Fund",
  "DataComentario": "Data",
  "DescontoAcimaAuto": "Desconto Acima Auto",
  "DescontoEspecial": "Desc. Especial",
  "DescontoEspecialExtra": "Desconto Especial",
  "DescontosAcrescimos": "Descontos/Acréscimos",
  "DigiteAPesquisa": "Digite a Pesquisa",
  "DuplicarOrcamento": "Duplicar Orçamento",
  "DadosAdicionais":"Dados Adicionais",
  "DefinirVencimentos": "Definir Vencimentos",
  "Departamento": "Departamento",
  "DuplicarAssistencia": "Duplicar Assistência",
  "Detalhesdorecebedor": "Detalhes do recebedor",
  "Detalhesdoendereo": "Detalhes do endereço",
  "Distancia": "Distância",
  "Deletar": "Deletar",
  "Descricao": "Descrição",
  "Efetivar": "Efetivar",
  "Email": "E-Mail",
  "EmAnalise": "Análise",
  "Emails":"E-mails",
  "Editar": "Editar",
  "ErrorDowload": "Erro ao fazer o download do arquivo",
  "EditarAssistencia": "Editar Assistência",
  "VisualizarAssistencia": "Visualizar Assistência",
  "EmailContato":" E-mail para Contato",
  "EmaildoEntrega": "E-mail do Entrega",
  "Enderecodacobranca": "Endereço da cobrança",
  "EmailporDepartamento":"  E-mail por Departamento",
  "Enderecodoenderecodecobranca": "Endereço do endereço de cobrança",
  "enviar": "enviar",
  "Entrar": "Entrar",
  "EnderecodeEntrega": "Endereco de Entrega",
  "Erro": "Erro",
  "Excluir":"Excluir",
  "Enviada": "Enviada",
  "EditarComentario": "Editar Comentário",
  "EnderecodeCobranca":" Endereço de Cobrança",
  "Errospendentes": "Existem alguns erros pendentes",
  "ExigirItensFiname": "Exigir Itens Finame",
  "EnderecoPrincipal": "Endereço Principal ",
  "Enderecoprincipaldocliente": "Endereço principal do cliente",
  "Escrevasuasnotasgerais": "Escreva suas notas gerais",
  "Escolher": "Escolher",
  "Estoque": "Estoque",
  "Estado": "Estado",
  "Endereco":"Endereço",
  "EnderecoDaEntrega" : "Endereço da Entrega",
  "Endereco": "Endereço",
  "Espessura": "Espessura",
  "ExistemAlgunsErrosPendentes": "Existem alguns erros pendentes",
  "FaltaAtualizar": "Falta atualizar",
  "Fc": "FCP ST",
  "FcSt": "FCP ST",
  "Finalizado": "Finalizado",
  "Finalizada": "Finalizada",
  "Finalizar": "Finalizar",
  "FCPST": "FCPST",
  "FormaPagamento" : "Forma de Pagamento",
  "Frete": "Frete",
  "FoneResidencial":"Fone Residencial",
  "FoneComercial": "Fone Comercial",
  "FoneCelular": "Fone Celular",
  "FoneFax": "Fone Fax",
  "Fisica":"Física",
  "Fechar": " Fechar",
  "GerandoPedidoParaOrcamento": "Gerando pedido para o orçamento",
  "Gerando": "Gerando",
  "Gravar": "Gravar",
  "Geral":"Geral",
  "Home":" Home",
  "Ie": "IE",
  "Igual": "Igual",
  "IdProjetoGrupo": "ID Projeto / Grupo",
  "IdSupervisor": "IdSupervisor",
  "IdVendedor": "IdVendedor",
  "InformacoesdaCausa": "Informações da Causa",
  "ImpressaoOrcamento": "Impressão do Orçamento",
  "ImpressaoAssistencia": "Impressão da Assistência",
  "ImagemdoProduto": "Imagem do Produto",
  "ImportandoItens": "Importando itens",
  "Imagem": "Imagem",
  "ImportarItens": "Importar Itens Orçamento",
  "ImportarItensPedidos" : "Importar Itens Pedido",
  "ImportarItensDoOrcamentoDeVendas": "Importar itens do orçamento de vendas",
  "ImportarItensDoPedido": "Importar itens do pedido",
  "InformeMotivoCancelamento" : "Informe um motivo de cancelamento",
  "Incluir": "Incluir",
  "IncluirVarios": "Incluir Vários",
  "IncluirpeloAssistente": "Incluir pelo Assistente",
  "InfoUsuario": "Info Usuário",
  "Iniciacom": "Inicia Com",
  "IndicadorIE": "Indicador IE",
  "ItensDoOrcamento": "Itens do Orçamento",
  "ItensFinameDoOrcamento": "Itens Finame do Orçamento",
  "ItensFiname": "Item Finame",
  "IndicadorIedoCliente": "Indicador Ie do Cliente",
  "Industrializacao":"Industrialização",
  "InscricaoMunicipal":"Inscrição Municipal",
  "Item": "Item",
  "InformacoesdoPedido": "Informações do Pedido",
  "Imprimir": "Imprimir",
  "ItensaRetornar": "Itens a retornar",
  "InscrEstadualProdutor": "Inscr.Estadual/Produtor",
  "InscricaoEstadualProdutordoCliente": "Inscrição Estadual Produtor do Cliente",
  "InscricaoMunicipaldoCliente": "Inscrição Municipal do Cliente",
  "inscricaoEstadualProdutordoRecebedor": "Inscrição Estadual Produtor do Recebedor",
  "IdentidadedeEstrangeirodoCliente": "Identidade de Estrangeiro do Cliente",
  "Inicio" : " Início",
  "ItensaEnviar":"Itens a enviar",
  "Identificacao": "Identificacao",
  "ItemFiname": "Item Finame",
  "ImpressoesPrincipais": "Disponível",
  "IdentEstrangeiro": "Ident. Estrangeiro",
  "Justificativa": "Justificativa",
  "JustificativaVisitaTecnica": "Justificativa da Visita",
  "Juridica":"Jurídica",
  "Liberado": "Liberado",
  "ListadeArquivosEnviados": "Lista de Arquivos Enviados",
  "Largura": "Largura",
  "Latitude" : "Latitude",
  "Longitude" : "Longitude",
  "MedidaA": "Medida A",
  "MedidaB": "Medida B",
  "ModoUso": "Modo Uso",
  "MotivoSituacao" : "Motivo",
  "Municipio" : "Município",
  "MsgExcluirAssistencia": "Deseja realmente excluir a assistência",
  "MsgFiltroNaoEncontradoOrcamentos": "Ops! Parece que não há itens cadastrados correspondentes ao filtro selecionado!",
  "MsgFiltroNaoEncontradoAssistencias": "Ops! Parece que não há assistências correspondentes ao filtro selecionado!",
  "MsgPrazoAssistenciaVencido": "Prazo da assistência está vencido !",
  "MsgAguardeImprimirAssistencia": "Aguarde... gerando impressão da assistência",
  "MsgAguardeImprimirOrcamento": "Aguarde... gerando impressão do orçamento",
  "MsgAvisodeEditarOrcamento": "Você está editando um orçamento, seu valor original era de",
  "Msgcasoencomendafor": "Caso a medida A for maior que 1,94 ela terá emenda, não indicada para forração, pois pode ocorrer ondulação na emenda.",
  "MsgDesejaRealmenteExcluir": "Deseja realmente excluir o orçamento de venda de número",
  "MsgDuplicarOrcamento": "Deseja realmente duplicar o orcamento",
  "Msgfixed": "SISTEMA VEDAFULL 2 LAMINADA AMARELA 140G/M2 RETANGULAR TEXTIL",
  "Msgorcamentovencido": "Este orçamento passou a data de validade e por esse motivo foi recalculado, podendo ter sido alterado seu valor final.",
  "Msgsempermissao": " Sem permissão para acessar esta página",
  "MsgPendenciasImportarItensErros": "Encontramos algumas pendências em alguns itens importados",
  "MsgLimite": " Você atingiu o seu limite",
  "MsgInfoItem": " Informações dos Itens Pedido",
  "MsgCausa": "Nenhuma causa selecionada",
  "MsgAnexoNaoCarregado": "Não foi possível renderizar o formato deste arquivo",
  "MsgAnexo": "Arraste e solte ou clique para adicionar um novo arquivo",
  "Multiplode": "múltiplo de",
  "MsgItem": "Nenhum item selecionado",
  "MsgInfoNota": "Informações do Nota",
  "MsgPedido": "Nenhum pedido selecionado",
  "MsgCardHome" : " Módulo para gerar orçamentos, acesso rápido ao módulo de orçamentos !",
  "MsgCardClientes": "Módulo para cadastro de clientes, detalhes e dados, acesso rápido ao módulo de clientes !",
  "MsgCardAssistencia": "Módulo para Assistências,suporte,visita técnica,  acesso rápido ao módulo de assistências !",
  "MunicipioEntrega": "Município Entrega",
  "MsgNota": "Nenhuma nota selecionada",
  "MsgNenhumProdutoRetornado": "Nenhum produto retornado !",
  "MsgArrasteeSolte": "Arraste e solte ou clique para adicionar um novo arquivo",
  "MsgAddAnexo": " Arraste e solte os arquivos aqui ou clique para selecionar arquivos",
  "MsgItemNaoPossuiComponentes": "Este item não possui componentes",
  "MEI": "MEI",
  "Nome": "Nome",
  "NomeCliente": "Nome Cliente",
  "NovaAssistencia": "Nova Assistência",
  "Nomeparacontato": "Nome para contato",
  "Novas": "Novas",
  "Normal": "Normal",
  "NomeProduto": "Nome Produto",
  "NomeRecebedor": "Nome Recebedor",
  "Nao": "Não",
  "NotasGerais": "Notas Gerais",
  "Novo": "Novo",
  "TotalRegistros" : "Total registros",
  "NenhumaDataRetornada": "Nenhuma data retornada",
  "NenhumaObservacaoEnvio": "Nenhuma observação no envio",
  "Nenhumanexoaqui": "Nenhum anexo aqui ",
  "NenhumaDataEnvio": "Nenhuma data enviada",
  "NenhumItemEnviado": "Nenhum item enviado",
  "NenhumItemRetorno": "Nenhum item retorno",
  "NenhumaObservacaoRetorno": "Nenhuma observação no item retornado",
  "NomeVendedor": "Vendedor",
  "NovoOrcamentoDeVenda": "Novo Orçamento de Vendas",
  "NumeroAssistencia" : "Numero",
  "Numero": "Número",
  "Numerodoendereco": "Numero do endereço",
  "NumerodoEnderecoEntrega": "Numero da entrega",
  "NomeTipoAssistencia": "Nome Tipo Assistência",
  "NumeroAtendimento": "Número Atendimento",
  "NomeCausa":"Causa",
  "Nenhumanexoadicionado": "Nenhum anexo adicionado",
  "Nenhumcomentarioadicionado": "Nenhum comentário adicionado",
  "NotaVenda":"Nota Venda",
  "nomeResponsavel": "Nome Responsável",
  "ObservacaodeEnvio": "Observação de Envio",
  "ObservacaodeRetorno": "Observação de Retorno",
  "ok": "ok",
  "Outros":"Outros",
  "Observacao":"Observação",
  "Ordenarpor": "Ordenar por",
  "Observacoesdorecebedor": "Observações do recebedor",
  "Observacao": "Observação",
  "ObservacaoFiscal": "Observação Fiscal",
  "ObservacaoInterna": "Observação Interna",
  "ObservacaoUsuario": "Observação Usuário",
  "ObservacaoVenda": "Observação Venda",
  "ObservacaoEntrega":"Observação Entrega",
  "Observacoes": "Observações",
  "OrcamentoDeVenda": "Orçamento de Vendas",
  "OrcamentosDeVenda": "Orçamentos de Vendas",
  "OrcamentosDeclientes": "Sistema de Clientes",
  "Parcelas": "Parcelas",
  "PrazoMedio": "Prazo Médio",
  "PercentualAcrescimo": "Acréscimo",
  "PercentualAcrescimoEspecial": "Acrés. Especial",
  "PercentualComissao": "% Comissão",
  "PercentualDesconto1": "Desconto 1",
  "PercentualDesconto2": "Desconto 2",
  "PercentualDesconto3": "Desconto 3",
  "PercentualDesconto4": "Desconto 4",
  "PercentualDesconto5": "Desconto 5",
  "PedidoVendas": "Pedido Vendas",
  "PedidoVenda":"Pedido Venda",
  "PrazoResposta": "Prazo Resposta",
  "PedidoVendaEnvio": "Pedido Venda Envio",
  "PedidoRetorno": "Pedido Retorno",
  "PedidoEnvio": "Pedido Envio",
  "PedidoVendaOrigem": "Pedido Venda Origem",
  "ProdutorRural": "Produtor Rural",
  "Principal": "Principal",
  "ProdutoVendido": "Produto Vendido",
  "ProdutoProblema": "Produto Problema",
  "Possiveispendencias": "Possíveis Pendências",
  "PercentualDescontoEspecial": "Desc. Especial",
  "PesoBruto": "Peso Bruto",
  "PesoBrutoTotal": "Peso Bruto Total",
  "PesoLiquidoTotal": "Peso Liquido Total",
  "PesoLiquido": "Peso Liquido",
  "PedidoGerado": "Pedido Gerado com sucesso",
  "PrecentualDescAcimaAuto": "% Desc. Acima Auto",
  "PrecoTabela": "Preço Tabela",
  "PrecoTabelaComDesconto": "Preço Tabela c/ Desc",
  "PrecoUnitario": "Preço Unitário",
  "Pais": "Pais",
  "Produto": "Produto",
  "Preco": "Preço",
  "Problema": "Problema",
  "PreviewAnexos": "Visualizar Anexos",
  "PerfildeAcesso": "Perfil de Acesso",
  "ProprioDestinatario": "Próprio Destinatário",
  "ProprioRemetente": "Próprio Remetente",
  "PermVenda": "Perm. Venda",
  "Quantidade": "Quantidade",
  "Recalcular": "Recalcular",
  "RecalcularTudo": "Recalcular tudo",
  "Recalculando": "Recalculando",
  "Referencia": "Referência",
  "Responsavel":" Responsável",
  "Rascunho": "Rascunho",
  "Retornada": "Retornada",
  "Responsavel": "Responsavel", 
  "Reprovada":"Reprovada",
  "RG":"RG",
  "Responsavel": "Responsável",
  "RGdoCliente": "RG do Cliente",
  "Revenda":"Revenda",
  "RecepcaoodeNFe": "Recepção de NF-e",
  "RecepcaodeNFSe": "Recepção de NFS-e",
  "RecepcaodeCTe":"Recepção de CT-e",
  "Saldo": "Saldo",
  "Salvar": "Salvar",
  "Searchby": "Search by",
  "Selecionar": "Selecionar",
  "SistemadeOrcamento": "Sistema Orcamento",
  "SemDesconto": "Sem Desconto",
  "SelecionarItemVendido": "Selecionar o próprio item",
  "SelecionarComponente": "Selecionar Componente",
  "SelecionaroProblema":"Selecionar o Problema",
  "SelecionarProblema": "Selecionar Problema",
  "SemPagamento": "Sem Pagamento",
  "Servico": "Serviço",
  "Serie" : "Serie",
  "Solucao": "Solução",
  "ServerOffLine": "Server OffLine",
  "SituacaoSolucao": "Situação Solução",
  "Situacao": "Situação",
  "SituacaoVisita": "Situação da Visita",
  "SituacaoCliente": "Situação do Cliente",
  "SelecioneumIndicadorIe": "Selecione um indicador IE",
  "SobMedida": "Sob Medida",
  "Sim": "Sim",
  "Sair": "Sair",
  "SomenteVencidos": "Somente Vencidos",
  "Simples": "Simples",
  "SolicitarAssistenciaTecnica": "Solicitar Visita ",
  "Solicitar": "Solicitar Alteração",
  "SistemadeClientes": "Sistema de Clientes",
  "SolicitarCliente": "Solicitar Cliente",
  "Selecioneotipodocontribuinte": "Selecione o tipo do contribuinte",
  "SimularAcesso": "Simular Acesso",
  "SelecionarLocalizacao": "Selecionar Localização",
  "Tabela": "Tabela",
  "TabelaPreco": "Tabela Preço",
  "TipodeContribuinte": "Tipo de Contribuinte",
  "TipoPessoa": "Tipo Pessoa",
  "TabelaDesconto": "Tabela de Desconto",
  "TipodoFrete" : "Tipo do Frete",
  "Teste": "Teste",
  "TotalIPI": "Total IPI",
  "TotalItens": "Total Itens",
  "TotalFrete": "Total Frete",
  "TotalPrecoTabela": "Total Preço Tabela",
  "TotalItem": "Total Item",
  "TotalST": "Total ST",
  "TI": "TI",
  "TotalValor": "Total Valor",
  "TipoAssistencia": "Tipo da Assistência",
  "Totais": "Totais",
  "Telefone": "Telefone Comercial",
  "TelefoneEntrega": "Telefone Entrega",
  "UnidadeMedida": "Und. Medida",
  "Unid": "Unid",
  "UsandoTabeladePreco": " Usando tabela de preço",
  "usuario":"Usuário",
  "Usandocfop": " Usando cfop ",
  "Usuario": "Usuário",
  "ValorPedido": "Valor Pedido",
  "ValorComissao": "Valor Comissão",
  "ValorDif": "Valor Dif.",
  "ValorST": "Valor ST",
  "ValorTotal": "Valor Total",
  "VencimentoFixo": "Vencimento Fixo",
  "Vencimentos": "Vencimentos",
  "Versao" : "Versão",
  "VisualizarAssistencia": "Visualizar Assistência",
  "Vendas": "Vendas",
  "VisualizarArquivo": "Visualizar Arquivo",
  "Voltar": "voltar",
  "VisualizarAnexo": "Visualizar Anexo",
  "ValorPedido": "Valor Pedido",
  "ValorNota": "Valor Nota",
  "Visualizar": "Visualizar",
  "VerificqueCredenciais": "Houve um problema com o login, verifique suas credenciais",
  "Voceestaeditandoorcamento": "Você está editando um orçamento, seu valor original era de",
  "IDProjetoGrupo": "ID Projeto / Grupo",
  "DeixarDeSimularAcesso" : "Deixar de simular acesso",
  "Selecionevendedorquedesejasimularoacesso" : "Selecione o vendedor que deseja simular o acesso",
  "Vendedor" : "Vendedor",
  "Pedido" : "Pedido",
  "Nota" : "Nota",
  "ItemPedido" : "Item Pedido",
  "TodosProdutos" : "Todos Produtos",
  
 
}

function T() {

  const [constantes, setConstantes] = useState(GetTextosIdioma());

  useEffect(() => {
    setConstantes(GetTextosIdioma());
  }, [SetIdiomaSelecionado])

  return constantes ? constantes : constantes_default;
}

export default T;
