
import { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Paper,
  Tab,
  Tabs,
  useTheme,
  useMediaQuery
} from "@mui/material";

import { useFormikContext } from "formik";
import ClienteTabGeral from "./cliente-tab-geral";
import ClienteTabEnderecoPrincipal from "./cliente-tab-endereco-principal";
import ClienteTabEnderecoEntrega from "./cliente-tab-endereco-entrega";
import { ClienteContext } from "../../contexts/clientes-context";
import ClienteTabEmail from "./cliente-tab-emails";
import ClientTabCondicoesVenda from "./cliente-tab-condicoes-venda";
import ClientTabNotasGerais from "./cliente-tab-notas-gerais";
import ClientTabEnderecoCobranca from "./cliente-tab-endereco-cobranca";
import { ClientePainel } from "./cliente-painel";
import ClienteTabDadosAdicionais from "./cliente-tab-dados-adicionais";

export default function ClienteTabs() {

  const theme = useTheme();

  const screenWidth = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    constantes,
    tabValue,
    setTab,
    fecharMenu,
    somenteLeitura
  } = useContext(ClienteContext)

  const [abasVisiveis, setAbasVisiveis] = useState();

  const formik = useFormikContext();
  const { values, errors, isSubmitting } = formik;

  function selecionarTab(event, newValue) {
    setTab(newValue);
    fecharMenu();
  }

  <Tab label="Notas Gerais" />

  function getAbasVisiveis(cliente, errors) {
    return [
      {
        id: 0,
        name: "Geral",
        label: constantes.Geral,
        visible: true,
        color: !somenteLeitura && errors?.Geral ? "red" : "#007767",
        element: <ClienteTabGeral />
      },
      {
        id: 1,
        name: "EnderecoPrincipal",
        label: constantes.EnderecoPrincipal,
        visible: true,
        color: !somenteLeitura && errors?.EnderecoPrincipal ? "red" : "#007767",
        element: <ClienteTabEnderecoPrincipal />
      },
      {
        id: 2,
        name: "EnderecoEntrega",
        label: constantes.EnderecodeEntrega,
        visible: true,
        color: !somenteLeitura && errors?.EnderecoEntrega ? "red" : "#007767",
        element: <ClienteTabEnderecoEntrega />
      },
      {
        id: 3,
        name: "EnderecoCobrança",
        label: constantes.EnderecodeCobranca,
        visible: true,
        color: !somenteLeitura && errors?.EnderecoCobrança ? "red" : "#007767",
        element: <ClientTabEnderecoCobranca />
      },
      {
        id: 4,
        name: "Emails",
        label: constantes.Emails,
        visible: true,
        color: !somenteLeitura && errors?.Emails ? "red" : "#007767",
        element: <ClienteTabEmail />
      },
      {
        id: 5,
        name: "DadosAdicionais",
        label: constantes.DadosAdicionais,
        visible: true,
        color: !somenteLeitura && errors?.DadosAdicionais ? "red" : "#007767",
        element: <ClienteTabDadosAdicionais />
      },

      {
        id: 6,
        name: "CondicoesVenda",
        label: constantes.CondicoesdeVenda,
        visible: true,
        color: !somenteLeitura && errors?.CondicoesVenda ? "red" : "#007767",
        element: <ClientTabCondicoesVenda />
      },
      {
        id: 7,
        name: "NotasGerais",
        label: constantes.NotasGerais,
        visible: true,
        color: !somenteLeitura && errors?.NotasGerais ? "red" : "#007767",
        element: <ClientTabNotasGerais />
      }
    ]
  }

  useEffect(() => {
    setAbasVisiveis(getAbasVisiveis(values, errors));
  }, [values])


  useEffect(() => {
    if (isSubmitting) {
      const consultaAba =
        errors?.Geral
          ? "Geral"
          : errors?.EnderecoPrincipal
            ? "EnderecoPrincipal"
            : errors?.EnderecodeEntrega
              ? "EnderecodeEntrega"
              : errors?.EnderecodeCobranca
                ? "EnderecodeCobranca"
                : errors?.Emails
                  ? "Emails"
                  : errors?.DadosAdicionais
                    ? "DadosAdicionais"
                    : errors?.CondicoesdeVenda
                      ? "CondicoesdeVenda"
                      : errors?.NotasGerais
                        ? "NotasGerais"
                        : null

      const aba = abasVisiveis?.find((item) => item.name === consultaAba)
      aba && setTab(aba.id);
    }
  }, [isSubmitting])


  return (
    <>
      <Box>
        <Box padding={2}>
          {!screenWidth && (
            <Box width="100%" height={70} component={Paper} mb={3}>
              <Tabs
                value={tabValue}
                onChange={selecionarTab}
                aria-label="Tabs"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                textColor={"#007767"}
                TabIndicatorProps={{
                  style: { backgroundColor: "#007767" },
                }}
              >
                {abasVisiveis && abasVisiveis.filter((item, index) => {
                  return item.visible
                }).map((item, index) => {
                  return <Tab name={item.name} label={item.label} sx={{ color: item.color }} />
                })}
              </Tabs>
            </Box>
          )}

          {abasVisiveis && abasVisiveis.filter((item, index) => {
            return item.visible
          }).map((item, index) => {
            return (
              <ClientePainel value={tabValue} index={index}>
                {item.element}
              </ClientePainel>
            )
          })}
        </Box>
      </Box>
    </>
  );
};