import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import ItemOrcamentoVendaNew from "../../orcamento_venda/orcamentovenda-incluiritens.new";

const NewModalItemOrcamentoVendaControl = (props) => {

    const { modal, toggle, className } = props;

    return (
        <>
            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modal}
                onHide={toggle}
                className={className}>
                <ModalHeader toggle={toggle} closeButton></ModalHeader>
                <ModalBody>
                    <ItemOrcamentoVendaNew {...props} />
                </ModalBody>
            </Modal>
        </>
    )
}

export default NewModalItemOrcamentoVendaControl;