import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import NewVencimentos from "../../vencimento_comp/vencimentos-Vencimentos";

const NewModalVencimentosControl = (props) => {

    return (
        <Modal {...props}
            fullscreen={"xl-down"}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.modal}
            onHide={props.toggle}
            backdrop="static"
            keyboard={false}
            className={props.className}>
            <ModalHeader toggle={props.toggle} closeButton></ModalHeader>
            <ModalBody>
                <NewVencimentos {...props}/>
            </ModalBody>
        </Modal>
    )
}

export default NewModalVencimentosControl;