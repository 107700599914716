import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import MotivoSituacaoService from "../../services/service.motivosituacao";


const MotivosSituacaoControl = (props) => {

    const [MotivosSituacao, setMotivosSituacao] = useState();
    const [MotivoSelecionado, setMotivoSelecionado] = useState();
    const { handleChange } = props;

    async function getMotivosSituacao() {
        await MotivoSituacaoService.getAll()
            .then((response) => {
                setMotivosSituacao(response.data.value);

            })
            .catch((error) => console.error("Motivos Situacao", error))
    }

    useEffect(() => {
        getMotivosSituacao();
    }, [])

    return (
        <>
            <Form.Select
                {...props}
                value={MotivoSelecionado}
                onChange={(e) => {
                    setMotivoSelecionado(Number(e.target.value));
                    handleChange(e.target.value);
                }}
            >
                <option key={-1} value={""}>{""}</option>
                {MotivosSituacao?.map((motivo, i) => {
                    return <option key={i} value={motivo.Id}>{motivo.Descricao}</option>
                })}
            </Form.Select>

        </>
    )
}


export default MotivosSituacaoControl;