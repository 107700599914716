import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";

class GruposProdutosVendaService extends ServiceBase {

  getAll(filter) {
    //return http.get("/clientes?$top=10");
    return http.get("/GruposProdutosVenda");
  }

  get(id) {
    return http.get(`/GruposProdutosVenda/${id}`);
  }

}

export default new GruposProdutosVendaService();