import { Alert, Badge, Button, Stack } from "react-bootstrap";
import { GetConfiguracoes, LimparAcessoSimulado, getAcessoSimulado, refreshlogin } from "../../config/localstorageConfig/localstorage";
import LoginService from "../../services/service.login";
import { useState, useContext } from "react";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import T from "../../common/traducao";
export default function UsuarioSimuladoAlerta() {

    const vendedorSimulado = getAcessoSimulado();
    const configuracoes = GetConfiguracoes();

    const [error, setError] = useState();

    const constantes = T();

    return (
        vendedorSimulado &&
        <Alert variant="success">
            <Stack direction="horizontal" gap={10}>
                <label>
                    {`Você está simulando um acesso do usuário ${vendedorSimulado?.Nome}`}
                </label>
                <Button
                    style={{ padding: 10, margin: 10 }}
                    onClick={() => {
                        LoginService.refreshToken()
                            .then((response) => {
                              
                                refreshlogin(response.data.AccessToken);
                                LimparAcessoSimulado();
                                window.location.reload(false);
                            })
                            .catch((error) => {
                                console.log(error);
                                setError(error.data);
                            })
                    }}>{constantes.DeixarDeSimularAcesso}
                </Button>
                {error &&
                    <Badge>
                        {error}
                    </Badge>
                }
            </Stack>
        </Alert>

    )
}