import { forwardRef, useState } from "react";
import { GetIdiomaFiltro } from "../../config/axiosConfig/http-common";
import ProdutoService from "../../services/service.produto";
import NewSearchBarControlFormik from "./search-Bar.Control.Formik";


const SearchListReferenciaProduto = forwardRef((props, ref) => {

    const [produtos, setProdutos] = useState();
    const [pages, setPages] = useState();    

    const idioma = GetIdiomaFiltro();

    const {onLoad, onError, onItemSelected} = props;

    const columns = [
        {
            field: 'Id',
            headerName: 'Codigo',
            width: 90,
            key: true
        },
        {
            field: 'Referencia',
            headerName: 'Referencia',
            tipo: 'text'
        },
        {
            field: 'Nome',
            headerName: 'Nome',
            width: 150,
        },
        {
            field: 'IdUnidadeMedida',
            headerName: 'Unid',
            width: 70,
        },
        {
            field: 'PrecoVenda',
            headerName: 'Preço',
            width: 70,
            align: "right",
            digits:2
        },
        {
            field: 'PermissaoVenda',
            headerName: 'Perm. Venda',
            tipo: 'text'
        },
    ]

    const columnsFilter = [
        {
            field: 'Id',
            headerName: 'Codigo',
            tipo: 'number'
        },
        {
            field: idioma ? 'NomeTraduzido' : 'Nome',
            headerName: 'Nome',
            tipo: 'text'
        },
        {
            field: 'Referencia',
            headerName: 'Referencia',
            tipo: 'text'
        },
        {
            field: 'PermissaoVenda',
            headerName: 'Perm. Venda',
            tipo: 'text'
        },

    ]



    function onSearchTitle(filter) {
        onLoad(true);
        ProdutoService.getAll(filter, props.idTabelaPreco)
            .then(response => {
                const produtos = response.data.value.map((item) => {
                    return {
                        ...item,
                        Nome: item.NomeTraduzido ?? item.Nome,
                        IdUnidadeMedida : item.IdUnidadeMedidaTraduzido ?? item.IdUnidadeMedida
                    }
                })

                setProdutos(produtos);
                setPages(response.data.pages);
            })
            .catch(e => {
                onError(e);
            })
            .finally(() => {
                onLoad(false);
            });

    }

    function onGetItem(id) {
        id && onLoad(true);
        id && ProdutoService.getReferencia(id, props.idTabelaPreco)
            .then(response => {
                if (response.response?.data.error) {
                    onError(response.response?.data.error);
                } else {
                    response.data && onItemSelected(response.data);
                }
            })
            .catch(e => {
                onError(e);
            })
            .finally(() => {
                onLoad(false);
            });
    }

    return (
        <>
            <NewSearchBarControlFormik
                {...props}
                ref={ref}
                fullscreen
                typeId={"text"}
                pages={pages}
                onSearchTitle={onSearchTitle}
                onGetItem={onGetItem}
                onItemSelected={onItemSelected}
                data={produtos}
                columns={columns}
                columnsFilter={columnsFilter}
                storagename={"Referencia"}
            />
        </>
    )
})

export default SearchListReferenciaProduto;
