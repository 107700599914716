import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";

class TabelaDescontoService extends ServiceBase {

  getAll(filter) {
    //return http.get("/clientes?$top=10");
    return http.get("/tabeladescontos" + this.getFilter(filter));
  }

  get(id) {
    return http.get(`/tabeladescontos/${id}`);
  }

}

export default new TabelaDescontoService();