import { Box } from "@mui/material";
import React from "react";

export const AssistenciaPainel = ({ children, value, index }) => {
  return (
    <Box key={index} role="tabpanel" hidden={value !== index}>
      {value === index && <div>{children}</div>}
    </Box>
  );
};

