
import React from "react";
import { Navbar } from "react-bootstrap";
import T from "../../common/traducao";
import UsuarioSimuladoAlerta from "../usuario_comp/usuario-simulado-alerta";
import { Header } from './Header'

const Menu = () => {

  const constantes = T();
  const ShowMenu = JSON.parse(localStorage.getItem("@hideMenu"))

  return (
    <>
      {ShowMenu ? (<div></div>) : (
        <>
          <Navbar
            expand="lg"
            className="bg-body-tertiary"

            style={{ marginTop: "-1vh" }}

          >

            <Header />

          </Navbar>
          <UsuarioSimuladoAlerta />
        </>
      )}

    </>

  );
};

export default Menu;
