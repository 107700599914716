import { ErrorMessage, Field, useField, useFormikContext } from "formik";
import { Badge } from "react-bootstrap";
import Form from "react-bootstrap/Form";


const ControlSelect = (props) => {

  const { name } = props;

  const { validateOnMount } = useFormikContext();
  const [fieldId, metaId, handleId] = useField(name);

  return (
    <>
      <Form.Group>
        {!props.hideTitle && <Form.Label className="mb-0">{props.placeholder}</Form.Label>}

        <Field name={props.name}>
          {({ field, form }) => (
            <Form.Select
              {...props}
              {...field}
              onBlur={(e) => props.onBlur ? props.onBlur(e) : e.target.blur()}
            >
              {props.nulloption && (
                <option key={0} value={0}>
                  {null}
                </option>
              )}
              {props.itens?.map((item, i) => {
                const index = props.nulloption ? i + 1 : i;
                return (
                  <option key={index} item={item} value={item.Valor}>
                    {item.Descricao ? item.Descricao : item.Valor}
                  </option>
                );
              })}
            </Form.Select>
          )}
        </Field>
        {validateOnMount && metaId.error && <Badge bg="danger">{metaId.error}</Badge>}

        {!validateOnMount && <ErrorMessage name={props.name}>
          {(msg) => <Badge bg="danger">{msg}</Badge>}
        </ErrorMessage>}
      </Form.Group>
    </>
  )
}

export default ControlSelect;
