import { GetOperacoes, SetOperacoes } from "../config/localstorageConfig/localstorage";
import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";

class OperacaoVendaService extends ServiceBase {

    getAll(filter) {
        //return http.get("/atributos" + this.getFilter(filter));
        return http.get("/operacoesvenda");
    }

    async setCache() {
        const atributos = GetOperacoes();
        if (!atributos) {
            http.get("/operacoesvenda")
                .then(response => { SetOperacoes(response.data.value) })
                .catch(e => {
                    console.error(e);
                })
        };
    }
    
    get(id) {
        return http.get(`/operacoesvenda/${id}`);
    }
}

export default new OperacaoVendaService();
