import { ErrorMessage, Field, useField, useFormikContext } from "formik";
import { Badge, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { DatePickerField } from "./control-datepicker-field";

const DateControl = (props) => {

  const { name } = props;

  const { validateOnMount } = useFormikContext();
  const [fieldId, metaId, handleId] = useField(name);

  return (
    <Form.Group>
      <Form.Label className="mb-0">{props.placeholder}</Form.Label>
      <Field name={props.name}>
        {({ field, form }) => (
          <Container>
            <Row>
              <DatePickerField
                {...field}
                {...props}
              />
            </Row>
          </Container>
        )}
      </Field>

      {validateOnMount && metaId.error && <Badge bg="danger">{metaId.error}</Badge>}

      {!validateOnMount && <ErrorMessage name={props.name}>
        {(msg) => <Badge bg="danger">{msg}</Badge>}
      </ErrorMessage>}
    </Form.Group>
  );
}

export default DateControl;
