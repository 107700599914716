import {
    Grid,
    InputLabel,
    Select,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TextField,
    Button,
    MenuItem
} from "@mui/material";
import {
    FieldArray,
    useFormikContext
} from "formik";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { ClienteContext } from "../../contexts/clientes-context";
import { useContext } from "react";
import { BiPlusCircle } from "react-icons/bi";
import { MdDeleteForever, MdOutlineEmail } from "react-icons/md";

export default function ClienteEmailDepartamento() {

    const {
        constantes,
        disabled,
        addEmailDepartamento,
        removerEmailDepartamento
    } = useContext(ClienteContext);

    const formik = useFormikContext();

    const {
        values,
        handleChange,
        setValues
    } = formik;

    return (
        <FieldArray
            name="Entidade.Emails"
            render={() => (
                <>
                    <Grid
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            margin: 0,
                            width: "47vw",
                            "@media screen and (max-width: 1024px)": {
                                width: "49vw",
                            },
                            "@media screen and (max-width: 768px)": {
                                width: "53vw",
                            },
                            "@media screen and (max-width: 600px)": {
                                width: "100%",
                            },
                        }}
                    >
                        <InputLabel sx={{ fontWeight: "bold", margin: "0" }}>
                            {constantes.EmailporDepartamento}{" "}
                        </InputLabel>
                        <Button
                            type="button"
                            disabled={disabled}
                            sx={{
                                background: "#0D9B86",
                                fontSize: 12,
                                textTransform: "inherit",
                                color: "white",
                                margin: "15px 0 0 0 ",
                                "&:hover": {
                                    background: "#0D8A7A",
                                },
                                "&:active": {
                                    background: "#0D7261",
                                },
                            }}
                            onClick={(e) => {
                                addEmailDepartamento(setValues);
                            }}
                        >
                            <BiPlusCircle
                                size={18}
                                style={{ marginTop: 0, marginRight: 2 }}
                            />{" "}
                            {constantes.Adicionar}
                        </Button>
                    </Grid>

                    <Table
                        sx={{
                            width: "47vw",
                            borderBottom: "1px solid #a3a3a3",
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                <th
                                    style={{
                                        padding: "6px 5px",
                                        borderBottom: "1px solid #a3a3a3",
                                        fontWeight: "bold",
                                        color: "#666666",
                                        ...(window.innerWidth <= 600 && {
                                            fontSize: "15px",
                                            padding: 2,
                                        }),
                                        ...(window.innerWidth <= 376 && {
                                            fontSize: "12px",
                                            padding: 2,
                                        }),
                                    }}
                                >
                                    {constantes.Departamento}{" "}
                                    <FaEnvelopeOpenText
                                        size={15}
                                        style={{ marginTop: -2 }}
                                    />
                                </th>
                                <th
                                    style={{
                                        padding: "6px 5px",
                                        borderBottom: "1px solid #a3a3a3",
                                        fontWeight: "bold",
                                        color: "#666666",
                                        ...(window.innerWidth <= 600 && {
                                            fontSize: "15px",
                                            padding: 2,
                                        }),
                                        ...(window.innerWidth <= 330 && {
                                            fontSize: "12px",
                                            padding: 2,
                                        }),
                                    }}
                                >
                                    {constantes.Email}{" "}
                                    <MdOutlineEmail size={20} style={{ marginTop: -2 }} />
                                </th>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {values.Entidade && values.Entidade?.Emails ? (
                                values.Entidade?.Emails.map((item, index) => (
                                    <TableRow key={index}>
                                        <td>
                                            <Select
                                                name={`Entidade.Emails.[${index}].Destino`}
                                                value={item.Destino}
                                                onChange={handleChange}
                                                disabled={disabled}
                                                sx={{
                                                    width: "20vw",
                                                    height: 40,
                                                    margin: "5px",
                                                    "@media screen and (max-width: 600px )": {
                                                        width: "35vw",

                                                        margin: "2px 0px",
                                                    },
                                                }}
                                            >
                                                <MenuItem value="Administrativo">
                                                    {constantes.Administrativo}
                                                </MenuItem>
                                                <MenuItem value="Comercial">
                                                    {constantes.Comercial}
                                                </MenuItem>
                                                <MenuItem value="Compras">
                                                    {constantes.Compras}
                                                </MenuItem>
                                                <MenuItem value="Financeiro">
                                                    {constantes.Financeiro}
                                                </MenuItem>
                                                <MenuItem value="Recepção de NF-e">
                                                    {constantes.RecepcaoodeNFe}
                                                </MenuItem>
                                                <MenuItem value="Recepção de NFS-e">
                                                    {constantes.RecepcaodeNFSe}
                                                </MenuItem>
                                                <MenuItem value="Recepção de CT-e">
                                                    {constantes.RecepcaodeCTe}
                                                </MenuItem>
                                                <MenuItem value="TI">{constantes.TI}</MenuItem>
                                            </Select>
                                        </td>
                                        <td>
                                            <TextField
                                                disabled={disabled}
                                                sx={{
                                                    width: "20vw",
                                                    height: 40,
                                                    margin: "5px",

                                                    "@media screen and (max-width: 600px )": {
                                                        width: "35vw",

                                                        margin: "2px 2px",
                                                    },
                                                    "@media screen and (max-width: 320px )": {
                                                        width: "30vw",
                                                        margin: "2px 2px",
                                                    },
                                                }}
                                                name={`Entidade.Emails.${index}.Email`}
                                                value={item.Email}
                                                placeholder="Digite um email"
                                                onChange={handleChange}
                                                size="small"
                                                fullWidth
                                            />
                                        </td>
                                        <td>
                                            <Button
                                                type="button"
                                                disabled={disabled || item.obrigatorio}
                                                onClick={() => removerEmailDepartamento(index, setValues)}
                                                sx={{
                                                    background: "crimson",
                                                    fontSize: 14,
                                                    textTransform: "inherit",
                                                    color: "white",
                                                    "&:hover": {
                                                        background: "#b70526",
                                                    },
                                                    "&:active": {
                                                        background: "#b70526",
                                                    },
                                                }}
                                            >
                                                <MdDeleteForever style={{ marginTop: -2 }} />{" "}
                                                {constantes.Excluir}
                                            </Button>
                                        </td>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        Adicione emails por departamento
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </>
            )}
        />
    )
}