
import { useContext } from "react";
import {
  Box,
  Paper,
  Typography,
} from "@mui/material";
import { AiOutlineSolution } from "react-icons/ai";
import { AssistenciasContext } from "../../contexts/assistencias-context";
import "react-datepicker/dist/react-datepicker.css";
import AssistenciaSolucao from "./assistencia-solucao";

export default function AssistenciaTabSolucao() {
  const {
    constantes,
  } = useContext(AssistenciasContext);

  return (
    <Box component={Paper}>
      <Box
        display="flex"
        alignItems="center"
        p={2}
        height={50}
        sx={{ backgroundColor: "rgba(59, 134, 124, 0.08)" }}
      >
        <Typography variant="h1" fontSize={22} fontWeight="sembold">
          <AiOutlineSolution style={{ marginBottom: "5px", marginRight: "4px" }} />
          {constantes.Solucao}
        </Typography>
      </Box>
      <AssistenciaSolucao/>
         
  </Box>
  );
}