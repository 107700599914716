import { Component, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import AtributosItem from "../../atributos_comp/atributo.ItemControl";

const ModalAtributosProdutoControl = (props) => {

    const [modal, setModal] = useState(false);
    const {toggle, className} = props;

    useEffect(() => {
        setModal(props.modal);
    }, [props.modal])

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={modal}
            onHide={toggle}
            className={className}>
            <ModalHeader toggle={toggle} closeButton></ModalHeader>
            <ModalBody>
                <AtributosItem {...props} />
            </ModalBody>
        </Modal>
    )
}

export default ModalAtributosProdutoControl;
