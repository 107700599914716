import { Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { Button } from "react-bootstrap";

const ModalPrintOut = ({constantes,open,close, children}) => {

    return (
        <>
            <Dialog
                open={open}
                onClose={close}
                maxWidth="sm"
                fullWidth
            >
                <DialogContent>
                     {children}
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{ background: "#af0728", border: "none" }}
                        onClick={close}
                    >
                        {constantes.Cancelar}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ModalPrintOut;
