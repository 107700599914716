import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  InputLabel,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { AssistenciasContext } from "../../contexts/assistencias-context";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "react-bootstrap";
import { useFormikContext } from "formik";
import CampoSearchListPedidosCliente from "../campos_comp/campo-searchlist-pedidos-cliente";
import CampoSearchListNotasCliente from "../campos_comp/campo-searchlist-notas-cliente";
import CampoSearchListItensPedido from "../campos_comp/campo-searchlist-itens-pedido";
import CamposSearchListProduto from "../campos_comp/campo-searchlist-produto";
import CamposSearchCausa from "../campos_comp/campo-searchlist-causa";
import InfoIcon from "@mui/icons-material/Info";
import ModalAtributosProdutoControl from "../ui_comp/Modals/ModalAtributosProduto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CampoSearchlistProdutoProblema from "../campos_comp/campo-searchlist-produto-problema";
import { faBox } from "@fortawesome/free-solid-svg-icons";
import CampoSearchListNotasPedido from "../campos_comp/campo-searchlist-notas-pedido";

function AssistenciaProblema() {
  const {
    assistencia,
    constantes,
    somenteLeitura,
    ExibirCausaAssistencia,
    handleLoad,
    handleError,
    updatePedido,
    updateNota,
    updateItemPedido,
    updateTipoAssistencia,
    updateCausa,
    updateProdutoVendido,
    updateProdutoProblema,
    configPermissoesAssistencia,
  } = useContext(AssistenciasContext);

  const formik = useFormikContext();
  const { values, setFieldValue, handleChange } = formik;
  const [modalAtributos, setModalAtributos] = useState(false);

  const [atributos, setAtributos] = useState(values?.Assistencia?.AtributosProdutoProblema);


  // useEffect(() => {
  //   if(values.Assistencia?.Pedido?.Id === ""){
  //     updatePedido(null, setFieldValue, values)
  //     updateProdutoVendido(null, setFieldValue, values); 
  //     updateProdutoProblema(null, setFieldValue, values);
  //   }
  // },[values.Assistencia?.Pedido?.Id])

  return (
    <>
      <Grid
        container
        display={"flex"}
        flexDirection={"column"}
        spacing={2}
        p={2}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={15}
          display="flex"
          flexDirection={"row"}
          gap={1}
          sx={{
            "@media (max-width: 900px)": {
              flexDirection: "column",
            },
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={15}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <CampoSearchListPedidosCliente
              constantes={constantes}
              idCliente={values.Assistencia.Cliente?.Id}
              disabled={somenteLeitura}
              disabledDesc
              nameId="Assistencia.Pedido.Id"
              // nameDesc="Assistencia.Pedido.Numero"
              placeholder={constantes?.Pedido}
              onLoad={handleLoad}
              onError={handleError}
              onItemSelected={(item) => {
                const pedido = item
                  ? {
                    Id: item.Id,
                    Numero: item.Id
                  }
                  : null;
                updatePedido(pedido, setFieldValue, values);
              }}
              onItemClear={() => {
                updatePedido(null, setFieldValue, values);
              }}
            />


          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={15}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {values.Assistencia.Pedido &&
              <CampoSearchListNotasPedido
                constantes={constantes}
                disabledId={true}
                idPedido={values.Assistencia.Pedido?.Id}
                disabled={somenteLeitura}
                disabledDesc
                nameId="Assistencia.NotaVenda.Id"
                nameDesc="Assistencia.NotaVenda.Numero"
                placeholder={`${constantes?.Nota} - ${constantes.Pedido}`}
                onLoad={handleLoad}
                onError={handleError}
                onItemSelected={(item) => {
                  const nota = {
                    ...item
                  };
                  updateNota(nota, setFieldValue, values);
                }}
                onItemClear={() => {
                  updateNota(null, setFieldValue, values);
                }}
              />
            }
            {!values.Assistencia.Pedido &&
              <CampoSearchListNotasCliente
                constantes={constantes}
                disabledId={true}
                idCliente={values.Assistencia.Cliente.Id}
                disabled={somenteLeitura}
                disabledDesc
                nameId="Assistencia.NotaVenda.Id"
                nameDesc="Assistencia.NotaVenda.Numero"
                placeholder={`${constantes?.Nota} - ${constantes.Cliente}`}
                onLoad={handleLoad}
                onError={handleError}
                onItemSelected={(item) => {
                  const nota = {
                    ...item
                  };
                  updateNota(nota, setFieldValue, values);
                }}
                onItemClear={() => {
                  updateNota(null, setFieldValue, values);
                }}
              />
            }

          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={15}
          display="flex"
          flexDirection={"row"}
          sx={{
            "@media (max-width: 900px)": {
              flexDirection: "column",
            },
          }}
          gap={1}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={15}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <CampoSearchListItensPedido
              constantes={constantes}
              idPedido={values.Assistencia.Pedido?.Id}
              disabled={somenteLeitura || !values.Assistencia.Pedido}
              disabledDesc
              nameId="Assistencia.ItemPedido.Id"
              // nameDesc="Assistencia.Nota.Numero"
              placeholder={constantes?.ItemPedido}
              onLoad={handleLoad}
              onError={handleError}
              onItemSelected={(item) => {
                const itempedido = item
                  ? { Id: item.Id, Produto: item.Produto }
                  : null;
                updateItemPedido(itempedido, setFieldValue, values);
              }}
              onItemClear={() => {
                const itempedido = { Id: "", Produto: null };

                updateItemPedido(itempedido, setFieldValue, values);
              }}
            />


          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={15}
          display="flex"
          flexDirection={"column"}
        >
          <CamposSearchListProduto
            constantes={constantes}
            disabled={somenteLeitura || values.Assistencia.Pedido}
            disabledDesc
            nameId="Assistencia.ProdutoVendido.Id"
            nameDesc="Assistencia.ProdutoVendido.Nome"
            placeholder={`${constantes?.ProdutoVendido} ${!values.Assistencia.Pedido ? `- ${constantes?.TodosProdutos}` : ""}`}
            onLoad={handleLoad}
            onError={handleError}
            onItemSelected={(item) => {
              const produtoVendido = item ?? null;

              updateProdutoVendido(produtoVendido, setFieldValue, values);
            }}
            onItemClear={() => {

              updateProdutoVendido(null, setFieldValue, values);
            }}

          />

        </Grid>

        {configPermissoesAssistencia.ApenasCompProdProblema && values.Assistencia.ProdutoVendido ? (
          <Grid
            item
            xs={15}
            sm={15}
            md={15}
            lg={15}
            xl={15}
            display="flex"
            flexDirection="column"
          >
            <CampoSearchlistProdutoProblema />
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={15}
            xl={15}
            display="flex"
            flexDirection={"column"}
          >
            <CamposSearchListProduto
              constantes={constantes}
              idTabelaPreco={0}
              disabled={somenteLeitura}
              disabledDesc
              nameId="Assistencia.ProdutoProblema.Id"
              nameDesc="Assistencia.ProdutoProblema.Nome"
              placeholder={`${constantes?.ProdutoProblema} ${!values.Assistencia.ProdutoVendido ? `- ${constantes?.TodosProdutos}` : ""}`}
              onLoad={handleLoad}
              onError={handleError}
              onItemSelected={(item) => {
                const produtoProblema = item ?? null;
                updateProdutoProblema(produtoProblema, setFieldValue, values);
              }}
              onItemClear={() => {
                updateProdutoProblema(null, setFieldValue, values);
              }}

            />
          </Grid>
        )}

        {ExibirCausaAssistencia &&

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={15}
            display="flex"
            flexDirection={"column"}
            gap={1}
          >
            <CamposSearchCausa
              constantes={constantes}
              idCausa={values.Assistencia?.CausaAssistencia?.Id}
              disabled={somenteLeitura}
              disabledDesc
              nameId="Assistencia.CausaAssistencia.Id"
              nameDesc="Assistencia.CausaAssistencia.Nome"
              placeholder={constantes?.CausaAssistencia}
              onLoad={handleLoad}
              onError={handleError}
              onItemSelected={(item) => {
                const causa = item ? { Id: item.Id, Nome: item.Nome } : null;
                updateCausa(causa, setFieldValue, values);
              }}
              onItemClear={() => {
                updateCausa(null, setFieldValue, values);
              }}
            />
          </Grid>
        }

        <Grid
          item
          flexDirection={"row"}
          xs={12}
          sm={12}
          md={12}
          lg={15}
          gap={1}
          display="flex"
        >
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={0.94}
              display="flex"
              flexDirection={"row"}
              gap={0.5}

            >
              <Box style={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
              }}>
                <InputLabel>{constantes.Quantidade}</InputLabel>


                <TextField
                  fullWidth
                  name="Assistencia.QtdeProblema"
                  value={values.Assistencia.QtdeProblema}
                  disabled={somenteLeitura || values.Assistencia?.ProdutoProblema?.FormulaParaQuantidadeId > 0}
                  onChange={handleChange}
                  size="small"
                  type="number"
                  placeholder={constantes.Quantidade}
                />

              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "flex-end" }}>

                {values.Assistencia?.ProdutoProblema?.FormulaParaQuantidadeId > 0 ? (

                  <Button
                    type="button"
                    style={{
                      backgroundColor: "#009180",
                      color: "white",
                      border: "1px solid rgba(76,77,76,0.1550595238095238)"
                    }}
                    onClick={(e) => {
                      setModalAtributos(true);
                    }}>
                    <FontAwesomeIcon icon={faBox}></FontAwesomeIcon>
                  </Button>
                ) : (
                  <>
                  </>
                )}

              </Box>
            </Grid>
          </>

          <ModalAtributosProdutoControl
            id={values.Assistencia?.ProdutoProblema?.Id}
            atributos={atributos}
            somenteLeitura={somenteLeitura}
            modal={modalAtributos}
            quantidadeCalculada={values.Assistencia?.QtdeProblema}
            setQuantidadeCalculada={(atributos, qtd) => {
              setFieldValue("Assistencia.QtdeProblema", Number(qtd));
              setFieldValue("Assistencia.AtributosProdutoProblema", atributos);
              // setTotalItem(Number((Number(qtd) * precoUnitario).toFixed(2)));
              setAtributos(atributos);
              setModalAtributos(false);
            }}
            toggle={() => { setModalAtributos(false) }}
          />
        </Grid>

        <Grid
          item
          flexDirection={"column"}
          xs={12}
          sm={15}
          md={15}
          lg={15}
          display="flex"
        >
          <>
            <InputLabel>{constantes.Descricao}</InputLabel>
            <TextareaAutosize
              style={{
                width: "100%",
                marginTop: 5,
                padding: "5px 10px",
                border: "1px solid #DCDCDC",
                borderRadius: 5,
                fontFamily: "sans-serif",
                textTransform: "lowercase",
                ...(window.innerWidth <= 800 && {
                  width: "100%",
                  marginTop: 15,
                  padding: "5px 10px",
                  fontFamily: "sans-serif",
                  textTransform: "lowercase",
                }),
                ...(window.innerWidth <= 600 && {
                  width: "100%",
                  marginTop: 15,
                  padding: "5px 10px",
                  fontFamily: "sans-serif",
                  textTransform: "lowercase",
                }),
              }}
              minRows={6}
              placeholder={constantes.DescricaoProblema}
              value={values.Assistencia.DescricaoProblema}
              disabled={somenteLeitura}
              onChange={handleChange}
              name="Assistencia.DescricaoProblema"
              fullWidth
              size="small"
            />
          </>
        </Grid>
      </Grid>
    </>
  );
}

export default AssistenciaProblema;
