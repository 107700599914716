import { faBox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { Button, Container, Form, Spinner, Stack } from "react-bootstrap";
import { ItemOrcamentoVendaContext } from "../../contexts/itemorcamentovenda-context";
import { useFormikContext } from "formik";

const SelecaoProdutoCtx = () => {

    const {
        loadingGlobal,
        setModalComposicao
    } = useContext(ItemOrcamentoVendaContext);

    const {
        values,
        setFieldValue,
        resetForm,
        handleChange
    } = useFormikContext();

    return (
        <Container>
            <Stack direction="horizontal" gap={2}>
                <div
                    className="p-0"
                    style={{ width: "98%" }}
                >

                    <Form.Control
                        name={"ItemOrcamentoVenda.NomeProduto"}
                        className="me-auto"
                        value={values.ItemOrcamentoVenda?.NomeProduto}
                        onChange={handleChange}
                        type="text"
                        placeholder={""}
                        // style={{ flex: 10 }}
                        disabled
                    />


                </div>
                <div
                    className="p-0"
                >
                    {loadingGlobal ? <Spinner animation="border" size="10" variant="secondary" /> : <Button
                        variant="outline-secondary"
                        disabled={!values.ItemOrcamentoVenda?.CodProduto}
                        type="button"
                        onClick={(e) => {
                            setModalComposicao(true);

                        }}
                        style={{ width: 60, alignContent: "bottom" }}
                    >
                        <FontAwesomeIcon icon={faBox}></FontAwesomeIcon>
                    </Button>}

                </div>



            </Stack>
        </Container>
    )
}

export default SelecaoProdutoCtx;

