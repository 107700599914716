import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ProdutoService from "../../services/service.produto";
import T from "../../common/traducao";
import SearchComp from "../../common/searchcomp";
import ListComp from "../../common/listcomp";
import {
  Col,
  Container,
  Form,
  FormControl,
  Image,
  ListGroup,
  ListGroupItem,
  Button,
  Row,
  Stack,
} from "react-bootstrap";
import { Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ExpandComponent } from "../controls_comp/control-Expand";
import { LoadingProgress } from "../ui_comp/Loading";
import NotImage from '../../assets/sem-imagem.png'

const NewComposicaoProdutoControl = (props) => {
  const { id, constantes } = props;

  const columns_images = [
    {
      field: "action",
      headerName: "",
      width: 90,
      key: true,
    },
    {
      field: "ProdutoId",
      headerName: constantes.Codigo,
      width: 90,
      key: true,
    },
    {
      field: "ProdutoNome",
      headerName: constantes.Nome,
      width: 90,
    },
    {
      field: "ProdutoReferencia",
      headerName: constantes.Referencia,
      width: 90,
    },
    {
      field: "ProdutoUnidadeMedida",
      headerName: constantes.UnidadeMedida,
      width: 90,
    },
    {
      field: "Quantidade",
      headerName: constantes.Quantidade,
      width: 150,
      digits: 4,
    },
    {
      field: "Imagem",
      headerName: constantes.Imagem,
      width: 150,
      type: "image",
    },
  ];

  const columns_sem_images = [
    {
      field: "action",
      headerName: "",
      width: 90,
      key: true,
    },

    {
      field: "ProdutoId",
      headerName: constantes.Codigo,
      width: 90,
      key: true,
    },
    {
      field: "ProdutoNome",
      headerName: constantes.Nome,
      width: 90,
    },
    {
      field: "ProdutoReferencia",
      headerName: constantes.Referencia,
      width: 90,
    },
    {
      field: "ProdutoUnidadeMedida",
      headerName: constantes.UnidadeMedida,
      width: 90,
    },
    {
      field: "Quantidade",
      headerName: constantes.Quantidade,
      width: 150,
      digits: 4,
    },
  ];

  // const params = useParams();

  const [produto, setProduto] = useState();
  const [composicao, setComposicao] = useState();
  const [pages, setPages] = useState();
  const [showimages, setShowImages] = useState(false);
  const [imagemproduto, setImagemProduto] = useState();
  const [expand, setExpand] = useState({});

  async function GetProduto(id) {
    (await id) &&
      ProdutoService.get(id)
        .then((response) => {
          const traducao = ProdutoService.getProdutoTraduzido(response.data);
          const produto = { ...response.data, Nome: traducao };
          setProduto(produto);
        })
        .catch()
        .finally();
  }

  async function GetImagemProduto(id) {
    (await id) &&
      ProdutoService.getImage(id)
        .then((response) => {
          setImagemProduto(response.request.responseURL);
        })
        .catch()
        .finally();
  }

  async function GetComposicao(id) {
    (await id) &&
      ProdutoService.getComposicao(id)
        .then((response) => {
          setComposicao(response.data.value);
          setPages(response.data.pages);
        })
        .catch()
        .finally();
  }


  useEffect(() => {
    GetProduto(id);
    GetImagemProduto(id);
    GetComposicao(id);
  }, []);


 

  const toggleExpand = (produtoId) => {
    setExpand((prevExpand) => ({
      ...prevExpand,
      [produtoId]: !prevExpand[produtoId],
    }));
  };

 
  const listWithActionExpand =
    composicao &&
    composicao.map((item) => {
      return {
        ...item,
        Imagem: showimages ? `${process.env.REACT_APP_BASE_URL}${item.Imagem}` : null, 
        action: (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 0,
              padding: 0.2,
              background: "transparent",
              width: "100%",
              height: "100%",
            }}
          >
            {item?.Composicao?.length !== 0 && (
              <Button
                style={{
                  display: "flex",
                  width: "100%",
                  background: "transparent",
                  padding: 1,
                  color: `${
                    item?.Composicao?.length === 0 ? "#797979" : "#000000"
                  }`,
                  border: "none",
                }}
                onClick={() => {
                  toggleExpand(item.$id);
                }}
                disabled={item?.Composicao?.length === 0}
              >
                <>
                  {item?.Composicao?.length !== 0 && expand[item.$id] ? (
                    <ExpandLessIcon color="black" />
                  ) : (
                    <ExpandMoreIcon color="black" />
                  )}
                </>
              </Button>
            )}
          </Box>
        ),
      };
    });
  
  const GetLista = () => {
    return showimages ? (
      <>
        {!listWithActionExpand && (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"100%"}
          >
            <LoadingProgress labelLoading={constantes.Carregando} />
          </Box>
        )}
      
        <ListComp
          onItemSelected={() => {}}
          data={listWithActionExpand}
          columns={columns_images}
          activeExpand={true}
          onExpanded={(itemdata) => {
            return (
              itemdata?.Composicao &&
              expand &&
              expand[itemdata?.$id] && (
                <ExpandComponent
                  dataComposicao={itemdata?.Composicao}
                  showImages={showimages}
                  expand={expand}
         
                  toggleExpand={toggleExpand}
                />
              )
            );
          }}
        />
      </>
    ) : (
      <>
        {!listWithActionExpand && (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"100%"}
          >
            <LoadingProgress labelLoading={constantes.Carregando} />
          </Box>
        )}
 
        <ListComp
          data={listWithActionExpand}
          onItemSelected={() => {}}
          columns={columns_sem_images}
          activeExpand={true}
          onExpanded={(itemdata) => {
            return (
              itemdata?.Composicao &&
              expand &&
              expand[itemdata?.$id] && (
                <ExpandComponent
                 dataComposicao={itemdata?.Composicao} 
                 showImages={showimages}
                 expand={expand}  
                 toggleExpand={toggleExpand} />
              )
            );
          }}
        />
      </>
    );
  };

  return (
    <>
      <ListGroup>
        <ListGroupItem>
          <Container>
            <Row>
              <Col xs={5} md={10}>
                <h2>{constantes.EstruturaComposiçãodoProduto}</h2>
              </Col>
            </Row>
          </Container>
        </ListGroupItem>

        <ListGroupItem>
          <Container>
            <Row>
              <Col md={2}>
                <span style={{ fontWeight: "bold" }}>{constantes?.Codigo}</span>
              </Col>
              <Col md={2}>{produto?.Id}</Col>
              <Col md={2}>
                <span style={{ fontWeight: "bold" }}>
                  {constantes.Referencia}
                </span>
              </Col>
              <Col md={2}>{produto?.Referencia}</Col>
            </Row>
            <Row>
              <Col md={2}>
                <span style={{ fontWeight: "bold" }}>{constantes.Nome}</span>
              </Col>
              <Col md={10}>{produto?.Nome}</Col>
            </Row>
            <Row>
              <Col md={4}>
                <span style={{ fontWeight: "bold" }}>
                  {constantes.Observacao}
                </span>
              </Col>
              <Col md={10}>{produto?.Observacao}</Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col xs={6} md={4}>
                <Container></Container>
              </Col>
            </Row>
          </Container>
        </ListGroupItem>
        <ListGroupItem>
          <Row>
            <Col md={4}>
              <Image thumbnail height={300} src={imagemproduto} rounded />
            </Col>
            <Col>
            
            {listWithActionExpand && (
             <Container>
                  <Form.Check
                    className="mb-3"
                    type="switch"
                    id="check-api-switch"
                    label="Mostrar Imagens"
                    value={showimages}
                    onChange={(e) => {
                      setShowImages(e.target.checked);
                    }}
                  />
                    </Container>
                )}
            
              <GetLista />
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </>
  );
};

export default NewComposicaoProdutoControl;
