import { useContext, useEffect, useState } from "react";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import AtributoComp from "../atributos_comp/atributo.comp.atributo";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useFormikContext } from "formik";

const OrcamentoVendaSalvarCtx = () => {

    const {
        orcamentoVenda,
        somenteLeitura,
        constantes,
        finalizando,
        setFinalizando,
        recalculando,
        salvando
    } = useContext(OrcamentoVendaContext);

    const {
        values,
        handleSubmit,
        isSubmiting,
    } = useFormikContext();

    const navigate = useNavigate();

    return (
        <>
            <Container
                className="p-3 mt-5 d-flex justify-content-center align-items-center gap-2 bottom-0 position-fixed bg-white"
                fluid
                style={{zIndex:2}}
            >
                <Row>
                    <Col>
                        {orcamentoVenda?.Municipio &&
                            <Button
                                type="button"
                                size="lg"
                                variant="primary"
                                origin="salvando"
                                onClick={(e) => {
                                    setFinalizando(false);
                                    handleSubmit(e);
                                }}
                                disabled={somenteLeitura || recalculando || salvando}
                            >
                                {recalculando ? <Spinner
                                    animation="border"
                                    role="status">
                                </Spinner> :

                                    values.OrcamentoVenda?.Id > 0
                                        ? constantes?.Atualizar
                                        : constantes?.Gravar}
                            </Button>}{" "}
                        {!somenteLeitura && orcamentoVenda?.Municipio && (
                            <Button
                                type="button"
                                size="lg"
                                variant="success"
                                origin="finalizando"
                                onClick={(e) => {
                                    setFinalizando(true);
                                    handleSubmit(e);
                                }}
                                disabled={somenteLeitura || recalculando || salvando}
                            >
                                {constantes?.Finalizar}
                            </Button>
                        )}{" "}
                        <Button
                            type="button"
                            size="lg"
                            variant="light"
                            onClick={() => {
                                navigate("/orcamentosvenda");
                            }}
                            disabled={salvando}
                        >
                            {constantes?.Cancelar}
                        </Button>
                    </Col>
                </Row>


            </Container>
        </>
    )

}

export default OrcamentoVendaSalvarCtx;