import ClientesProvider from "../../contexts/clientes-context";
import ViewClientes from "../../components/clientes";
const ViewClientesTabs = () => {

  return (
 
        <ClientesProvider>
          <ViewClientes />
        </ClientesProvider>
  
  );
};

export default ViewClientesTabs;
