import { Container, Stack } from "react-bootstrap";
import { useFormikContext } from "formik";
import { useContext } from "react";
import { ItemOrcamentoVendaContext } from "../../contexts/itemorcamentovenda-context";
import EditControl from "../controls_comp/control-edit.jsx";

const IdentificacaoItemCtx = () => {

    const {
        constantes
    } = useContext(ItemOrcamentoVendaContext);

    const { 
        values,
    } = useFormikContext();

    return (
        <Container>
            <Stack direction="horizontal" gap={3}>
                <EditControl name="ItemOrcamentoVenda.Item" placeholder={constantes.Item} type="number" disabled />
                <EditControl name="ItemOrcamentoVenda.Id" placeholder="Id" type="number" disabled />
                <div className="p-2 ms-auto"></div>
                <div className="vr" />
                <div className="p-0">
                    <Stack>
                        {values?.ItemOrcamentoVenda?.TabelaPrecoId &&
                            <small> {constantes.UsandoTabeladePreco} {values?.ItemOrcamentoVenda?.TabelaPrecoId} </small>
                        }
                        {values?.ItemOrcamentoVenda?.Cfop &&
                            <small> {constantes.Usandocfop} {values?.ItemOrcamentoVenda?.Cfop.Id}</small>
                        }
                        {values?.ItemOrcamentoVenda?.QtdeMultiplaVenda &&
                            <small>{constantes.Multiplode} {values?.ItemOrcamentoVenda?.QtdeMultiplaVenda}</small>
                        }
                    </Stack>
                </div>
            </Stack>
        </Container >
    )

}

export default IdentificacaoItemCtx;

