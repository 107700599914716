import { useLocation, Navigate } from "react-router-dom";
import { GetIdiomaSelecionado, getUserLogin, isAuthenticated } from "../config/localstorageConfig/localstorage";
import { useContext, useEffect, useLayoutEffect } from "react";
import { H2jGlobalContext } from "../contexts/H2jGlobal-context";
import T from "./traducao";
import { MenuLoadingProgress } from "../components/ui_comp/Loading";
import SignIn from "../pages/SignIn";

export const WithRouter = ({ children }) => {
  const constantes = T();
  let location = useLocation();

  const { loadingGlobal, handleLoading, updateAuthState } = useContext(H2jGlobalContext);

  const _isAutenticated = isAuthenticated();


  if (!_isAutenticated) {
    location.pathname = "/signin";
  }

  return _isAutenticated ? (
    <>
      {children}
      {loadingGlobal && <MenuLoadingProgress labelLoadingProgress={`${constantes.Carregando}...`} />}
    </>
  ) :
    <SignIn />
};
