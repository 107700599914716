import { useState } from "react";
import ControlSelect from "./control-selectcontrol.jsx";
import T from '../../common/traducao.js'
const ControlTipoContribuinte = (props) => {

    // TIPO_CONTRIBUINTE_NORMAL = 'Normal';
    // TIPO_CONTRIBUINTE_SIMPLES = 'Simples';
    // TIPO_CONTRIBUINTE_PRODUTOR_RURAL = 'Produtor Rural';
    // TIPO_CONTRIBUINTE_MEI = 'MEI';
    const constantes = T();
    const tiposContribuinte = 
        [
            {
                Id : 0,
                Valor : "",
                Descricao : ""
            },
            {
                Id : 1,
                Valor : `${constantes.Normal}`,
                Descricao : `${constantes.Normal}`
            },
            {
                Id : 2,
                Valor : `${constantes.Simples}`,
                Descricao : `${constantes.Simples}`
            },
            {
                Id : 3,
                Valor : `${constantes.ProdutorRural}`,
                Descricao : `${constantes.ProdutorRural}`
            },
            {
                Id : 4,
                Valor : `${constantes.MEI}`,
                Descricao : `${constantes.MEI}`
            }
        ];

    return (
        <ControlSelect {...props} name={"OrcamentoVenda.TipoContribuinte"} placeholder={constantes.TipodeContribuinte}
            itens={tiposContribuinte} 
        />
    )
}

export default ControlTipoContribuinte;