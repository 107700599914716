import { Container, Form, Stack } from "react-bootstrap";
import { useContext } from "react";
import { ItemOrcamentoVendaContext } from "../../contexts/itemorcamentovenda-context";
import { useFormikContext } from "formik";
import CamposSearchListProduto from "../campos_comp/campo-searchlist-produto";
import SearchListReferenciaProduto from "../searchs_comp/search-List.Referencia.Produto";

const SelecaoReferenciaCtx = () => {

    const {
        configs,
        constantes,
        somenteLeitura,
        precoUnitario,
        loadingGlobal,
        unidadeMedida,
        totalItem,
        precoTabela,
        precoTabelaComDesconto,
        setModalComposicao,
        AtualizarCalculo,
        tabelaPrecoId,
        onLoad,
        onError,
        setDevePreencherAtributos,
        setDeveMostrarSobMedida,
        setLoadingGlobal
    } = useContext(ItemOrcamentoVendaContext);

    const {
        values,
        setFieldValue,
        resetForm
    } = useFormikContext();

    return (
        <Container>
            <Stack direction="horizontal" gap={3}>
                <Stack>
                    <Form.Label className="mb-0">{constantes.Produto}</Form.Label>
                    <CamposSearchListProduto
                        //ref={prodRef}
                        idTabelaPreco={tabelaPrecoId}
                        disabled={somenteLeitura}
                        hideTitle
                        nameId="ItemOrcamentoVenda.CodProduto"
                        //nameDesc="ItemOrcamentoVenda.NomeProduto"
                        placeholder="Produto"
                        onLoad={onLoad}
                        onError={onError}
                        style={{ width: 1000 }}
                        onItemSelected={
                            (item) => {
                                values.ItemOrcamentoVenda.FormulaParaQuantidadeId = item.FormulaParaQuantidadeId;
                                setDevePreencherAtributos(configs?.UsarAtributosVenda && item?.FormulaParaQuantidadeId > 0);
                                setDeveMostrarSobMedida(item?.PermitirSobMedida);
                                setLoadingGlobal(true)
                                //values.ItemOrcamentoVenda.CodProduto = item.Id;
                                AtualizarCalculo(
                                    setFieldValue,
                                    values,
                                    resetForm,
                                    item.Id)
                            }
                        }
                    />
                </Stack>
                <div className="vr" />

                <Stack>
                    <Form.Label className="mb-0">{constantes.Referencia}</Form.Label>
                    <SearchListReferenciaProduto
                        disabled={somenteLeitura}
                        idTabelaPreco={tabelaPrecoId}
                        hideTitle
                        nameId="ItemOrcamentoVenda.ReferenciaProduto"
                        //nameDesc="ItemOrcamentoVenda.NomeProduto"
                        placeholder={constantes.Referencia}
                        onLoad={onLoad}
                        onError={onError}
                        style={{ width: 1000 }}
                        onItemSelected={
                            (item) => {
                                values.ItemOrcamentoVenda.FormulaParaQuantidadeId = item.FormulaParaQuantidadeId;
                                setDevePreencherAtributos(configs?.UsarAtributosVenda && item?.FormulaParaQuantidadeId > 0);
                                setDeveMostrarSobMedida(item?.PermitirSobMedida);

                                //values.ItemOrcamentoVenda.CodProduto = item.Id;
                                AtualizarCalculo(
                                    setFieldValue,
                                    values,
                                    resetForm,
                                    item.Id)
                            }
                        }
                    />
                </Stack>
            </Stack>
        </Container>
    )
}

export default SelecaoReferenciaCtx;