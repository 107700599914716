import React, { useContext, useEffect } from "react";
import { Grid, InputLabel, Switch, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import DateControl from "../controls_comp/control-Date";
import { AssistenciasContext } from "../../contexts/assistencias-context";
import { validationLimitDate } from "../../utils/validationLimitDate";
import { getStyleSituacaoVisitaTecnica } from "../../consts/situacao_assistencia";
import { getData } from "../../utils/formattedInputDate";
function VisitaTecnica() {
  const {
    constantes,
    somenteLeitura,
    resetFieldsVisitaTecnica,
  } = useContext(AssistenciasContext);

  const formik = useFormikContext();
  const { values, setFieldValue, handleChange, resetForm } = formik;

  useEffect(() => {

    if (values.Assistencia.InicioDispVisitaTecnica > values.Assistencia.FimDispVisitaTecnica) {
      setFieldValue("Assistencia.FimDispVisitaTecnica", values.Assistencia.InicioDispVisitaTecnica)
    }
  }, [
    values.Assistencia.InicioDispVisitaTecnica,
  ]);

  return (
    <Grid container spacing={2} p={2}>
      <Grid
        spacing={1}
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        display="flex"
        flexDirection={"row"}
        alignItems={"center"}
      >
        <InputLabel sx={{ fontWeight: "bold" }}>
          {constantes.SolicitarAssistenciaTecnica}
        </InputLabel>
        <Switch
          name="Assistencia.SolicitarVisitaTecnica"
          defaultChecked={values.Assistencia?.SolicitarVisitaTecnica}
          defaultValue={values.Assistencia?.SolicitarVisitaTecnica}
          value={values.Assistencia?.SolicitarVisitaTecnica}
          checked={values.Assistencia?.SolicitarVisitaTecnica}
          onChange={handleChange}
          disabled={somenteLeitura}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Grid>

      <Grid
        spacing={1}
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
        xl={6}
        display="flex"
        flexDirection={"column"}
      >
        <DateControl
          name="Assistencia.InicioDispVisitaTecnica"
          placeholder={constantes?.DisponibilidadeInicial}
          selected={values?.Assistencia?.InicioDispVisitaTecnica}
          disabled={
            !values.Assistencia.SolicitarVisitaTecnica || somenteLeitura
          }
          min={new Date().toISOString().slice(0, 10)}
        />
      </Grid>

      <Grid
        item
        flexDirection={"column"}
        xs={12}
        sm={6}
        md={6}
        lg={6}
        xl={6}
        display="flex"
      >
        <DateControl
          name="Assistencia.FimDispVisitaTecnica"
          placeholder={constantes?.DisponibilidadeFinal}
          selected={values?.Assistencia?.FimDispVisitaTecnica}
          min={values.Assistencia.InicioDispVisitaTecnica}
          disabled={
            !values.Assistencia.SolicitarVisitaTecnica || somenteLeitura
          }
        />
      </Grid>

      <Grid
        item
        flexDirection={"column"}
        xs={12}
        sm={12}
        md={12}
        lg={15}
        xl={15}
        display="flex"
      >
        <InputLabel>{constantes.SituacaoVisita}</InputLabel>

        <strong style={getStyleSituacaoVisitaTecnica(values?.Assistencia?.SituacaoVisitaTecnica)}>
          {values.Assistencia?.SituacaoVisitaTecnica}
        </strong>

      </Grid>
      {getData(values?.Assistencia?.PrevisaoVisitaTecnica) &&

        <Grid
          item
          flexDirection={"column"}
          xs={12}
          sm={12}
          md={12}
          lg={15}
          xl={15}
          display="flex"
        >
          <DateControl
            name="Assistencia.PrevisaoVisitaTecnica"
            placeholder={constantes?.DataPrevisaoVisitaTecnica}
            selected={values?.Assistencia?.PrevisaoVisitaTecnica}

            disabled={true}
          />
        </Grid>
      }
      {values?.Assistencia?.JustificativaVisitaTecnica &&
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={15}
          xl={15}
          display="flex"
          flexDirection={"column"}
        >
          <InputLabel>{constantes.JustificativaVisitaTecnica}</InputLabel>
          <TextField
            name="Assistencia.JustificativaSituacaoVisitaTecnica"
            defaultValue={values?.Assistencia?.JustificativaSituacaoVisitaTecnica}
            value={values?.Assistencia?.JustificativaSituacaoVisitaTecnica}
            onChange={handleChange}
            fullWidth
            size="small"
            disabled={true}
            placeholder={constantes.JustificativaVisitaTecnica}
          />
        </Grid>
      }
    </Grid>
  );
}

export default VisitaTecnica;
