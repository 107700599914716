import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { Button } from "react-bootstrap";
import CancelarOrcamentoControl from "./CancelarOrcamentoVendaControl";
import { useEffect, useState } from "react";


const ModalCancelarOrcamento = ({ constantes, open, close, onCancelar, id }) => {

    const [cancelamentoOrcamento, setCancelamentoOrcamento] = useState({});

    useEffect(() => {
        setCancelamentoOrcamento({});
    }, [open])

    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    close()
                }}
                maxWidth="sm"
                fullWidth
            >
                <DialogContent>
                    <CancelarOrcamentoControl
                        id={id}
                        cancelamentoOrcamento={cancelamentoOrcamento}
                        handleChange={(cancelamento) => {
                            setCancelamentoOrcamento(cancelamento);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{ background: " #009180", border: "none" }}
                        disabled={cancelamentoOrcamento?.IdMotivoSituacao ? false : true}
                        onClick={() => onCancelar(id, cancelamentoOrcamento)}
                    >
                        {constantes.Confirmar}
                    </Button>
                    <Button
                        style={{ background: " #910000", border: "none" }}
                        onClick={() => close()}
                    >
                        {constantes.Sair}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ModalCancelarOrcamento;