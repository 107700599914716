import { faCancel, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import moment from "moment";
import { Button, Col, Form, Row } from "react-bootstrap";
import * as Yup from 'yup';

import { Debug } from "../../common/Debug";
import ReactDatePicker from "react-datepicker";
import { useRef, useState } from "react";


const schema = Yup.object({
    //Data: Yup.date().default(() => new Date()),
    Valor: Yup.number().positive('*')
});

const LancamentoVencimentos = (props) => {

    const { state, itemedicao, onItemAdd, onCancelItem, onItemUpdate, DataBaseVencimentos } = props;

    const dateRef = useRef();
    const diasRef = useRef();

    const _initial_clean = {
        Dias: '',
        Data: null,
        //Data: new Date(DataBaseVencimentos ?? null).toISOString().slice(0, 10),
        Percentual: '',
        Valor: ''
    };

    const _initial = itemedicao ?
        {
            Dias: Number(itemedicao.Dias),
            Data: itemedicao.Data ? itemedicao.Data : '',
            Percentual: itemedicao.Percentual ? itemedicao.Percentual : '',
            Valor: itemedicao.Valor ? itemedicao.Valor : ''
        } : _initial_clean


    return (
        <Formik
            enableReinitialize={true}
            validationSchema={schema}
            validate={(values, isValidating) => {
                let errors = {};

                if (values.Dias < 0) {
                    return { Dias: "Menor que 0" };
                }

                const diasedicao = itemedicao ? itemedicao.Dias >= 0 : false;
                const mudou = diasedicao ? diasedicao == values.Dias : true;
                const dias = values.Dias;

                const _element = props.lista && mudou &&
                    props.lista.find((element) => {
                        return element.Dias === dias

                    })

                errors = _element ? { Dias: "Já existe" } : {};
                return errors;

            }}
            onSubmit={(values, { resetForm }) => {
                values.Percentual = Number(values.Percentual);
                values.Valor = Number(values.Valor);
                values.Data = null;
                if (typeof (values.Dias) === 'number') {
                    state === "inserindo" ?
                        onItemAdd && onItemAdd(values)
                        :
                        onItemUpdate && onItemUpdate(values)
                }
                resetForm();
                diasRef.current.focus();
            }}
            initialValues={_initial}

        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                resetForm,
                values,
                errors,
                isValidating
            }) => {
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <div>
                            <Form.Group as={Row}>
                                <Form.Group as={Col} md="2" controlId='validatioFormik01'>
                                    <Form.Control
                                        onWheel={(e) => e.target.blur()}
                                        type="number"
                                        ref={diasRef}
                                        name="Dias"
                                        placeholder="Dias"
                                        value={values.Dias}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                const dias = e.target.value;
                                                const novadata = moment(DataBaseVencimentos ?? null).add(dias, 'days').format("YYYY-MM-DD");
                                                setFieldValue("Data", novadata);
                                            }
                                        }}
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                            handleBlur(e);
                                            const dias = e.target.value;
                                            const novadata = moment(DataBaseVencimentos ?? null).add(dias, 'days').format("YYYY-MM-DD");
                                            setFieldValue("Data", novadata);
                                        }}
                                        isInvalid={!!errors.Dias}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.Dias}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="3" controlId='validatioFormik02'>
                                    <Form.Control
                                        type={values.Data ? 'date' : 'text'}
                                        ref={dateRef}
                                        name="Data"
                                        //value={typeDate === 'date' ? values.Data : valueData}
                                        value={values.Data ?? ''}
                                        onFocus={(e) => {
                                            if (!values.Data) {
                                                const dataatual = new Date(DataBaseVencimentos ?? null).toISOString().slice(0, 10);
                                                setFieldValue("Data", dataatual);
                                            }
                                        }}
                                        onChange={(e) => {
                                            handleChange(e);
                                            const dataatual = moment(DataBaseVencimentos ?? null);
                                            const datadigitada = moment(e.target.value);
                                            const newdate = datadigitada.clone().startOf('day').diff(dataatual.clone().startOf('day'), 'days');
                                            setFieldValue("Dias", newdate);
                                        }}
                                        onBlur={(e) => {
                                        }}
                                        isInvalid={!!errors.Data}
                                        disabled={!!!props.vencimentofixo}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.Data}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="2" controlId='validatioFormik03'>
                                    <Form.Control
                                        onWheel={(e) => e.target.blur()}
                                        type="number"
                                        name="Percentual"
                                        placeholder="%"
                                        value={values.Percentual}
                                        onChange={handleChange}
                                        isInvalid={!!errors.Percentual}
                                        disabled={errors.Dias}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.Percentual}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="3" controlId='validatioFormik04'>
                                    <Form.Control
                                        onWheel={(e) => e.target.blur()}
                                        type="number"
                                        name="Valor"
                                        placeholder="R$"
                                        value={values.Valor}
                                        onChange={handleChange}
                                        isInvalid={!!errors.Valor}
                                        disabled={errors.Dias}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.Valor}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="2" controlId='validatioFormik04'>
                                    <Button disabled={errors.Dias} variant="success" type="submit">
                                        <FontAwesomeIcon icon={faCheck} />
                                    </Button>
                                    {state === "editando" &&
                                        <Button variant="danger" onClick={() => {
                                            onCancelItem && onCancelItem();
                                            resetForm();
                                        }}>
                                            <FontAwesomeIcon icon={faCancel} />
                                        </Button>}

                                </Form.Group>
                            </Form.Group>
                        </div >

                    </Form>
                )
            }}
        </Formik >
    );
}

export default LancamentoVencimentos;