import { useContext } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import { ClienteContext } from "../../contexts/clientes-context";
import { FaMapMarkerAlt } from "react-icons/fa";
import SearchListMunicipios from "../searchs_comp/search-List.Municipios";
import EditCepControl from "../controls_comp/control-edit-cep";
import EditControl from "../controls_comp/control-edit";

export default function ClienteTabEnderecoPrincipal() {

  const {
    constantes,
    handleLoad,
    somenteLeitura } = useContext(ClienteContext);

  const {
    values,
    setValues,
    setFieldValue } = useFormikContext();

  return (
    <Box component={Paper}>
      <Box
        display="flex"
        alignItems="center"
        p={2}
        height={50}
        sx={{ backgroundColor: "rgba(59, 134, 124, 0.08)" }}
      >
        <Typography variant="h1" fontSize={22} fontWeight="sembold">
          <FaMapMarkerAlt style={{ marginBottom: "5px", marginRight: "8px" }} />
          {constantes.EnderecoPrincipal}
        </Typography>
      </Box>

      <Grid container spacing={2} p={2}>
        <Grid
          spacing={2}
          container
          item
          xs={12}
          sm={12}
          md={10}
          display="flex"
          alignItems="end"
        >
          <Grid ml={2} xs={12} sm={3.6} md={4.2}>
            <EditCepControl
              disabled={somenteLeitura}
              name="Entidade.Cep"
              placeholder={constantes?.Cep}
              type="text"
              onCepResponse={(enderecoResponse, municipioResponse) => {
                setValues({
                  ...values,
                  Entidade: {
                    ...values.Entidade,
                    Endereco: enderecoResponse.logradouro,
                    Bairro: enderecoResponse.bairro,
                    Municipio: municipioResponse
                  }
                })
              }}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={18}>
            <EditControl
              inputProps={{ maxLength: 50 }}
              disabled={somenteLeitura}
              name="Entidade.Endereco"
              placeholder={constantes?.Enderecoprincipaldocliente}
              type="text" />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5} md={5}>
          <EditControl
            inputProps={{ maxLength: 50 }}
            disabled={somenteLeitura}
            name="Entidade.NumeroEndereco"
            placeholder={constantes?.Numerodoendereco}
            type="text" />
        </Grid>
        <Grid item xs={12} sm={5} md={5}>
          <EditControl
            inputProps={{ maxLength: 50 }}
            disabled={somenteLeitura}
            name="Entidade.ComplementoEndereco"
            placeholder={constantes?.Complemento}
            type="text" />
        </Grid>
        <Grid item xs={12} sm={10} md={10}>
          <EditControl
            inputProps={{ maxLength: 50 }}
            disabled={somenteLeitura}
            name="Entidade.Bairro"
            placeholder={constantes?.Bairro}
            type="text" />
        </Grid>

        <Grid item xs={12} sm={10} md={10}>
          <SearchListMunicipios
            name="Entidade.Municipio"
            nameId="Entidade.Municipio.Id"
            nameDesc="Entidade.Municipio.Nome"
            required
            disabled={somenteLeitura}
            placeholder={constantes.Municipio}
            onLoad={handleLoad}
            onItemSelected={(item) => {
              setFieldValue("Entidade.Municipio", item);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
